import {
  GET_SEGMENT_ASYNC,
  GET_HISTORICAL_ASYNC,
  CLEAR_SEGMENT_ASYNC,
  SEARCH_REPORT_ASYNC,
  SET_LOADING,
  GET_FINANCIAL_HISTORICAL_ASYNC,
  SEARCH_FINANCIAL_HISTORICAL_ASYNC,
  EXPORT_FINANCIAL_HISTORICAL_ASYNC,
} from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  group: [],
  account: [],
  first: [],
  second: [],
  third: [],
  files: [],
  loading: false,
  financialHistorical: [],
  filterHistoricalParams: [],
};

const monthlyReport = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_SEGMENT_ASYNC:
      if (payload.type === 'group') {
        state.account = [];
        state.first = [];
        state.second = [];
        state.third = [];
      }
      if (payload.type === 'account') {
        state.first = [];
        state.second = [];
        state.third = [];
      }
      if (payload.type === 'first') {
        state.second = [];
        state.third = [];
      }
      if (payload.type === 'second') {
        state.third = [];
      }
      return { ...state, [payload.type]: [...payload.data] };

    case GET_HISTORICAL_ASYNC:
      return { ...state, files: { ...payload } };

    case CLEAR_SEGMENT_ASYNC:
      return { ...state, ...initialState };
    case SEARCH_REPORT_ASYNC:
      return { ...state, files: { ...payload } };
    case GET_FINANCIAL_HISTORICAL_ASYNC:
      return {
        ...state,
        financialHistorical: payload?.financialHistorical,
        filterHistoricalParams: payload?.filterHistoricalParams,
      };
    case SEARCH_FINANCIAL_HISTORICAL_ASYNC:
      return {
        ...state,
        financialHistorical: payload?.financialHistorical,
        filterHistoricalParams: payload?.filterHistoricalParams,
      };
    case SET_LOADING:
      return { ...state, payload };
    case EXPORT_FINANCIAL_HISTORICAL_ASYNC:
      return { ...state, filterHistoricalParams: payload?.filterHistoricalParams };
    default:
      return state;
  }
};

export default monthlyReport;
