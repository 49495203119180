import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  selectTypeMain: (theme: Theme) => ({
    height: '90vh',
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      marginTop: 'calc(100% - 70%);',
      marginBottom: '2rem',
    },
  }),
  selectTypeTitle: (theme: Theme) => ({
    fontSize: '3rem',
    width: '60%',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  }),
  selectTypeOptions: (theme: Theme) => ({
    width: '22rem',
    height: '18.1rem',
    margin: '2rem 2rem',
    padding: '1rem 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.whiteLilac,
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      width: '13rem',
      margin: '1rem 0 1rem 0',
      height: '12.1rem',
    },
  }),
  selectTypeOptionText: (theme: Theme) => ({
    fontSize: '1.25rem',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      fontWeight: '700',
      marginTop: '1rem',
    },
  }),
  selectTypeButtonContainer: (theme: Theme) => ({
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  selectTypeButton: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  }),
  selectTypeText: (theme: Theme) => ({
    fontSize: '0.875rem',
    fontWeight: '400',
    marginLeft: '1rem',
  }),
};
