export const SET_ENTITY_DATA = 'SET_ENTITY_DATA';
export const SET_ENTITY_DATA_ASYNC = 'SET_ENTITY_DATA_ASYNC';
export const CLEAR_ENTITY_DATA = 'CLEAR_ENTITY_DATA';
export const SEND_FINANCIAL_DATA = 'SEND_FINANCIAL_DATA';
export const SEND_FINANCIAL_DATA_ASYNC = 'SEND_FINANCIAL_DATA_ASYNC';
export const LOADING_FINANCIAL_REGISTER = 'LOADING_FINANCIAL_REGISTER';
export const GET_FINANCIAL_DATA = 'GET_FINANCIAL_DATA';
export const GET_FINANCIAL_DATA_ASYNC = 'GET_FINANCIAL_DATA_ASYNC';
export const LOADING_FINANCIAL_GET = 'LOADING_FINANCIAL_GET';
export const RESET_FORM = 'RESET_FORM';
export const UPDATE_FINANCIAL_DATA = 'UPDATE_FINANCIAL_DATA';
export const UPDATE_FINANCIAL_DATA_ASYNC = 'UPDATE_FINANCIAL_DATA_ASYNC';
export const SEND_LEGAL_DATA = 'SEND_LEGAL_DATA';
export const SEND_LEGAL_DATA_ASYNC = 'SEND_LEGAL_DATA_ASYNC';
export const LOADING_LEGAL_REGISTER = 'LOADING_LEGAL_REGISTER';
export const GET_LEGAL_DATA = 'GET_LEGAL_DATA';
export const GET_LEGAL_DATA_ASYNC = 'GET_LEGAL_DATA_ASYNC';
export const LOADING_LEGAL_GET = 'LOADING_LEGAL_GET';
export const RESET_REGISTER = 'RESET_REGISTER';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_ASYNC = 'DELETE_FILE_ASYNC';
export const RESET_POSITION = 'RESET_POSITION';
export const GET_POSITION_ADM_DATA = 'GET_POSITION_ADM_DATA';
export const GET_POSITION_ADM_DATA_ASYNC = 'GET_POSITION_ADM_DATA_ASYNC';
export const GET_POSITION_WATCH_DATA = 'GET_POSITION_WATCH_DATA';
export const GET_POSITION_WATCH_DATA_ASYNC = 'GET_POSITION_WATCH_DATA_ASYNC';
export const UPDATE_POSITION_ADM_DATA = 'UPDATE_POSITION_ADM_DATA';
export const UPDATE_POSITION_ADM_TABLE_DATA = 'UPDATE_POSITION_ADM_TABLE_DATA';

export const SAVE_POSITION_ADM_DATA = 'SAVE_POSITION_ADM_DATA';
export const SAVE_POSITION_ADM_DATA_ASYNC = 'SAVE_POSITION_ADM_DATA_ASYNC';

export const GET_MEMBERS_ADM_DATA = 'GET_MEMBERS_ADM_DATA';
export const GET_MEMBERS_ADM_DATA_ASYNC = 'GET_MEMBERS_ADM_DATA_ASYNC';
