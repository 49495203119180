import { palette } from '../contants';

export const MuiYearPicker = {
  styleOverrides: {
    root: {
      '& .Mui-selected:hover, .Mui-selected:focus': {
        backgroundColor: palette.primary,
        color: palette.dark,
      },
    },
  },
};
