import { useEffect, useRef, useState } from 'react';
import { Box, Typography, InputAdornment, Button } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { UsersTable } from 'components';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { TextField } from 'components';
import { UseIntermitence, UsePagination } from 'hooks';
import { AddUsersModal } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { usersSelector, authSelector } from 'store/selectors';
import { getUsers, searchUser } from 'store/actions';
import { PermissionEnum } from 'utils/constants';

export const UsersList = () => {
  const inputRef = useRef(null);
  const { status, switchStatus } = UseIntermitence();
  const users = useSelector(usersSelector);
  const {
    user: {
      role: { permissions },
    },
  } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const {
    usersData: { data, meta },
  } = users;
  const totalPages = meta?.pageCount || 1;

  const { nextPage, previousPage, page, customPage } = UsePagination(meta?.page, totalPages, getUsers);

  const {
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const headers: string[] = ['Nombre', 'Correo', 'Rol', '', ''];

  useEffect(() => {
    dispatch(getUsers(1));
  }, []);

  const handleSearch = async ({ target }: any) => {
    const { value } = target;
    setSearch(value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(searchUser(search, customPage));
    }, 600);

    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <>
      <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center' bgcolor={palette.light}>
        <Box
          width='100%'
          display='flex'
          height={'calc(100vh - 5rem)'}
          justifyContent='flex-start'
          flexDirection='column'
          alignItems='center'
          position='relative'>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'98%'}
            margin={'4.5rem 1% 1rem 1%'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Typography fontSize={16} fontWeight={'600'} marginRight={'1rem'} color={palette?.dark}>
                Usuarios
              </Typography>
              <TextField
                label='Buscar'
                {...register('search', {
                  onChange: handleSearch,
                })}
                placeholder='Nombre o ID de caja de ahorro'
                type='text'
                name='search'
                control={control}
                id='search'
                InputProps={{
                  style: { width: '28rem' },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Icon icon='heroicons:magnifying-glass-solid' width={18} color={palette.black} />
                    </InputAdornment>
                  ),
                }}
                ref={inputRef.current}
              />
            </Box>

            {permissions.includes(PermissionEnum.CreateUser) && (
              <Box>
                <Button type='submit' sx={styles.tableButton} onClick={() => switchStatus()} variant='contained'>
                  Agregar usuario
                </Button>
              </Box>
            )}
          </Box>

          <UsersTable
            headers={headers}
            rowsData={data}
            title={`Número de usuarios en el sistema (${meta?.itemCount ?? 0})`}
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
            totalPages={totalPages}
          />
        </Box>
      </Box>

      {status && <AddUsersModal open={status} onClose={switchStatus} type={'create'} page={page} />}
    </>
  );
};
