import { SET_ROLES_DATA, GET_ROLES, DELETE_ROL, SEARCH_ROL, SEND_ROLE, UPDATE_ROLE } from './action-types';
import { actionObject } from '../../utils';

export const setRolesData = (data: any) => actionObject(SET_ROLES_DATA, data);
export const getRoles = (page = 1, take = 10) => actionObject(GET_ROLES, {page, take});
export const deleteRol = (id: string, onClose: any, page = 1, take = 10) => actionObject(DELETE_ROL, { id, onClose, page, take });
export const searchRol = (name: string, customPage: any) => actionObject(SEARCH_ROL, { name, customPage });
export const sendRole = (body: any, onClose: any, page = 1, take = 10) => actionObject(SEND_ROLE, { body, onClose, page, take });
export const updateRole = (id: string, body: any, onClose: any, page = 1, take = 10) =>
  actionObject(UPDATE_ROLE, { id, body, onClose, page, take });
