import { useRef, useEffect, useState } from 'react';
import { MuiModal, TextField, Select } from 'components';
import { Box, Button, Grid } from '@mui/material';
import { styles } from './styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Theme } from '@mui/material';
import { sendUser, updateUser } from 'store/actions';
import { rolesSelector } from 'store/selectors';
import { getRoles } from 'store/actions';

export const AddUsersModal = ({ open, onClose, type, userData, page }: any) => {
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const roles = useSelector(rolesSelector);

  const {
    rolesData: { data },
  } = roles;

  const handleSelect = (event: any) => {
    const {
      target: { value },
    } = event;
    clearErrors('role');

    setValue('role', value);
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstname: userData?.firstname ? userData.firstname : '',
      lastname: userData?.lastname ? userData.lastname : '',
      email: userData?.email ? userData.email : '',
      role: userData?.role ? userData.role?.id : '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema(type)),
  });
  const values = getValues();
  useEffect(() => {
    dispatch(getRoles(1, 30));
  }, []);

  const onSubmit = (data: any) => {
    delete data?.type;
    if (type == 'edit') return dispatch(updateUser(userData?.id, data, onClose, page));
    dispatch(sendUser(data, onClose, page));
  };

  return (
    <MuiModal
      width={'48.313rem'}
      height={'40rem'}
      title={type == 'create' ? 'Agregar usuario nuevo' : 'Editar usuario '}
      titleAlign={isMobile ? 'center' : 'left'}
      open={open}
      marginTop={isMobile ? '2rem' : '4rem'}
      onCancel={onClose}>
      <Box sx={styles.modalBody}>
        <Grid
          marginTop={!isMobile ? '2rem' : '0'}
          display={'flex'}
          justifyContent={'space-between'}
          container
          spacing={0}>
          <Box width={'100%'} marginTop={'1rem'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} sx={styles.inputContainer} justifyContent={'space-between'} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Nombre'
                  {...register('firstname')}
                  placeholder='Texto'
                  control={control}
                  helperText={errors?.firstname?.message ? errors?.firstname.message : ''}
                  error={!!errors?.firstname}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Apellido'
                  {...register('lastname')}
                  placeholder='Texto'
                  helperText={errors?.lastname?.message ? errors?.lastname.message : ''}
                  control={control}
                  error={!!errors?.lastname}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>

          <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Correo electrónico'
                  {...register('email')}
                  helperText={errors?.email?.message ? errors?.email.message : ''}
                  placeholder='Correo@gmail.com'
                  control={control}
                  error={!!errors?.email}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.secondInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <Select
                  control={control}
                  {...register('role')}
                  error={errors?.role?.message ? true : false}
                  ref={selectRef.current}
                  handleSelect={(value: string) => handleSelect(value)}
                  defaultValue={type == 'edit' ? userData?.role?.name : 'Seleccionar Rol'}
                  options={data}></Select>
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Contraseña'
                  {...register('password')}
                  placeholder='Texto'
                  control={control}
                  helperText={errors?.password?.message ? errors?.password.message : ''}
                  error={!!errors?.password}
                  ref={inputRef.current}
                />
              </Grid>
              <Grid item sx={styles.secondInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Confirmar contraseña'
                  {...register('confirmPassword')}
                  placeholder='Texto'
                  control={control}
                  helperText={errors?.confirmPassword?.message ? errors?.confirmPassword.message : ''}
                  error={!!errors?.confirmPassword}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
          <Button onClick={() => onClose()} variant='outlined' type='submit' sx={styles.button}>
            Cancelar
          </Button>

          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            {type == 'create' ? 'Agregar' : 'Guardar'}
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};
