import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    marginTop: '5rem',
    backgroundColor: palette.white,
    height: 'calc(100vh - 5rem);',
    scrollY: 'scroll',
    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(100% - 80%);',
    },
  }),
  rowText: (theme: Theme) => ({
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  }),
  backgroundImage: (theme: Theme) => ({
    width: '40rem',
    maxwIDTH: '40rem',
  }),
  title: (theme: Theme) => ({
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  fileText: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20ch',
    marginRight: '1rem',
  }),
  graphicsMain: (theme: Theme) => ({
    backgroundColor: palette.white,
    height: 'calc(100vh - 5rem);',
    scrollY: 'scroll',
    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(100% - 80%);',
    },
  }),
};
