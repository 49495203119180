import {
  GET_STATES,
  GET_MUNICIPALITY,
  GET_PARISHES,
  GET_REPORT_DATA,
  GET_EMPLOYERS,
  EXPORT_REPORT_DATA,
} from './action-types';
import { actionObject } from '../../utils';

export const getStates = () => actionObject(GET_STATES);
export const getMunicipalities = (states: any) => actionObject(GET_MUNICIPALITY, { states });
export const getParishes = (params: any) => actionObject(GET_PARISHES, params);
export const getReportData = (filterParams: any) => actionObject(GET_REPORT_DATA, filterParams);
export const getEmployers = () => actionObject(GET_EMPLOYERS);

export const exportReportData = (filterParams: any, callback?: ({ ok }: { ok: boolean; data: any }) => void) =>
  actionObject(EXPORT_REPORT_DATA, filterParams, callback);
