import { useRef, useEffect, useState } from 'react';
import { MuiModal, TextField, Select, InputFileUpload, AutocompleteSelectFree } from 'components';
import { Box, Button, Grid } from '@mui/material';
import { styles } from './styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData, uploadImage, updatePositionAdm } from 'store/actions';
import { productsSelector, registerSelector } from 'store/selectors';
import { useMediaQuery, Theme } from '@mui/material';

export const AddMembersModal = ({ open, onClose, type }: any) => {
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const dispatch = useDispatch();
  const { administrativeMembers, vigilanceMembers, positionsAdm, positionsWatch } = registerData;
  const { admMembers } = administrativeMembers;
  const { vigMembers } = vigilanceMembers;
  const [memberData, setMemberData]: any = useState();
  const [selectOptions, setSelectOptions]: any = useState();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { loading } = useSelector(productsSelector);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fullname: '',
      dni: '',
      email: '',
      phone: '',
      position: '',
      path_affidavit: '',
      path_personal_balance: '',
    },
    resolver: yupResolver(schema(type)),
  });
  const values = getValues();

  const handleSelect = (event: any) => {
    const {
      target: { value },
    } = event;

    let position: any = { id: value };

    clearErrors('position');
    setValue('position', position);
  };

  const onHandlerDocument = (key: any, file: any) => {
    if (!file) {
      setValue(key, '');
      return;
    }
    const { name } = file;

    const fileName = name?.substring(0, name?.lastIndexOf('.'));
    clearErrors(key);
    dispatch(uploadImage({ file, key: fileName }, setValue, key));
  };

  const onSubmit = (data: any) => {
    if (type == 'adm') {
      data.positionName = data.position.name;
      data.index = data.position.index;

      data.position = data.position.id;

      return prepareMembers(data);
    }
    data.position = data.position.id;
    prepareMembers(data);
  };

  const prepareMembers = (data: any) => {
    if (data == undefined) return;

    const membersCopy = [...memberData];

    const newUserList = membersCopy.push(data);

    if (type == 'adm') {
      dispatch(setEntityData({ ...registerData, administrativeMembers: { admMembers: [...membersCopy] } }));
      return onClose();
    }

    dispatch(setEntityData({ ...registerData, vigilanceMembers: { vigMembers: [...membersCopy] } }));
    return onClose();
  };

  const setAviablepositions = () => {
    // dispatch(getPositionAdm());
    // dispatch(getPositionWatch());

    const usedPositions = memberData?.map((item: any) => {
      return item?.position;
    });
    if (type == 'adm') {
      var filteredPositionsAdm = positionsAdm.filter((element: any) => !usedPositions?.includes(String(element?.id)));

      return setSelectOptions(filteredPositionsAdm);
    }
    var filteredPositionsWatch = positionsWatch.filter((element: any) => !usedPositions?.includes(String(element?.id)));

    return setSelectOptions(filteredPositionsWatch);
  };

  useEffect(() => {
    setMemberData(type == 'adm' ? admMembers : vigMembers);
    if (memberData) setAviablepositions();
  }, [type, memberData]);

  return (
    <MuiModal
      width={'60%'}
      title={type == 'adm' ? 'Agregar miembro del Consejo Administrativo' : 'Agregar miembro del Consejo de Vigilancia'}
      titleAlign={isMobile ? 'center' : 'left'}
      open={open}
      marginTop={isMobile ? '2rem' : '0'}
      onCancel={onClose}>
      <Box sx={styles.modalBody}>
        <Grid
          marginTop={!isMobile ? '2rem' : '0'}
          display={'flex'}
          justifyContent={'space-between'}
          container
          spacing={0}>
          <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} sx={styles.inputContainer} justifyContent={'space-between'} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                <TextField
                  fullWidth
                  label='Nombre completo'
                  {...register('fullname')}
                  placeholder='Texto'
                  control={control}
                  helperText={errors?.fullname?.message ? errors?.fullname.message : ''}
                  error={!!errors?.fullname}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                <TextField
                  fullWidth
                  label='Cédula'
                  {...register('dni')}
                  placeholder='V20000000'
                  helperText={errors?.dni?.message ? errors?.dni.message : ''}
                  control={control}
                  error={!!errors?.dni}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                <TextField
                  fullWidth
                  label='Teléfono'
                  {...register('phone')}
                  placeholder='04141234567'
                  helperText={errors?.phone?.message ? errors?.phone.message : ''}
                  control={control}
                  error={!!errors?.phone}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>

          <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Correo electrónico'
                  {...register('email')}
                  helperText={errors?.phone?.message ? errors?.phone.message : ''}
                  placeholder='@email.com'
                  control={control}
                  error={!!errors?.email}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.thirdInput} xs={12} sm={12} md={8} lg={8} xl={8}>
                {type == 'adm' ? (
                  <AutocompleteSelectFree
                    {...register('position')}
                    control={control}
                    label={'Seleccionar Cargo'}
                    options={selectOptions ?? []}
                    value={values?.position ?? ''}
                    setSelectOptions={(e: any) => {
                      setSelectOptions(e);
                      dispatch(updatePositionAdm(e));
                    }}
                    updateMemberMethod={(e: any) => setMemberData(e)}
                    handleSelection={null}
                    ref={selectRef.current}
                    limitTags={1}
                  />
                ) : (
                  <Select
                    control={control}
                    {...register('position')}
                    ref={selectRef.current}
                    error={errors?.position?.message ? true : false}
                    handleSelect={(value: string) => handleSelect(value)}
                    defaultValue={'Seleccionar Cargo'}
                    options={selectOptions}></Select>
                )}
              </Grid>
            </Box>
          </Box>

          {type == 'adm' && (
            <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
                <Grid item sx={styles.firstInput} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box display={'flex'} width='100%'>
                    <InputFileUpload
                      {...register('path_affidavit', { required: type == 'adm' ? true : false })}
                      fullWidth
                      control={control}
                      index={0}
                      externalName={values?.path_affidavit}
                      acceptedFormats={'.pdf'}
                      methodFile={(value: any) => onHandlerDocument('path_affidavit', value)}
                      ref={inputRef.current}
                      helperText={
                        errors?.path_affidavit?.message
                          ? errors?.path_affidavit?.message
                          : 'Declaración jurada de patrimonio de cada uno de los miembros de los Consejos de Administración'
                      }
                      error={!!errors?.path_affidavit}
                      label='Adjuntar Declaración Jurada'
                      withDeleteIcon={true}
                      loading={loading}
                    />
                  </Box>
                </Grid>

                <Grid item sx={styles.thirdInput} xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box display={'flex'} width='100%'>
                    <InputFileUpload
                      {...register('path_personal_balance')}
                      fullWidth
                      index={1}
                      withDeleteIcon={true}
                      acceptedFormats={'.pdf'}
                      externalName={values?.path_personal_balance}
                      methodFile={(value: any) => onHandlerDocument('path_personal_balance', value)}
                      control={control}
                      helperText={
                        errors?.path_personal_balance?.message
                          ? errors?.path_personal_balance?.message
                          : 'Visado por contador público colegiado. Art. 27 LCAFAAAS'
                      }
                      ref={inputRef.current}
                      error={!!errors?.path_personal_balance}
                      label='Adjuntar Balance Personal'
                      loading={loading}
                    />
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>

        <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
          <Button onClick={() => onClose()} variant='outlined' type='submit' sx={styles.button}>
            Cancelar
          </Button>

          <Button onClick={handleSubmit(onSubmit, type)} type='submit' sx={styles.button} variant='contained'>
            Agregar
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};
