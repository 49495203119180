import * as Yup from 'yup';
import { emailRegex, RifRegex, CiOrRifRegex, onlyNumbersFirstNoZero } from '../../utils';

export const schema = Yup.object().shape({
  register_number: Yup.string()
    .required('Campo requerido')
    .min(1, `Este campo debe tener al menos ${1} caracter`)
    .max(4, `Este campo no debe tener mas de ${4} caracteres`)
    .matches(
      onlyNumbersFirstNoZero,
      'Este campo solo debe tener caracteres numéricos (El primer numero no puede ser 0).',
    ),

  rif: Yup.string()
    .required('Campo requerido')
    .matches(RifRegex, 'Este campo debe poseer un formato valido como J00000000'),
  name: Yup.string().notRequired().max(255, `Este campo no debe tener mas de  ${255} caracteres`),

  email: Yup.string()
    .required('Campo requerido')
    .max(255, `Este campo no debe tener mas de  ${255} caracteres`)
    .matches(emailRegex, 'Ingrese un formato válido, por ejemplo email@gmail.com'),

  user_document: Yup.string()
    .required('Campo requerido')
    .matches(CiOrRifRegex, ' Este campo debe poseer un formato valido como J000000000 O V00000000'),
});
