import { Typography, Table, TableBody, TableHead, TableRow, TableCell, tableCellClasses } from '@mui/material';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { styles } from '../../styles';
import { parseAmount } from 'utils';

export const FinancialTable: FC<any> = ({ title, rows, totalAmount }: any) => {
  return (
    <Table
      sx={{
        bgcolor: palette.white,
        borderRadius: '0.625rem',
        width: '98%',
        boxShadow: 2,
        marginLeft: '1%',
        marginRight: '1%',
        marginBottom: '2rem',
      }}
      aria-label='simple table'>
      <TableHead
        sx={{
          width: '52.5rem',
          [`& .${tableCellClasses.root}`]: {
            padding: '1rem',
          },
        }}>
        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              paddingTop: '0.6rem',
              paddingBottom: '0.6rem',
              border: 'none',
              bgcolor: palette.dark,
              borderRadius: '0.438rem',
            },
          }}>
          <TableCell align={'left'} width={'100%'} colSpan={12}>
            <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
              {title}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {rows?.length > 0 ? (
          <>
            {rows?.map((row: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  <Typography fontSize={'0.875rem'} fontWeight={'700'} sx={styles.rowText}>
                    {row.name}{' '}
                  </Typography>
                </TableCell>

                <TableCell align='right' component='th' scope='row'>
                  <Typography fontSize={'0.875rem'} sx={styles.rowText}>
                    {parseAmount(row.value)} bs
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow
            sx={{
              height: '1rem',
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell align='center' colSpan={12}></TableCell>
          </TableRow>
        )}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component='th' scope='row'>
            <Typography fontSize={'0.875rem'} fontWeight={'700'} sx={styles.rowText}>
              Total
            </Typography>
          </TableCell>

          <TableCell align='right' component='th' scope='row'>
            <Typography fontSize={'0.875rem'} fontWeight={'700'} sx={styles.rowText}>
              {totalAmount} bs
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
