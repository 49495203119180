import { Typography, Box } from '@mui/material';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { IndicatorCardTypes } from './types';
import { parseAmount } from 'utils';

export const IndicatorCard: FC<IndicatorCardTypes> = ({ title = '', total = 0, showCurrency = false }) => {
  return (
    <Box
      display={'flex'}
      borderRadius={'0.75rem'}
      width={'100%'}
      bgcolor={palette?.light}
      flexDirection={'column'}
      padding={'1rem'}>
      <Typography textAlign={'left'} variant='caption'>
        {title}
      </Typography>
      <Typography textAlign={'right'} variant='button'>
        {`${total ? parseAmount(total) : 0} ${showCurrency ? 'bs' : ''}`}
      </Typography>
    </Box>
  );
};
