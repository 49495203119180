import { LOGIN, LOGOUT, USER_LOGIN, PRE_REGISTER, FORGOT_PASSWORD, RESTORE_PASSWORD } from './action-types';
import { actionObject } from '../../utils';

export const loginUser = (body: any, navigate: any) => actionObject(LOGIN, { body, navigate });
export const preRegister = (body: any, switchStatus: any, resetForm: any) =>
  actionObject(PRE_REGISTER, { body, switchStatus, resetForm });
export const forgotPassword = (body: any) => actionObject(FORGOT_PASSWORD, { body });
export const restorePassword = (body: any, navigate: any) => actionObject(RESTORE_PASSWORD, { body, navigate });
export const logout = () => actionObject(LOGOUT);
export const userlogin = (body: any, navigate: any) => actionObject(USER_LOGIN, { body, navigate });
