import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from './types';
import { MuiModal } from 'components';

import { styles } from './styles';
import { palette } from 'theme/contants';
import { hexColors } from 'utils';

export const ModalDetail: FC<ModalTypes> = ({ title, open, onClose, detailData }) => {
  return (
    <MuiModal
      open={open}
      width={'32.563rem'}
      height={'38.063rem'}
      title={title}
      titleAlign={'left'}
      onCancel={() => onClose()}>
      <Box
        display={'flex'}
        height={'100%'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignContent={'center'}>
        <Box height={'100%'} sx={{ overflowY: 'scroll' }} marginBottom={'1rem'}>
          {(detailData ?? []).map((item: any, index: number) => (
            <>
              {item?.name && (
                <Box
                  key={index}
                  display={'flex'}
                  margin={'1rem 0'}
                  width={'100%'}
                  flexDirection={'column'}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}>
                  <Typography variant='caption'>{`Estado ${item?.name}`}</Typography>
                  {(item?.values ?? []).map((element: any, id: number) => (
                    <Box key={id} display={'flex'} width={'100%'} marginTop={'0.5rem'} justifyContent={'space-between'}>
                      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box
                          width={'3rem'}
                          height={'1.075rem'}
                          display={'flex'}
                          justifyContent={'center'}
                          borderRadius={'0.625rem'}
                          bgcolor={hexColors[id]}
                        />{' '}
                        {title == 'Cantidad de Asociados' ? (
                          <Typography margin={'0 0.7rem'} fontSize={'0.875rem'} color={palette?.black}>
                            {`${element?.value} Cajas ${element?.label}s`}
                          </Typography>
                        ) : (
                          <Typography margin={'0 0.7rem'} fontSize={'0.875rem'} color={palette?.black}>
                            {title == 'Cantidad de Asociados' ? `Cajas ${element?.label}` : `Sector ${element?.label}`}
                          </Typography>
                        )}
                      </Box>
                      <Box display={'flex'} justifyContent={'flex-end'} marginRight={'0.5rem'} alignItems={'center'}>
                        {title == 'Cantidad de Asociados' ? (
                          <Typography margin={'0.2rem'} variant='caption' color={palette?.black}>
                            {`${Number(element?.associates).toLocaleString('de-DE')} asociados`}
                          </Typography>
                        ) : (
                          <Typography margin={'0.2rem'} variant='caption' color={palette?.black}>
                            {element?.value}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </>
          ))}
        </Box>
        <Box display={'flex'} margin={'1rem 0'} justifyContent={'flex-end'} width={'100%'} sx={styles.containerButton}>
          <Button onClick={() => onClose()} sx={styles.button} variant='contained'>
            Aceptar
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};
