import { useRef, useState, useEffect } from 'react';
import { InputAmount } from 'components';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { parseAmount, parseTotalAmount, plainNumberString, handleAmountFormat } from 'utils';
import { useMediaQuery, Theme } from '@mui/material';

export const Liability = () => {
  const inputRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const { liability, totalLiability } = registerData;
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      accounts_payable: liability?.accounts_payable ? handleAmountFormat(liability?.accounts_payable) : '',
      commitments: liability?.commitments ? handleAmountFormat(liability?.commitments) : '',
      items_payable: liability?.items_payable ? handleAmountFormat(liability?.items_payable) : '',
      other_liabilities: liability?.other_liabilities ? handleAmountFormat(liability?.other_liabilities) : '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    validateZero();
    const newData = plainNumberString(data);
    dispatch(setEntityData({ ...registerData, liability: newData, totalLiability: totalAmount, financialStep: 6 }));
  };
  const returnPreviousStep = () => {
    dispatch(setEntityData({ ...registerData, financialStep: 4 }));
  };

  const onHandler = () => {
    const formValues = getValues();
    parseTotalAmount(formValues, setTotalAmount);
  };

  useEffect(() => {
    const formValues = getValues();
    setTotalAmount(totalLiability);
    parseTotalAmount(formValues, setTotalAmount);
  }, []);

  const validateZero = () => {
    const formValues = getValues();
    const newvalue = Object.entries(formValues).map((item: any) => {
      if (Number(item[1]) <= 0 || item[1] == '0,00') return setValue(item[0], String(0));
    });
  };

  const values = getValues();

  // UseEnterPress(() => onSubmit(values));

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            fontWeight={'600'}
            marginTop={'1rem'}
            color={palette.black}>
            Pasivos
          </Typography>

          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Cuentas por Pagar'
                {...register('accounts_payable')}
                control={control}
                placeholder={'0.00'}
                handlerAmount={() => {
                  onHandler();
                }}
                helperText={
                  errors?.accounts_payable?.message
                    ? errors?.accounts_payable?.message
                    : 'Incluye: honorarios profesionales, servicios basicos por pagar, alquileres, proveedores, entre otros.'
                }
                error={!!errors?.accounts_payable}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Compromisos Laborales'
                {...register('commitments')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.commitments?.message
                    ? errors?.commitments.message
                    : 'Registra los saldos de las obligaciones con sus trabajadores en cumplimiento de las leyes sociales vigentes.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.commitments}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Haberes por Pagar'
                {...register('items_payable')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.items_payable?.message
                    ? errors?.items_payable.message
                    : 'Registre las obligaciones que tiene la asociación con el asociado y el exasociado y que por alguna circunstancia no han sido cancelados.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.items_payable}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Otros Pasivos'
                {...register('other_liabilities')}
                helperText={
                  errors?.other_liabilities?.message
                    ? errors?.other_liabilities.message
                    : 'Registre el saldo total de los otros pasivos (Intereses por pagar, cobranzas realizadas por anticipado).'
                }
                handlerAmount={() => onHandler()}
                control={control}
                placeholder={'0.00'}
                error={!!errors?.other_liabilities}
                ref={inputRef.current}
              />
            </Grid>
            {!isMobile && <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>
      </Grid>
      <Box sx={{ wdith: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={styles.amount}
          marginTop={isMobile ? '1rem' : '3rem'}
          marginBottom={isMobile ? '1.5rem' : '0'}
          fontWeight='600'
          fontSize={'1.25rem'}>
          Total: <span style={{ fontWeight: '400' }}>{parseAmount(totalAmount)}</span>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        sx={styles.buttonContainer}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Box sx={styles.amounContainer}>
            <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
              Continuar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
