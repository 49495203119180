import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    backgroundColor: palette?.white,
    padding: '0 2rem',
    minHeight: '18vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: '18vh',
    },
  }),

  section: (theme: Theme) => ({
    color: '#000000',
    '&:hover': {
      color: palette.primary,
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  sectionText: {
    '&:hover': {
      color: palette.primary,
      cursor: 'pointer',
    },
  },

  button: (theme: Theme) => ({
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  menuButton: (theme: Theme) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  }),
  swipeableDrawerStyles: (theme: Theme) => ({
    width: '100%',
    height: `calc(100vh - 7rem)`,
    top: 0,
    '& .MuiPaper-root': {
      width: '100%',
      height: `100%`,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
    zIndex: 1300,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  responsiveButton: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '2rem',
    },
  }),
};
