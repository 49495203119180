import { createTheme } from '@mui/material/styles';
import { OverridesComponents } from './components';
import { palette } from './contants';

// Generate defualt MUI theme for breakpoints
export const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary,
      dark: palette.dark,
    },
    secondary: {
      main: palette.light,
    },
    warning: {
      main: palette.warning,
    },
    success: {
      main: palette.success,
    },
    error: {
      main: palette.error,
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    h1: {
      fontSize: '4rem',
      fontWeight: '400',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: '700',
      lineHeight: '1.2',
      [breakpoints.down('lg')]: {
        fontSize: '1.25rem',
      },
    },
    // H3 y H4 igual, solo que h3 es bold
    h3: {
      fontSize: '2rem',
      fontWeight: '600',
      [breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontSize: '2rem',
      fontWeight: '400',
      [breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    // TODO: FALTA H5
    // H6 Son los links
    h6: {
      fontSize: '0.75rem',
      fontWeight: '400',
    },
    // Los 1 van a ser exactamente igual que el 2 pero bold
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: '600',
      [breakpoints.down('md')]: {
        fontSize: '0.875rem',
      },
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: '400',
      [breakpoints.down('md')]: {
        fontSize: '0.875rem',
      },
    },
    body1: {
      fontSize: '1.25rem',
      fontWeight: '400',
    },
    body2: {
      fontSize: '1.25rem',
      fontWeight: '600',
      [breakpoints.down('md')]: {
        fontSize: '0.875rem',
        fontWeight: '700',
      },
    },
    caption: {
      fontSize: '1rem',
      fontWeight: '600',
    },
    button: {
      textTransform: 'none',
      fontSize: '0.875rem',
      fontWeight: '400',
    },
  },

  components: OverridesComponents,
});
