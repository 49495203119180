import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { palette } from 'theme/contants';
import { DoughnutChart, MuiChips } from 'components';
import { DoughnutCardTyes } from './types';
import { UseIntermitence } from 'hooks';
import { ModalDetail } from '../ModalDetail';

export const DoughnutCard: FC<DoughnutCardTyes> = ({ title, chartData, total, detailData }) => {
  const { status, switchStatus } = UseIntermitence();

  return (
    <>
      <Box
        width={'100%'}
        height={'100%'}
        bgcolor={palette?.light}
        // boxShadow={2}
        borderRadius={'0.75rem'}
        padding={'1rem'}>
        <Box display={'flex'} justifyContent={'flex-start'} flexDirection={'column'} width={'100%'} height={'100%'}>
          <Typography variant='subtitle1'>{title}</Typography>
          <Box
            display={'flex'}
            paddingLeft={'3rem'}
            justifyContent={'space-between'}
            height={'100%'}
            alignItems={'center'}
            width={'100%'}>
            <Box width={'12rem'} position={'relative'}>
              <Typography
                position={'absolute'}
                sx={{ transform: 'translate(-50%, -50%)' }}
                top={'50%'}
                left={'50%'}
                variant='subtitle1'>
                {`Total ${total}`}
              </Typography>

              <DoughnutChart chartValues={chartData} />
            </Box>
            <Box width={'60%'} height={'100%'}>
              <Box
                display={'flex'}
                height={'100%'}
                justifyContent={'space-between'}
                width={'100%'}
                flexDirection={'column'}
                alignItems={'flex-start'}>
                <Box></Box>
                <Box>
                  {(chartData ?? []).map((item: any, index: number) => (
                    <Box
                      key={index}
                      display={'flex'}
                      width={'100%'}
                      padding={'0 2rem'}
                      justifyContent={'space-between'}
                      alignItems={'center'}>
                      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box
                          width={'3rem'}
                          height={'1.075rem'}
                          display={'flex'}
                          justifyContent={'center'}
                          borderRadius={'0.625rem'}
                          bgcolor={item?.colors}
                        />
                        <Typography margin={'0 0.7rem'} fontSize={'1rem'} color={palette?.black}>
                          {title == 'Cantidad de cajas de Ahorro' ? `${'Sector'} ${item?.labels}` : item?.labels}
                        </Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Typography margin={'0.2rem'} variant='subtitle1' color={palette?.black}>
                          {item?.values}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                  <MuiChips method={() => switchStatus()} isSelected={true} text={'Ver detalle'} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {status && <ModalDetail open={status} title={title} onClose={() => switchStatus()} detailData={detailData} />}
    </>
  );
};
