import * as Yup from 'yup';

export const schema = Yup.object().shape({
  date_swearing_certificate: Yup.string().required('Campo requerido'),
  elected_start_date: Yup.string().required('Campo requerido'),
  elected_end_date: Yup.string().required('Campo requerido'),
  path_swearing_certificate: Yup.string().required('Campo requerido'),
  date_constitutive_act: Yup.string().required('Campo requerido'),
  path_constitutive_act: Yup.string().required('Campo requerido'),
  date_proto_constitutive_act: Yup.string().required('Campo requerido'),
  date_statute: Yup.string().required('Campo requerido'),
  statute: Yup.string().required('Campo requerido'),
});
