import * as Yup from 'yup';

export const schema = Yup.object().shape({
  accounts_payable: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  commitments: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  items_payable: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
  other_liabilities: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
});
