import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';
import { Drawer as MuiDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { baseUrl } from 'paths';
import { Location } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { menuOptions } from 'utils';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';

export const DashboardDrawer = ({ location }: { location: Location }) => {
  const {
    user: {
      type = 'saving',
      role
    },
  } = useSelector(authSelector);
  const navigate = useNavigate();

  const navigateTo = (route: string) => navigate(route);

  return (
    <MuiDrawer variant='permanent' anchor='left'>
      <Box sx={{ padding: '1.6875rem 1.6875rem 0 2.1875rem' }}>
        <Box component={'img'} src={`${baseUrl}/resources/logo-black.svg`} alt='onboarding-img' width={150} />
      </Box>
      <List
        disablePadding
        sx={{
          mt: '2.5rem',
        }}>
        {menuOptions?.map((option, index) => {
          if(option.type === 'admin' && option.permission) {
            if(role && role.permissions && role.permissions.includes(option.permission)) {
              return (
                <Box key={index}>
                  {option.type === type && (
                    <ListItem key={option?.label} disablePadding onClick={() => navigateTo(option?.route)}>
                      <ListItemButton
                        disableGutters
                        sx={{
                          padding: '0.8125rem 1.6875rem 0.8125rem 2.1875rem',
                          bgcolor: location.pathname.startsWith(option.route) ? '#C3C8DD' : palette?.white,
                          borderLeft: location.pathname.startsWith(option.route) ? `5px solid ${palette?.dark}` : '',
                          borderRadius: '0.063rem',
                        }}>
                        <ListItemIcon sx={{ minWidth: '2.375rem' }}>
                          <Icon icon={option.icon} width={'1.5rem'} height={'1.5rem'} color={palette?.dark} />
                        </ListItemIcon>
                        <ListItemText
                          primary={option?.label}
                          sx={{
                            margin: 0,
                            '& .MuiTypography-root': {
                              fontSize: '0.875rem',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                </Box>
              );
            }
          } else {
            return (
              <Box key={index}>
                {option.type === type && (
                  <ListItem key={option?.label} disablePadding onClick={() => navigateTo(option?.route)}>
                    <ListItemButton
                      disableGutters
                      sx={{
                        padding: '0.8125rem 1.6875rem 0.8125rem 2.1875rem',
                        bgcolor: location.pathname.startsWith(option.route) ? '#C3C8DD' : palette?.white,
                        borderLeft: location.pathname.startsWith(option.route) ? `5px solid ${palette?.dark}` : '',
                        borderRadius: '0.063rem',
                      }}>
                      <ListItemIcon sx={{ minWidth: '2.375rem' }}>
                        <Icon icon={option.icon} width={'1.5rem'} height={'1.5rem'} color={palette?.dark} />
                      </ListItemIcon>
                      <ListItemText
                        primary={option?.label}
                        sx={{
                          margin: 0,
                          '& .MuiTypography-root': {
                            fontSize: '0.875rem',
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </Box>
            );
          }
        })}
      </List>
    </MuiDrawer>
  );
};
