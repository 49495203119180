import * as Yup from 'yup';
import { onlyNumbersRegex, RifRegex } from 'utils';

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),

  rif: Yup.string()
    .required('Campo requerido')
    .matches(RifRegex, 'Este campo debe poseer un formato valido como J000000000'),

  register_number: Yup.string()
    .required('Campo requerido')
    .min(1, `Este campo debe tener al menos ${1} caracteres`)
    .max(4, `Este campo no debe tener mas de ${4} caracteres`),

  address: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),

  employer: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),

  number_associates: Yup.string()
    .required('Campo requerido')
    .matches(onlyNumbersRegex, 'Este campo no debe tener caracteres numéricos'),
});
