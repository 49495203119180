import { SelectProps, Chip, Autocomplete, TextField, IconButton, Button } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';

interface IAditionalProps {
  name: string;
  control: any;
  error: boolean;
  defaultValue: string;
  options?: any;
  handleSelect: any;
  value: any;
  handleSelection: any;
  placeholder: any;
  nameOnly: boolean;
  multiple: boolean;
  handleSearch: any;
  errorMessage: string;
  chipsColors: any;
  limitTags: number;
  disabled?: boolean;
  disableClearButton?: boolean;
}
type Props = SelectProps | IAditionalProps;

export const AutocompleteSelect: FC<Props> = ({
  name,
  control,
  error = false,
  options,
  ref,
  defaultValue,
  handleSelect,
  value,
  handleSelection,
  label,
  nameOnly = false,
  multiple = true,
  handleSearch = null,
  errorMessage = '',
  chipsColors = null,
  limitTags = 1,
  disabled = false,
  placeholder = 'Seleccionar',
  disableClearButton = false,
  ...props
}: any) => {
  const baseChips = (value: any, getTagProps: any) =>
    value.map((option: any, index: string | number) => (
      <Chip
        key={option.name}
        variant='filled'
        label={nameOnly ? option : option.name}
        size='small'
        deleteIcon={
          <IconButton size='small' edge='end' aria-label='delete'>
            <Icon icon='mdi:close-circle' width={18} color={palette.codGray} />
          </IconButton>
        }
        sx={{ backgroundColor: palette?.scorpion, color: palette?.white, padding: '4px', borderRadius: '0.438rem' }}
        {...getTagProps({ index })}
      />
    ));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            multiple={multiple}
            freeSolo
            id='project-select'
            options={options}
            disabled={disabled}
            disableCloseOnSelect={multiple}
            disableClearable={disableClearButton}
            isOptionEqualToValue={(option: any, value) => option.id === value.id}
            sx={{
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: '4.5px 7px !important',
              },
              '.MuiAutocomplete-tagSizeMedium:not(.MuiChip-root)': {
                color: palette?.darkGray,
              },
            }}
            componentsProps={{
              paper: {
                sx: {
                  borderRadius: '0.75rem',
                },
              },
              popper: {
                sx: {
                  zIndex: 1600,
                },
              },
            }}
            // getOptionLabel={(option: any) => option.name}
            getOptionLabel={(option) => (multiple ? `${option.name} (${option.id})` : option.name)}
            renderOption={(props, option: any, { selected }) => (
              <li
                {...props}
                id={nameOnly ? option : option.id}
                style={{
                  backgroundColor: selected ? palette?.scorpion : palette.white,
                  margin: '10px',
                  borderRadius: '0.438rem',
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: '1.5rem',
                  color: selected ? palette?.white : palette.scorpion,
                }}>
                {nameOnly ? option : option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                placeholder={field?.value?.length > 0 ? '' : placeholder}
                label={label}
                {...params}
                sx={{
                  '& fieldset': error && {
                    border: `2px solid ${palette.error} !important`,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '15px',
                      border: `1px solid ${disabled ? palette.gray : palette.dark} !important`,
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={baseChips}
            popupIcon={<Icon icon={'heroicons-solid:chevron-down'} height={20} width={20} color={palette?.black} />}
            {...props}
            {...field}
            limitTags={limitTags}
            onChange={(_, value) => {
              field.onChange(value);
              handleSelection(value);
            }}
          />
        );
      }}
    />
  );
};
