import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  modalBox: (theme: Theme) => ({
    bgcolor: '#FFFFFF',
    p: 5,
    borderRadius: '0.5625rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('lg')]: {
      width: '90%',
      marginLeft: 0,
      overflowY: 'scroll',
      maxHeight: '80%',
      padding: '1rem',
    },
  }),
  modal: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    zIndex: 1307,
  }),

  modalTitle: (theme: Theme) => ({
    color: palette.black,
    // fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.25rem',
      fontWeight: '700',
    },
  }),
};
