import { Alert, Snackbar, Slide, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { intermitenceSelector } from 'store/selectors';

export const Toast = () => {
  const { toast } = useSelector(intermitenceSelector);

  return (
    <Slide direction='down' in={toast.show} timeout={{ enter: 600, exit: 600 }}>
      <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ marginTop: '0.3rem' }}>
        <Alert severity={toast?.type} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='button'>{toast?.message}</Typography>
        </Alert>
      </Snackbar>
    </Slide>
  );
};
