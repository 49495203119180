import {
  SET_ENTITY_DATA,
  SET_ENTITY_DATA_ASYNC,
  LOADING_FINANCIAL_REGISTER,
  RESET_FORM,
  GET_FINANCIAL_DATA_ASYNC,
  LOADING_FINANCIAL_GET,
  UPDATE_FINANCIAL_DATA_ASYNC,
  LOADING_LEGAL_REGISTER,
  GET_LEGAL_DATA_ASYNC,
  LOADING_LEGAL_GET,
  GET_POSITION_ADM_DATA_ASYNC,
  GET_POSITION_WATCH_DATA_ASYNC,
  RESET_REGISTER,
  RESET_POSITION,
  UPDATE_POSITION_ADM_DATA,
  UPDATE_POSITION_ADM_TABLE_DATA,
  SAVE_POSITION_ADM_DATA_ASYNC,
  GET_MEMBERS_ADM_DATA_ASYNC,
} from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  entityData: { completed: false },
  protocolData: { completed: false },
  administrativeMembers: { admMembers: [], completed: false },
  vigilanceMembers: { vigMembers: [], completed: false },
  financialStep: 1,
  legalStep: 1,
  explainAdm: '',
  explainVig: '',
  incomes: { completed: false },
  expenses: { completed: false },
  liability: { completed: false },
  assets: { completed: false },
  capital: { completed: false },
  legalStatus: '',
  financialStatus: '',
  totalIncomes: 0,
  totalExpenses: 0,
  totalLiability: 0,
  totalCurrentAssets: 0,
  totalNotCurrentAssets: 0,
  totalCapital: 0,
  financialLoading: false,
  getFinancialLoading: false,
  legalLoading: false,
  getLegalLoading: false,
  positionsAdm: [],
  positionsAdmTable: [],
  positionsWatch: [],
};

const register = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case SET_ENTITY_DATA:
      return { ...state, ...payload };
    case SET_ENTITY_DATA_ASYNC:
      return initialState;

    case LOADING_FINANCIAL_REGISTER:
      return { ...state, financialLoading: payload };

    case LOADING_FINANCIAL_GET:
      return { ...state, getFinancialLoading: payload };

    case GET_FINANCIAL_DATA_ASYNC:
      return { ...state, ...payload };

    case UPDATE_FINANCIAL_DATA_ASYNC:
      return { ...state, ...payload };

    case RESET_FORM:
      return {
        ...state,
        ...{
          incomes: { completed: false },
          expenses: { completed: false },
          liability: { completed: false },
          assets: { completed: false },
          capital: { completed: false },
        },
        entityData: { completed: false },
        protocolData: { completed: false },
        administrativeMembers: { admMembers: [], completed: false },
        vigilanceMembers: { vigMembers: [], completed: false },
        explainAdm: '',
        explainVig: '',
      };

    case LOADING_LEGAL_REGISTER:
      return { ...state, legalLoading: payload };

    case LOADING_LEGAL_GET:
      return { ...state, getLegalLoading: payload };

    case GET_LEGAL_DATA_ASYNC:
      return { ...state, ...payload };

    case GET_POSITION_ADM_DATA_ASYNC:
      return { ...state, positionsAdm: payload, positionsAdmTable: payload };

    case UPDATE_POSITION_ADM_DATA:
      return { ...state, positionsAdm: payload };

    case UPDATE_POSITION_ADM_TABLE_DATA:
      return { ...state, positionsAdmTable: payload };

    case GET_POSITION_WATCH_DATA_ASYNC:
      return { ...state, positionsWatch: payload };

    case RESET_REGISTER:
      return { ...initialState };

    case RESET_POSITION:
      return { ...state, positionsAdm: [], positionsWatch: [], positionsAdmTable: [] };

    case SAVE_POSITION_ADM_DATA_ASYNC:
      return { ...state, positionsAdm: payload };

    case GET_MEMBERS_ADM_DATA_ASYNC:
      return { ...state, administrativeMembers: { admMembers: payload.members?.admMembers } };
    default:
      return state;
  }
};

export default register;
