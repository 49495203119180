import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';

export const ProtectedRoute = () => {
  const { user } = useSelector(authSelector);

  if (!user && !user?.accessToken) {
    return <Navigate to='/' replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
