import {
  SET_ENTITY_DATA,
  CLEAR_ENTITY_DATA,
  SEND_FINANCIAL_DATA,
  GET_FINANCIAL_DATA,
  RESET_FORM,
  UPDATE_FINANCIAL_DATA,
  SEND_LEGAL_DATA,
  GET_LEGAL_DATA,
  GET_POSITION_ADM_DATA,
  GET_POSITION_WATCH_DATA,
  RESET_REGISTER,
  DELETE_FILE,
  RESET_POSITION,
  UPDATE_POSITION_ADM_DATA,
  UPDATE_POSITION_ADM_TABLE_DATA,
  SAVE_POSITION_ADM_DATA,
  GET_MEMBERS_ADM_DATA,
} from './action-types';
import { actionObject } from '../../utils';

export const setEntityData = (data: any) => actionObject(SET_ENTITY_DATA, data);
export const clearEntityData = () => actionObject(CLEAR_ENTITY_DATA);
export const sendFinancialData = (navigation: any, capitalData: any) =>
  actionObject(SEND_FINANCIAL_DATA, { navigation, capitalData });
export const getFinancialData = () => actionObject(GET_FINANCIAL_DATA);
export const resetFinancialForm = () => actionObject(RESET_FORM);
export const updateFinancialData = () => actionObject(UPDATE_FINANCIAL_DATA);
export const sendLegalData = (navigation: any) => actionObject(SEND_LEGAL_DATA, { navigation });
export const getLegalData = () => actionObject(GET_LEGAL_DATA);
export const resetLegalForm = () => actionObject(RESET_FORM);
export const getPositionAdm = () => actionObject(GET_POSITION_ADM_DATA);
export const getPositionWatch = () => actionObject(GET_POSITION_WATCH_DATA);
export const resetRegister = () => actionObject(RESET_REGISTER);
export const deleteFile = (name: string) => actionObject(DELETE_FILE, name);
export const resetPositions = () => actionObject(RESET_POSITION);

export const updatePositionAdm = (body: any) => actionObject(UPDATE_POSITION_ADM_DATA, body);
export const updatePositionAdmTable = (body: any) => actionObject(UPDATE_POSITION_ADM_TABLE_DATA, body);

export const savePositionAdm = (body: any) => actionObject(SAVE_POSITION_ADM_DATA, body);

export const getMembersAdm = () => actionObject(GET_MEMBERS_ADM_DATA);
