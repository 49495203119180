import { SelectProps, Chip, Autocomplete, TextField, IconButton, createFilterOptions } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';
import { registerSelector } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData, updatePositionAdmTable, updatePositionAdm, savePositionAdm } from 'store/actions';

interface IAditionalProps {
  name: string;
  control: any;
  error: boolean;
  defaultValue: string;
  options?: any;
  handleSelect: any;
  value: any;
  handleSelection: any;
  placeholder: any;
  nameOnly: boolean;
  multiple: boolean;
  handleSearch: any;
  errorMessage: string;
  chipsColors: any;
  limitTags: number;
  disabled?: boolean;
  setSelectOptions?: any;
  updateMemberMethod?: any;
}
type Props = SelectProps | IAditionalProps;

export const AutocompleteSelectFree: FC<Props> = ({
  name,
  control,
  error = false,
  options,
  ref,
  defaultValue,

  value,
  handleSelection,
  label,
  nameOnly = false,
  multiple = true,
  handleSearch = null,
  errorMessage = '',
  chipsColors = null,
  limitTags = 1,
  disabled = false,
  setSelectOptions,
  updateMemberMethod,
  ...props
}: any) => {
  const filter = createFilterOptions();

  const [showAddOption, setShowAddOption] = useState(true);
  const registerData = useSelector(registerSelector);
  const { administrativeMembers, positionsAdm } = registerData;
  const dispatch = useDispatch();

  useEffect(() => {
    if (positionsAdm.length > 6) {
      fillPositions();
    }
  }, [positionsAdm, options]);

  const fillPositions = () => {
    const customPosition = positionsAdm.find((option: any) => option.index === 6);
    const customPositionSubstitute = positionsAdm.find((option: any) => option.index === 7);

    if (customPosition && !customPositionSubstitute) {
      const newOptions = [...positionsAdm, { name: `${customPosition?.name} Suplente`, id: '1', index: 7 }];
      dispatch(updatePositionAdm(newOptions));
      dispatch(updatePositionAdmTable(newOptions));
      return setShowAddOption(false);
    }

    if (!customPosition && customPositionSubstitute) {
      let splited = customPositionSubstitute?.name.split(' Suplente');

      const newOptions = [...positionsAdm, { name: `${splited[0]}`, id: '0', index: 6 }];
      dispatch(updatePositionAdm(newOptions));
      dispatch(updatePositionAdmTable(newOptions));
      return setShowAddOption(false);
    }

    if (customPosition && customPositionSubstitute) {
      return null;
    }
    if (!customPosition && !customPositionSubstitute) {
      return setShowAddOption(true);
    }
  };

  const baseChips = (value: any, getTagProps: any) =>
    value.map((option: any, index: string | number) => (
      <Chip
        key={nameOnly ? option : option.id}
        variant='filled'
        label={nameOnly ? option : option.name}
        size='small'
        deleteIcon={
          <IconButton size='small' edge='end' aria-label='delete'>
            <Icon icon='mdi:close-circle' width={18} color={palette.codGray} />
          </IconButton>
        }
        sx={{ backgroundColor: palette?.scorpion, color: palette?.white, padding: '4px', borderRadius: '0.438rem' }}
        {...getTagProps({ index })}
      />
    ));

  const addInputToOptions = (inputValue: string, updateOptions: any) => {
    const existingOption = options.find((option: any) => option.title === inputValue);

    if (!existingOption) {
      const newOptions = [
        ...options,
        { name: inputValue, id: '0', index: 6 },
        { name: `${inputValue} Suplente`, id: '1', index: 7 },
      ];
      const newOptionsTable = [
        ...positionsAdm,
        { name: inputValue, id: '0', index: 6 },
        { name: `${inputValue} Suplente`, id: '1', index: 7 },
      ];

      setShowAddOption(false);
      dispatch(updatePositionAdmTable(newOptionsTable));
      updateOptions(newOptions);
    }
  };

  const handleDeleteOption = (setSelectOptions: any) => {
    const newOptions = options.filter((item: any) => item.index !== 6 && item.index !== 7);
    const newPositions = administrativeMembers?.admMembers.filter((item: any) => item.index !== 6 && item.index !== 7);

    dispatch(setEntityData({ ...registerData, administrativeMembers: { admMembers: newPositions } }));
    dispatch(updatePositionAdmTable(newOptions));
    setSelectOptions(newOptions);
    updateMemberMethod(newOptions);
    setShowAddOption(true);
    dispatch(savePositionAdm({ adminCouncil: newPositions }));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            multiple={false}
            freeSolo
            id='project-select'
            options={options}
            disabled={disabled}
            disableCloseOnSelect={multiple}
            isOptionEqualToValue={(option: any, value) => option.id === value.id}
            disableClearable={!multiple}
            sx={{
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: '4.5px 7px !important',
              },
              '.MuiAutocomplete-tagSizeMedium:not(.MuiChip-root)': {
                color: palette?.darkGray,
              },
            }}
            componentsProps={{
              paper: {
                sx: {
                  borderRadius: '0.75rem',
                },
              },
              popper: {
                sx: {
                  zIndex: 1600,
                },
              },
            }}
            getOptionLabel={(option: any) => option.name ?? ''}
            renderOption={(props: any, option: any, { selected }: any) => (
              <li
                {...props}
                id={nameOnly ? option : option.id}
                style={{
                  backgroundColor: selected ? palette?.scorpion : palette.white,
                  margin: '10px',
                  borderRadius: '0.438rem',
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: '1.5rem',
                  color: selected ? palette?.white : palette.scorpion,
                }}>
                {nameOnly ? option : option.name}
                {option?.index == 6 && (
                  <IconButton
                    size='small'
                    edge='end'
                    aria-label='delete'
                    onClick={(e) => {
                      handleDeleteOption(setSelectOptions);
                      e.stopPropagation();
                    }}>
                    <Icon icon='mdi:close-circle' width={18} color={palette.codGray} />
                  </IconButton>
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                placeholder={options.length == 0 ? 'Escriba el cargo a agregar' : 'Seleccionar'}
                label={label}
                {...params}
                sx={{
                  '& fieldset': error && {
                    border: `2px solid ${palette.error} !important`,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '15px',
                      border: `1px solid ${disabled ? palette.dark : palette.gray} !important`,
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={baseChips}
            popupIcon={<Icon icon={'heroicons-solid:chevron-down'} height={20} width={20} color={palette?.black} />}
            {...props}
            {...field}
            limitTags={limitTags}
            onChange={(_e, value) => {
              field.onChange(value?.inputValue ? value?.inputValue : value);
              if (value.inputValue) return addInputToOptions(value.inputValue, setSelectOptions);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = options.some((option) => inputValue === option.title);
              if (inputValue !== '' && !isExisting && showAddOption) {
                filtered.push({
                  inputValue,
                  name: `Agregar "${inputValue}"`,
                });
              }

              return filtered;
            }}
          />
        );
      }}
    />
  );
};
