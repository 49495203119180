import { Box, Typography, TableRow, TableCell } from '@mui/material';
import { styles } from '../../styles';
import { ClientCard } from '../Card';
import { useSelector } from 'react-redux';
import { clientsSelector } from 'store/selectors';
import { DownloadButton } from 'components/DownloadButton';

export const ProtocolSection = () => {
  const clientData = useSelector(clientsSelector);

  const protocolFirstDataHeaders = [
    'Fecha de protocolización del Acta de Juramentación',
    'Periodo para el cual resultaron electos',
    'Acta adjuntada',
  ];

  const protocolSecondDataHeaders = [
    'Fecha del Acta Constitutiva',
    'Fecha de protocolización del Acta Constitutiva',
    'Acta adjuntada',
  ];

  const protocolThirdDataHeaders = ['Fecha de la ultima reforma estatuaria', 'Acta adjuntada', ''];
  return (
    <Box marginBottom={'2rem'} width={'100%'}>
      <Typography marginLeft={'0.5rem'} marginBottom={'1rem'} textAlign={'left'} fontSize={'1rem'} fontWeight={'600'}>
        Protocolización
      </Typography>
      <Box marginBottom={'1rem'}>
        <ClientCard headers={protocolFirstDataHeaders}>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.protocolData?.date_swearing_certificate}
              </Typography>
            </TableCell>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.protocolData?.elected_start_date} -{' '}
                {clientData?.legalDetail?.protocolData?.elected_end_date}{' '}
              </Typography>
            </TableCell>
            <TableCell component='th' scope='row' sx={{ display: 'flex' }}>
              <Typography variant='button' sx={styles.fileText}>
                {clientData?.legalDetail?.protocolData?.path_swearing_certificate}
              </Typography>
              <DownloadButton fileName={clientData?.legalDetail?.protocolData?.path_swearing_certificate} />
            </TableCell>
          </TableRow>
        </ClientCard>
      </Box>
      <Box marginBottom={'1rem'}>
        <ClientCard headers={protocolSecondDataHeaders}>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.protocolData?.date_constitutive_act}{' '}
              </Typography>
            </TableCell>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.protocolData?.date_proto_constitutive_act}
              </Typography>
            </TableCell>
            <TableCell component='th' scope='row' sx={{ display: 'flex' }}>
              <Typography variant='button' sx={styles.fileText}>
                {clientData?.legalDetail?.protocolData?.path_constitutive_act}{' '}
              </Typography>
              <DownloadButton fileName={clientData?.legalDetail?.protocolData?.path_constitutive_act} width={18} />
            </TableCell>
          </TableRow>
        </ClientCard>
      </Box>
      <Box marginBottom={'1rem'}>
        <ClientCard headers={protocolThirdDataHeaders}>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.protocolData?.date_statute}{' '}
              </Typography>
            </TableCell>

            <TableCell component='th' scope='row' sx={{ display: 'flex' }}>
              <Typography variant='button' sx={styles.fileText}>
                {clientData?.legalDetail?.protocolData?.statute}
              </Typography>
              <DownloadButton fileName={clientData?.legalDetail?.protocolData?.statute} />
            </TableCell>
            <TableCell component='th' scope='row'></TableCell>
          </TableRow>
        </ClientCard>
      </Box>
    </Box>
  );
};
