export const GET_STATES = 'GET_STATES';
export const GET_STATES_ASYNC = 'GET_STATES_ASYNC';
export const GET_MUNICIPALITY = 'GET_MUNICIPALITY';
export const GET_MUNICIPALITY_ASYNC = 'GET_MUNICIPALITY_ASYNC';
export const SET_LOADING = 'SET_LOADING';
export const GET_PARISHES = 'GET_PARISHES';
export const GET_PARISHES_ASYNC = 'GET_PARISHES_ASYNC';
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const GET_REPORT_DATA_ASYNC = 'GET_REPORT_DATA_ASYNC';
export const GET_EMPLOYERS = 'GET_EMPLOYERS';
export const GET_EMPLOYERS_ASYNC = 'GET_EMPLOYERS_ASYNC';
export const GET_FINANCIAL_HISTORICAL = 'GET_FINANCIAL_HISTORICAL';
export const GET_FINANCIAL_HISTORICAL_ASYNC = 'GET_FINANCIAL_HISTORICAL_ASYNC';
export const EXPORT_REPORT_DATA = 'EXPORT_REPORT_DATA';
export const EXPORT_REPORT_DATA_ASYNC = 'EXPORT_REPORT_DATA_ASYNC';
