import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  buttonContainer: (theme: Theme) => ({
    width: '70%',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      alignSelf: 'center',
    },
  }),
  button: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),

  input: (theme: Theme) => ({
    margin: '2rem 0',
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0',
    },
  }),
  text: {
    color: palette.black,
    margin: '0.5rem 0.8rem 0 0',
    '&:hover': {
      color: palette.primary,
      cursor: 'pointer',
    },
  },
  link: {
    fontSize: '0.75rem',
    color: palette.black,
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0.5rem 0 0 0',
  },
};
