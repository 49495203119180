import { useRef, useState, useEffect } from 'react';
import { InputAmount } from 'components';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { parseAmount, parseTotalAmount, plainNumberString, handleAmountFormat } from 'utils';
import { useMediaQuery, Theme } from '@mui/material';

export const Expenses = () => {
  const inputRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const { expenses, totalExpenses } = registerData;
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      personal: expenses?.personal ? handleAmountFormat(expenses?.personal) : '',
      administrative: expenses?.administrative ? handleAmountFormat(expenses?.administrative) : '',
      services_received: expenses?.services_received ? handleAmountFormat(expenses?.services_received) : '',
      depreciation: expenses?.depreciation ? handleAmountFormat(expenses?.depreciation) : '',
      operating_expense: expenses?.operating_expense ? handleAmountFormat(expenses?.operating_expense) : '',
      estimates: expenses?.estimates ? handleAmountFormat(expenses?.estimates) : '',
      financial_expense: expenses?.financial_expense ? handleAmountFormat(expenses?.financial_expense) : '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    validateZero();
    const newData = plainNumberString(data);
    dispatch(setEntityData({ ...registerData, expenses: newData, totalExpenses: totalAmount, financialStep: 3 }));
  };
  const returnPreviousStep = () => {
    dispatch(setEntityData({ ...registerData, financialStep: 1 }));
  };

  const onHandler = () => {
    const formValues = getValues();
    parseTotalAmount(formValues, setTotalAmount);
  };

  useEffect(() => {
    const formValues = getValues();
    setTotalAmount(totalExpenses);
    parseTotalAmount(formValues, setTotalAmount);
  }, []);

  const validateZero = () => {
    const formValues = getValues();
    const newvalue = Object.entries(formValues).map((item: any) => {
      if (Number(item[1]) <= 0 || item[1] == '0,00') return setValue(item[0], String(0));
    });
  };

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            fontWeight={'600'}
            marginTop={'1rem'}
            color={palette.black}>
            Gastos
          </Typography>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              {' '}
              <InputAmount
                fullWidth
                label='Gastos de Personal'
                {...register('personal')}
                control={control}
                handlerAmount={() => {
                  onHandler();
                }}
                placeholder={'0.00'}
                helperText={
                  errors?.personal?.message
                    ? errors?.personal?.message
                    : 'Registre los gastos causados en el ejercicio por concepto de remuneraciones al personal de la asociación y otros derivados a la relación contractual.'
                }
                error={!!errors?.personal}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              {' '}
              <InputAmount
                fullWidth
                label='Gastos Administrativos'
                {...register('administrative')}
                handlerAmount={() => onHandler()}
                placeholder={'0.00'}
                helperText={
                  errors?.administrative?.message
                    ? errors?.administrative.message
                    : 'Registre los saldos de gastos generales y de administraciones causadas en el ejercicio.'
                }
                control={control}
                error={!!errors?.administrative}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Pago por Servicios Recibidos'
                {...register('services_received')}
                handlerAmount={() => onHandler()}
                placeholder={'0.00'}
                helperText={
                  errors?.services_received?.message
                    ? errors?.services_received.message
                    : 'Registre los gastos del ejercicio incurridos por concepto de Honorarios Profesionales, servicios básicos entre otros.'
                }
                control={control}
                error={!!errors?.services_received}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} margin={!isMobile ? '2rem 0' : '0'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Gastos de Depreciación de Propiedad, Planta y Equipo'
                {...register('depreciation')}
                helperText={
                  errors?.depreciation?.message
                    ? errors?.depreciation.message
                    : 'Registre los gastos incurridos en el ejercicio originados por la depreciación, amortización y desvalorización de las inversiones y bienes diversos.'
                }
                handlerAmount={() => onHandler()}
                placeholder={'0.00'}
                control={control}
                error={!!errors?.depreciation}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Gastos por Constitución de la Estimación'
                {...register('estimates')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.estimates?.message
                    ? errors?.estimates.message
                    : 'Registre el efecto en los gastos de contabilización de estimación de la cartera de préstamos, estimación para cartera de inversiones.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.estimates}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Gastos Financieros'
                {...register('financial_expense')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.financial_expense?.message
                    ? errors?.financial_expense.message
                    : 'Registre los gastos financieros causados en el ejericicio por concepto de intereses, comisiones entre otros.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.financial_expense}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} margin={!isMobile ? '0' : '0'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Gastos Operativos'
                {...register('operating_expense')}
                handlerAmount={() => onHandler()}
                placeholder={'0.00'}
                helperText={
                  errors?.operating_expense?.message
                    ? errors?.operating_expense.message
                    : 'Registre los gastos financieros causados en el ejericicio por concepto de intereses, comisiones entre otros.'
                }
                control={control}
                error={!!errors?.operating_expense}
                ref={inputRef.current}
              />
            </Grid>
            {!isMobile && <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>
      </Grid>
      <Box sx={{ wdith: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography sx={styles.amount} marginBottom={'1.5rem'} fontWeight='600' fontSize={'1.25rem'}>
          Total: <span style={{ fontWeight: '400' }}>{parseAmount(totalAmount)}</span>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        sx={styles.buttonContainer}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Box sx={styles.amounContainer}>
            {' '}
            <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
              Continuar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
