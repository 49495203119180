import { useRef, useEffect, useState } from 'react';
import { InputAmount, MuiModal } from 'components';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import { parseAmount, parseTotalAmount, handleAmountFormat, plainNumberString } from 'utils';
import { UseIntermitence } from 'hooks';
import { sendFinancialData } from 'store/actions';
import { useMediaQuery, Theme } from '@mui/material';

export const Capital = () => {
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    capital,
    totalCapital,
    totalIncomes,
    totalExpenses,
    totalCurrentAssets,
    totalNotCurrentAssets,
    totalLiability,
    financialLoading,
  } = registerData;

  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState('');
  const { status, switchStatus } = UseIntermitence();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      contribution_associate_received: capital?.contribution_associate_received
        ? handleAmountFormat(capital?.contribution_associate_received)
        : '',
      contribution_employer_received: capital?.contribution_employer_received
        ? handleAmountFormat(capital?.contribution_employer_received)
        : '',
      contribution_associate: capital?.contribution_associate
        ? handleAmountFormat(capital?.contribution_associate)
        : '',
      contribution_employer: capital?.contribution_employer ? handleAmountFormat(capital?.contribution_employer) : '',
      legal_reserves: capital?.legal_reserves ? handleAmountFormat(capital?.legal_reserves) : '',
      deficit: capital?.deficit ? handleAmountFormat(capital?.deficit) : '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: any) => {
    validateZero();
    const assets = totalCurrentAssets + totalNotCurrentAssets;
    const pasiveTotal = Number(totalLiability) + Number(totalAmount);

    if (pasiveTotal.toFixed(2) == assets.toFixed(2)) {
      const newData = plainNumberString(data);
      await dispatch(
        setEntityData({
          ...registerData,
          capital: data,
          totalCapital: totalAmount,
        }),
      );
      await dispatch(sendFinancialData(navigation, newData));
      return;
    }
    return switchStatus();
  };

  const returnPreviousStep = () => {
    dispatch(setEntityData({ ...registerData, financialStep: 5 }));
  };

  const onHandler = () => {
    const formValues = getValues();
    parseTotalAmount(formValues, setTotalAmount);
  };

  useEffect(() => {
    setTotalAmount(totalCapital);
    const result = totalIncomes - totalExpenses;
    const total = parseAmount(result);
    setValue('deficit', total);
    onHandler();
  }, []);

  const validateZero = () => {
    const formValues = getValues();
    const newvalue = Object.entries(formValues).map((item: any) => {
      if (Number(item[1]) <= 0 || item[1] == '0,00') return setValue(item[0], String(0));
    });
  };

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            fontWeight={'600'}
            marginBottom={'1rem'}
            marginTop={'1rem'}
            color={palette.black}>
            Patrimonio Constituido y Reservas Atribuibles
          </Typography>
          <Typography textAlign={'left'} sx={styles.formText} marginBottom={'1rem'} color={palette.black}>
            Aportes recibidos
          </Typography>

          <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Aporte del Asociado'
                {...register('contribution_associate_received')}
                control={control}
                placeholder={'0.00'}
                handlerAmount={() => {
                  onHandler();
                }}
                helperText={
                  errors?.contribution_associate_received?.message
                    ? errors?.contribution_associate_received?.message
                    : 'Registre los Aporte de los asociados que consiste en un porcentaje de su salario.'
                }
                error={!!errors?.contribution_associate_received}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Aporte del Empleador'
                {...register('contribution_employer_received')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.contribution_employer_received?.message
                    ? errors?.contribution_employer_received.message
                    : 'Registre el Aporte del empleador acordado por convenio celebrado entre las partes.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.contribution_employer_received}
                ref={inputRef.current}
              />
            </Grid>

            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>

        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            marginTop={'1rem'}
            marginBottom={'1rem'}
            color={palette.black}>
            Aportes no recibidos
          </Typography>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Aporte del Asociado'
                {...register('contribution_associate')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.contribution_associate?.message
                    ? errors?.contribution_associate.message
                    : 'Registre los Aportes no recibidos de los asociados que consiste en un porcentaje de su salario.'
                }
                control={control}
                error={!!errors?.contribution_associate}
                ref={inputRef.current}
                placeholder={'0.00'}
              />
            </Grid>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Aporte del Empleador'
                {...register('contribution_employer')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.contribution_employer?.message
                    ? errors?.contribution_employer.message
                    : 'Registre el Aporte del empleador no recibidos acordado por convenio celebrado entre las partes.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.contribution_employer}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Reservas legales'
                {...register('legal_reserves')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.legal_reserves?.message
                    ? errors?.legal_reserves.message
                    : 'Registre los importes que en las distribuciones de los excedentes de la asociación han sido destinado a reserva de ley.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.legal_reserves}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer} marginTop={'1.5rem'}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Excedente o déficit'
                {...register('deficit')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.deficit?.message
                    ? errors?.deficit.message
                    : 'Registre los beneficios obtenidos en cada ejercicio económico producto de la operatividad de la asociación.'
                }
                control={control}
                placeholder={'0.00'}
                disabled
                error={!!errors?.deficit}
                ref={inputRef.current}
              />
            </Grid>
            {!isMobile && <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>
      </Grid>
      <Box sx={{ wdith: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography sx={styles.amount} marginBottom={'0.5rem'} fontWeight='600' fontSize={'1.25rem'}>
          Total: <span style={{ fontWeight: '400' }}>{parseAmount(totalAmount)}</span>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        sx={styles.buttonContainer}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Box sx={styles.amounContainer}>
            <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
              {financialLoading ? <CircularProgress color='inherit' size={28} /> : 'Continuar'}
            </Button>
          </Box>
        </Box>
      </Box>
      {status && (
        <MuiModal
          width={'55%'}
          open={status}
          height={isMobile ? '15rem' : '20.563rem'}
          title={'Los montos registrados no coinciden'}>
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'} flexDirection={'column'}>
            <Typography paragraph textAlign={'left'} variant='body1'>
              Hubo un error al registrar los montos, ingresa nuevamente los datos solicitados para continuar con el
              registro
            </Typography>
          </Box>
          <Box
            sx={styles.modalButtonContainer}
            marginTop={'1.5rem'}
            display='flex'
            justifyContent='flex-end'
            alignContent='flex-end'>
            <Button type='submit' onClick={() => switchStatus()} sx={styles.button} variant='contained'>
              Confirmar
            </Button>
          </Box>
        </MuiModal>
      )}
    </>
  );
};
