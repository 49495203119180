import * as Yup from 'yup';

export const schema = Yup.object().shape({
  national_currency: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  foreign_currency: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  banks: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
  load_portfolio_asset: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  investment_portfolio_asset: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
});
