import { useRef } from 'react';
import { TextField } from 'components';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { schema } from '../../validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'store/actions';
import { intermitenceSelector } from 'store/selectors';
import { UseIntermitence } from 'hooks';

export const LoginResponsive = () => {
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const navigation = useNavigate();
  const { loginLoading } = useSelector(intermitenceSelector);
  const { status, switchStatus } = UseIntermitence();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      user: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => dispatch(loginUser(data, navigation));

  return (
    <Box
      display='flex'
      flexDirection='column'
      position={'relative'}
      alignItems='center'
      justifyContent={'flex-start'}
      height='81vh'
      marginTop={'18vh'}>
      <Box bgcolor={palette?.dark} width='100%' height={'30%'} display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography width={'80%'} margin={'4rem 10%'} sx={styles.title} color={palette.white} textAlign='left'>
            Ingresa en la <b>plataforma de asociados</b> y administra tus <b>Cajas de Ahorro</b>
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.image} component={'img'} src={`${process.env.PUBLIC_URL}/resources/hands.svg`} alt='hands' />
      <Box width='100%' display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}>
        <Box
          sx={styles.backgroundImage}
          component={'img'}
          src={`${process.env.PUBLIC_URL}/resources/venezuela-point.svg`}
          alt='hands-background'
        />
        <Box
          display={'flex'}
          borderRadius={'0.938rem'}
          bgcolor={palette?.white}
          padding={'0 1rem'}
          justifyContent={'center'}
          alignItems={'center'}
          width='90%'
          position={'absolute'}
          bottom={'40px'}
          zIndex={3}
          flexDirection={'column'}>
          <Box margin={'1rem 0'} width='100%' display='flex' justifyContent='space-between' flexDirection='column'>
            <TextField
              fullWidth
              label='ID'
              {...register('user')}
              placeholder='Texto'
              id='user'
              helperText={errors?.user?.message}
              name='user'
              control={control}
              error={!!errors?.user}
              ref={inputRef.current}
            />
            <Box display='flex'>
              <Typography variant='h6' onClick={() => navigation('/pre-register')} sx={styles.text}>
                ¿No estás registrado? <u>Ingresa aquí.</u>
              </Typography>
            </Box>
          </Box>
          <Box margin={'1rem 0'} width='100%' display='flex' justifyContent='space-between' flexDirection='column'>
            <TextField
              fullWidth
              label='Contraseña'
              {...register('password')}
              placeholder='Texto'
              type={!status ? 'password' : 'text'}
              icon={status ? 'mdi:eye-off-outline' : 'ic:baseline-remove-red-eye'}
              control={control}
              helperText={errors.password?.message}
              iconMethod={switchStatus}
              error={!!errors?.password}
              ref={inputRef.current}
            />
            <Box display='flex'>
              <Typography variant='h6' sx={styles.text} onClick={() => navigation('/forgot-password')}>
                ¿Has olvidado tu contraseña? <u>Recupérala.</u>
              </Typography>
            </Box>
          </Box>
          <Box width={'100%'} marginTop={'1rem'}>
            <Button type='submit' sx={{ width: '100%' }} onClick={handleSubmit(onSubmit)} variant='contained'>
              {loginLoading ? <CircularProgress color='inherit' size={28} /> : 'Iniciar sesión'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
