import { LOGIN_ASYNC, LOGOUT, USER_LOGIN_ASYNC, FORGOT_PASSWORD, RESTORE_PASSWORD } from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  user: null,
};

const register = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case LOGIN_ASYNC:
      return { ...state, ...payload };
    case USER_LOGIN_ASYNC:
      return { ...state, ...payload };
    case LOGOUT:
      return { ...state, user: null };
    case FORGOT_PASSWORD:
      return { ...state, ...payload };
    case RESTORE_PASSWORD:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default register;
