import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    marginTop: '5rem',
    // maxWidth: '1200px',
    // margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(100% - 80%);',
    },
  }),

  sideContainer: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      position: 'inherit',
    },
  }),
  leftSide: (theme: Theme) => ({
    width: '60%',
    height: '34.75rem',
    borderRadius: '0 10.063rem 8.563rem 0',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '20.75rem',
      borderRadius: '0px 0px 1.875rem 1.875rem',
    },
  }),

  rightSide: (theme: Theme) => ({
    width: '40%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),

  formContainer: (theme: Theme) => ({
    width: '85%',
    justifyContent: 'space-between',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
    },
  }),

  fieldsContainer: (theme: Theme) => ({
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
    },
  }),

  fieldContent: (theme: Theme) => ({
    margin: '0 1rem 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0 1rem 0',
    },
  }),

  buttonContainer: (theme: Theme) => ({
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),

  image: (theme: Theme) => ({
    zIndex: 1,
    position: 'absolute',
    left: 0,
    [theme.breakpoints.down('md')]: {
      top: 0,
      backgroundSize: 'contain',
      width: '100%',
      zIndex: 1,
    },
  }),
  backgroundImage: (theme: Theme) => ({
    position: 'absolute',
    zIndex: 0,
    left: -250,
    [theme.breakpoints.down('md')]: {
      right: 0,
      left: 0,
      backgroundSize: 'contain',
      width: '100%',
      zIndex: 0,
    },
  }),
  text: {
    color: palette.black,
    margin: '0.5rem 0.8rem 0 0',
    '&:hover': {
      color: palette.primary,
      cursor: 'pointer',
    },
  },
  link: {
    fontSize: '0.75rem',
    color: palette.black,
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0.5rem 0 0 0',
  },
  title: (theme: Theme) => ({
    fontSize: '3.2rem',

    lineHeight: '1.2',
    b: {
      fontWeight: '700',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  }),
};
