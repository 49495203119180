import * as Yup from 'yup';

export const schema = Yup.object().shape({
  load_portfolio: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  financial_income: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  investment_portfolio_income: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),
  operating_income: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  extraordinary: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),
});
