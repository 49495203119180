import { useRef } from 'react';
import { MuiModal, TextField } from 'components';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styles } from './styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData, sendLegalData, savePositionAdm } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Theme } from '@mui/material';

export const ExplainModal = ({ open, onClose, type }: any) => {
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const registerData = useSelector(registerSelector);
  const { explainAdm, explainVig, administrativeMembers, positionsAdm, vigilanceMembers } = registerData;
  const { admMembers } = administrativeMembers;
  const { vigMembers } = vigilanceMembers;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      explain: type == 'adm' ? explainAdm : explainVig,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    const newAdmMembers = replacePositionsWithNames();

    if (type == 'adm') {
      dispatch(savePositionAdm({ adminCouncil: newAdmMembers, watchCouncil: vigMembers }));

      dispatch(setEntityData({ ...registerData, explainAdm: data?.explain, legalStep: 4 }));
      return onClose();
    }

    dispatch(
      setEntityData({
        ...registerData,
        administrativeMembers: { admMembers: newAdmMembers },
        explainVig: data?.explain,
        legalStatus: 'Waiting',
        legalStep: 1,
      }),
    );
    dispatch(sendLegalData(navigation));
    onClose();
  };

  const replacePositionsWithNames = () =>
    admMembers.map(({ name, position, ...item }: any) => {
      const positionFound = positionsAdm.find((pos: any) => pos.id === position);

      let val = position;

      if (positionFound) {
        if (positionFound.index != '6' && positionFound.index != '7') {
          val = position || positionFound.name;
        } else if (positionFound.index == '6' || positionFound.index == '7') {
          val = positionFound.name || position;
        }
      }

      return {
        ...item,
        position: val,
      };
    });

  return (
    <MuiModal
      width={'60%'}
      height={!isMobile ? '24.25rem' : '23rem'}
      marginTop={!isMobile ? '4rem' : '0'}
      titleAlign={isMobile ? 'center' : 'left'}
      open={open}
      onCancel={onClose}
      title={' ¿No están activos todos los miembros del Consejo?'}>
      <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography textAlign={'left'} sx={styles.modalsubtitle}>
            Explica la razón
          </Typography>

          <Grid item display={'flex'} margin={'0 1rem 0 0'} xs={12}>
            <TextField
              fullWidth
              label='Dejar comentario'
              {...register('explain')}
              placeholder='Texto'
              multiline
              rows={4}
              control={control}
              helperText={errors.explain?.message ? errors.explain?.message : ''}
              error={!!errors?.explain}
              ref={inputRef.current}
            />
          </Grid>
        </Box>

        <Box sx={styles.buttonContainer} display={'flex'} marginTop={'3rem'}>
          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            Confirmar
          </Button>
        </Box>
      </Grid>
    </MuiModal>
  );
};
