import * as Yup from 'yup';
import { IdRegex, onlyLettersAndNumbers } from 'utils';

export const schema = Yup.object().shape({
  user: Yup.string()
    .required('Campo requerido')
    .min(7, `Este campo debe poseer un minimo de ${7} caracteres `)
    .max(7, `Este campo no debe tener más de ${7} caracteres`)
    .matches(IdRegex, 'el ID debe poseer el siguiente formato PM-0000'),

  password: Yup.string()
    .required('Campo requerido')
    .matches(onlyLettersAndNumbers, 'El campo debe contener solo letras y números')
    .min(8, `Este campo debe poseer un minimo de ${8} caracteres `)
    .max(255, `Este campo no debe tener más de ${255} caracteres`),
});
