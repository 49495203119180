import { SET_USERS_DATA, GET_USERS_ASYNC, LOADING_USERS_GET, SEARCH_USER_ASYNC } from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  usersData: [],
  usersLoading: false,
};

const users = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case SET_USERS_DATA:
      return { ...state, ...payload };
    case SEARCH_USER_ASYNC:
      return { ...state, usersData: payload };
    case GET_USERS_ASYNC:
      return { ...state, usersData: payload };
    case LOADING_USERS_GET:
      return { ...state, usersLoading: payload };
    default:
      return state;
  }
};

export default users;
