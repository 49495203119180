import { palette } from '../contants';

export const MuiSwitch = {
  styleOverrides: {
    root: {
      width: '58px',
      height: '38px',
    },
    switchBase: {
      color: palette.light,

      '&.Mui-checked': {
        color: palette.light,
      },
    },
    track: {
      opacity: 0.5,

      '.Mui-checked.Mui-checked + &': {
        opacity: 1,
        backgroundColor: palette.primary,
      },
    },
  },
};
