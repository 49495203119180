import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService } from '../../utils';
import {
  DELETE_ROL,
  GET_ROLES,
  GET_ROLES_ASYNC,
  LOADING_ROLES_GET,
  SEARCH_ROL,
  SEARCH_ROL_ASYNC,
  SEND_ROLE,
  UPDATE_ROLE,
} from './action-types';
import { showDialog } from '../../utils';
import { authSelector } from 'store/selectors';

function* getRolesAsync({ payload }: any) {
  try {
    const { page, take } = payload;
    yield put(actionObject(LOADING_ROLES_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(
      FetchService,
      `/role?page=${page}&take=${take}`,
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(LOADING_ROLES_GET, false));
      yield put(actionObject(GET_ROLES_ASYNC, data));
    }

    if (!data) {
      yield put(actionObject(LOADING_ROLES_GET, false));

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(LOADING_ROLES_GET, false));
    throw new Error('Ha ocurrido un error');
  }
}

function* deleteRoleAsync({ payload }: any) {
  const { id, onClose, page, take } = payload;

  try {
    yield put(actionObject(LOADING_ROLES_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, code } = yield call(FetchService, `/role/${id}`, 'DELETE', undefined, accessToken);

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_ROLES_GET, false));
      onClose();
      yield call(getRolesAsync, { payload: { page, take } });
      yield call(showDialog, 'Eliminado exitosamente', 'success');
    }

    if (!data) {
      if (code && code == '23503') yield call(showDialog, 'El rol se encuentra asignado', 'error');
      yield put(actionObject(LOADING_ROLES_GET, false));
    }
  } catch (error) {
    yield put(actionObject(LOADING_ROLES_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* searchRoleAsync({ payload }: any) {
  const { name, customPage } = payload;
  try {

    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/role/search?page=1&name=${name}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      customPage(1);
      yield put(actionObject(SEARCH_ROL_ASYNC, data));
    }

    if (!data) throw new Error(response?.message);
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* sendRoleAsync({ payload }: any) {
  const { body, onClose, page, take } = payload;

  try {
    yield put(actionObject(LOADING_ROLES_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(FetchService, '/role', 'POST', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(LOADING_ROLES_GET, false));
      onClose();
      yield call(getRolesAsync, { payload: { page, take } });
      yield call(showDialog, 'Creado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_ROLES_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOADING_ROLES_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* updateRoleAsync({ payload }: any) {
  const { id, body, onClose, page, take } = payload;

  try {
    yield put(actionObject(LOADING_ROLES_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(FetchService, `/role/${id}`, 'PUT', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(LOADING_ROLES_GET, false));
      onClose();
      yield call(getRolesAsync, { payload: { page, take } });
      yield call(showDialog, 'Editado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_ROLES_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOADING_ROLES_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

export function* watchGetRoles() {
  yield takeLatest(GET_ROLES, getRolesAsync);
}

export function* watchDeleteRoles() {
  yield takeLatest(DELETE_ROL, deleteRoleAsync);
}

export function* watchSearchRole() {
  yield takeLatest(SEARCH_ROL, searchRoleAsync);
}

export function* watchSendRole() {
  yield takeLatest(SEND_ROLE, sendRoleAsync);
}

export function* watchUpdateRole() {
  yield takeLatest(UPDATE_ROLE, updateRoleAsync);
}
