import * as Yup from 'yup';
import {
  emailRegex,
  fullFormatPhoneRegex,
  onlyLettersNumbersAndSpace,
  onlyLettersRegex,
  onlyNumbersRegex,
} from 'utils';

export const schema = Yup.object().shape({
  classification: Yup.string().required(),

  name: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`)
    .matches(onlyLettersNumbersAndSpace, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  availability: Yup.string().when('classification', {
    is: (value: string) => value == 'Producto',
    then: Yup.string()
      .required('Campo requerido')
      .min(1, `Este campo debe tener al menos ${1} caracteres`)
      .max(255, `Este campo no debe tener mas de ${255} caracteres`)
      .matches(onlyNumbersRegex, 'Este campo solo debe tener caracteres numéricos'),
    otherwise: Yup.string().notRequired(),
  }),

  location: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),

  phone: Yup.string()
    .required('Campo requerido')
    .matches(fullFormatPhoneRegex, 'El número de teléfono no es válido el formato debe ser el siguiente 04143456789'),

  association: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`)
    .matches(onlyLettersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  price: Yup.string().required(),

  first_product_image: Yup.string().required(),
  second_product_image: Yup.string().notRequired(),
  third_product_image: Yup.string().notRequired(),

  email: Yup.string()
    .required('Campo requerido')
    .matches(emailRegex, 'Ingrese un formato válido, por ejemplo email@gmail.com'),

  description: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),
});
