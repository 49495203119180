import { TextField as MUITextField, TextFieldProps, InputAdornment, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import React, { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { palette } from 'theme/contants';

interface IAditionalProps {
  name: string;
  control: any;
  error: boolean;
  ref: any;
  helperText: any;
  handlerAmount: () => void;
  showPlaceholder?: boolean;
  leftPlaceHolder?: string;
}
type Props = TextFieldProps | IAditionalProps;

export const InputAmount: FC<Props> = ({
  name,
  control,
  error = false,
  helperText,
  handlerAmount,
  ref,
  showPlaceholder = true,
  leftPlaceHolder = 'Monto',
  ...props
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MUITextField
          variant='outlined'
          inputRef={ref}
          error={error}
          helperText={helperText}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            startAdornment: (
              <InputAdornment position='start'>
                <Typography color={palette.gray} fontSize={'0.75rem'}>
                  {showPlaceholder && leftPlaceHolder}
                </Typography>
              </InputAdornment>
            ),
          }}
          inputProps={{ style: { textAlign: 'right' } }}
          InputLabelProps={{
            shrink: true,
          }}
          {...props}
          {...field}
          onChange={(e: any) => {
            {
              const {
                target: { value },
              } = e;
              field.onChange(value);
              handlerAmount(value);
            }
          }}
        />
      )}
    />
  );
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { ...other } = props;
  return <NumericFormat {...other} type='text' decimalSeparator=',' thousandSeparator='.' decimalScale={2} />;
});
