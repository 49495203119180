import {
  GET_STATES_ASYNC,
  GET_MUNICIPALITY_ASYNC,
  SET_LOADING,
  GET_PARISHES_ASYNC,
  GET_REPORT_DATA_ASYNC,
  GET_EMPLOYERS_ASYNC,
  EXPORT_REPORT_DATA_ASYNC,
} from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  states: [],
  municipalities: [],
  parishes: [],
  filterParams: [],
  reportResult: [],
  employers: [],
};

const reports = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_STATES_ASYNC:
      return { ...state, states: payload?.states };
    case GET_MUNICIPALITY_ASYNC:
      return { ...state, municipalities: payload?.municipalities };
    case GET_PARISHES_ASYNC:
      return { ...state, parishes: payload?.parishes };
    case GET_REPORT_DATA_ASYNC:
      return { ...state, reportResult: payload?.reportResult, filterParams: payload?.filterParams };
    case GET_EMPLOYERS_ASYNC:
      return { ...state, employers: payload?.employers };
    case SET_LOADING:
      return { ...state, payload };
    case EXPORT_REPORT_DATA_ASYNC:
      return { ...state, filterParams: payload?.filterParams };

    default:
      return state;
  }
};

export default reports;
