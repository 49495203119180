import { palette } from '../contants';

export const MuiButton = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState }: any) => ({
      fontSize: '0.875rem',
      borderRadius: '15px',
      width: '213px',
      height: '45px',
      boxShadow: 'none',

      ...(ownerState.variant === 'contained' && {
        color: palette.light,
      }),

      '&:hover': {
        boxShadow: 'none',
        ...(ownerState.variant === 'contained' && {
          backgroundColor: palette.dark,
        }),
        ...(ownerState.variant === 'outlined' && {
          borderColor: palette.dark,
          color: palette.dark,
          backgroundColor: 'transparent',
        }),
        ...(ownerState.variant === 'text' && {
          color: palette.dark,
          backgroundColor: 'transparent',
        }),
      },

      '&:disabled': {
        ...(ownerState.variant === 'contained' && {
          backgroundColor: palette.disabled,
          color: palette.light,
        }),
        ...(ownerState.variant === 'outlined' && {
          borderColor: palette.disabled,
          color: palette.disabled,
        }),
        ...(ownerState.variant === 'text' && {
          backgroundColor: 'transparent',
          color: palette.disabled,
        }),
      },
    }),
  },
};
