import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { palette } from 'theme/contants';
import { AutocompleteSelect, InputDate } from 'components';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sectors, protocolStatus, savingStatus } from 'utils';

import { reportsSelector } from 'store/selectors';
import { exportReportData, getMunicipalities, getParishes, getReportData } from 'store/actions';
import { getExportableUrl } from 'store/intermitence/action';

export const Filters = () => {
  const dispatch = useDispatch();
  const reports = useSelector(reportsSelector);
  const { states, municipalities, parishes, employers, reportResult } = reports;
  const [currentParams, setCurrentParams] = useState<any>([]);
  const inputRef = useRef(null);

  const {
    register,
    control,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      states: [],
      municipalities: [],
      parishes: [],
      sector: [],
      employer: [],
      status: [],
      protocol: [],
      startDate: new Date(),
      endDate: new Date(),
    },
  });
  const formValues = getValues();
  const handleDate = (key: any, date: any) => {
    const filters: any = {
      ...currentParams,
      [key]: date,
    };
    setCurrentParams(filters);
    setValue(key, date);
  };

  const handleChange = (field: any, value: any, keyToPush?: any) => {
    const currentValues = value?.map((item: string) => item[keyToPush]) || [];

    const filters: any = {
      ...currentParams,
      [field]: keyToPush && currentValues.length > 0 ? currentValues : value.length > 0 ? value : undefined,
    };

    setCurrentParams(filters);
    setValue(field, value);
  };

  useEffect(() => {
    dispatch(getReportData(buildParams(currentParams)));
  }, [currentParams]);

  const buildParams = (params: any) => {
    return {
      states: params?.states ? encodeURIComponent(params?.states) : undefined,
      municipality: params?.municipalities ? encodeURIComponent(params?.municipalities) : undefined,
      parish: params?.parishes ? encodeURIComponent(params?.parishes) : undefined,
      sector: params?.sector ? encodeURIComponent(params?.sector) : undefined,
      employer: params?.employer ? encodeURIComponent(params?.employer) : undefined,
      status: params?.status ? encodeURIComponent(params?.status) : undefined,
      protocol: params?.protocol ? encodeURIComponent(params?.protocol) : undefined,
      startDate: params?.startDate ? formattedDate(params?.startDate) : undefined,
      endDate: params?.endDate ? formattedDate(params?.endDate) : undefined,
    };
  };

  const formattedDate = (dateString: Date) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero for months
    const day = date.getDate().toString().padStart(2, '0'); // Add leading zero for days
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const { states: selectedStates, municipalities: selectedMunicipalities } = currentParams;
  const payload = {
    states: currentParams?.states ? encodeURIComponent(currentParams?.states) : [],
    municipalities: currentParams?.municipalities ? encodeURIComponent(currentParams?.municipalities) : [],
  };

  useEffect(() => {
    if (!selectedStates) {
      setValue('municipalities', []);
      setValue('parishes', []);
      const filters = {
        ...currentParams,
        municipalities: undefined,
        parishes: undefined,
      };
      return setCurrentParams(filters);
    }

    dispatch(getMunicipalities(currentParams?.states ? encodeURIComponent(currentParams?.states) : []));
  }, [selectedStates]);

  useEffect(() => {
    if (!selectedMunicipalities) {
      setValue('parishes', []);
      const filters = {
        ...currentParams,
        municipalities: undefined,
        parishes: undefined,
      };
      return setCurrentParams(filters);
    }
    dispatch(getParishes(payload));
  }, [selectedMunicipalities]);

  useEffect(() => {
    filterAutocompleteOptions(formValues?.municipalities, municipalities);
  }, [municipalities]);

  const filterAutocompleteOptions = (selectValues: string[], array2: any) => {
    if (selectValues && selectValues.length > 0) {
      const result: any = selectValues.filter((obj1: any) => array2.some((obj2: any) => obj1.id === obj2.id));

      const resultNames = result.map((matchingObj: any) => matchingObj.name);
      const filters: any = {
        ...currentParams,
        municipalities: 'name' && resultNames.length > 0 ? resultNames : undefined,
      };

      setCurrentParams(filters);

      setValue('municipalities', result);
    }

    return [];
  };

  const downloadReport = () => {
    dispatch(
      exportReportData(buildParams(currentParams), ({ ok, data }) => {
        if (ok) {
          dispatch(
            getExportableUrl(data, ({ ok, values }) => {
              if (ok) {
                window.open(values?.url);
              }
            }),
          );
        }
      }),
    );
  };

  return (
    <Box width={'100%'} margin={'0rem 1% 1rem 1%'}>
      <Grid margin={'1rem 0'} marginLeft={'-8px !important'} container spacing={3} marginBottom={2}>
        <Grid item xs={3}>
          <AutocompleteSelect
            {...register('states')}
            control={control}
            label='Estado'
            options={states}
            value={formValues.states}
            handleSelection={(value: any) => {
              handleChange('states', value, 'name');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteSelect
            {...register('municipalities')}
            options={municipalities ?? []}
            label='Municipio'
            value={formValues.municipalities}
            disabled={municipalities.length > 0 ? false : true}
            control={control}
            handleSelection={(value: any) => {
              handleChange('municipalities', value, 'name');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteSelect
            {...register('parishes')}
            options={parishes ?? []}
            label='Parroquia'
            control={control}
            disabled={parishes.length > 0 ? false : true}
            handleSelection={(value: any) => {
              handleChange('parishes', value, 'name');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>
        <Grid item xs={2.5}>
          <AutocompleteSelect
            {...register('sector')}
            options={sectors}
            label='Sector'
            control={control}
            handleSelection={(value: any) => {
              handleChange('sector', value, 'id');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>
      </Grid>

      <Grid margin={'1rem 0'} marginLeft={'-8px !important'} container spacing={3} marginBottom={2}>
        <Grid item xs={3}>
          <AutocompleteSelect
            {...register('employer')}
            options={employers}
            label='Patrono'
            control={control}
            handleSelection={(value: any) => {
              handleChange('employer', value, 'name');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>

        <Grid item xs={3}>
          <AutocompleteSelect
            {...register('status')}
            options={savingStatus}
            label='Estado de vigencia'
            control={control}
            handleSelection={(value: any) => {
              handleChange('status', value, 'id');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>
        <Grid item xs={3}>
          <AutocompleteSelect
            {...register('protocol')}
            options={protocolStatus}
            label='Estado de protocolización'
            control={control}
            handleSelection={(value: any) => {
              handleChange('protocol', value, 'id');
            }}
            ref={inputRef.current}
            limitTags={1}
          />
        </Grid>
        <Grid item xs={2.5}>
          <Button
            onClick={() => {
              reset();
              setCurrentParams([]);
            }}
            variant='outlined'
            type='submit'
            sx={{
              color: palette?.dark,
              borderColor: palette?.dark,

              borderRadius: '0.438rem',
              width: '100%',
              fontWeight: '600',
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: palette.blueHaze,
              },
            }}>
            {'Limpiar filtros'}
          </Button>
        </Grid>
      </Grid>

      <Box margin={'2rem 1% 0 1%'} display={'flex'} width={'98%'} justifyContent={'flex-start'}>
        <Typography variant='subtitle1' textAlign={'left'} color={palette?.tundora}>
          Ingresa rango de fecha
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-start'} width={'100%'}>
        <Grid container spacing={3} marginTop={'0.2rem'} marginLeft={'-8px !important'} marginBottom={2}>
          <Grid item xs={2}>
            <InputDate
              {...register('startDate')}
              label='Desde'
              control={control}
              value={formValues?.startDate}
              handleDatePicker={(value: any) => handleDate('startDate', value)}
              error={!!errors?.startDate}
              ref={inputRef.current}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.dark} !important`,
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <InputDate
              {...register('endDate')}
              label='Hasta'
              placeholder='00/00/00'
              disablePast={false}
              disableFuture={false}
              control={control}
              value={formValues?.endDate}
              handleDatePicker={(value: any) => handleDate('endDate', value)}
              error={!!errors?.endDate}
              ref={inputRef.current}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.dark} !important`,
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => downloadReport()}
              variant='outlined'
              type='submit'
              disabled={reportResult?.total == '0' ? true : false}
              sx={{
                color: palette?.dark,
                borderColor: palette?.dark,
                borderRadius: '0.938rem',
                width: '100%',
                fontWeight: '600',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: palette.blueHaze,
                },
              }}>
              {'Descargar data'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
