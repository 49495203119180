import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, convertStringToNumber, FetchService } from '../../utils';
import {
  SEND_FINANCIAL_DATA,
  LOADING_FINANCIAL_REGISTER,
  GET_FINANCIAL_DATA,
  GET_FINANCIAL_DATA_ASYNC,
  LOADING_FINANCIAL_GET,
  UPDATE_FINANCIAL_DATA,
  UPDATE_FINANCIAL_DATA_ASYNC,
  SEND_LEGAL_DATA,
  GET_LEGAL_DATA,
  LOADING_LEGAL_GET,
  LOADING_LEGAL_REGISTER,
  GET_LEGAL_DATA_ASYNC,
  GET_POSITION_ADM_DATA_ASYNC,
  GET_POSITION_ADM_DATA,
  GET_POSITION_WATCH_DATA,
  GET_POSITION_WATCH_DATA_ASYNC,
  SET_ENTITY_DATA,
  DELETE_FILE_ASYNC,
  DELETE_FILE,
  SAVE_POSITION_ADM_DATA,
  GET_MEMBERS_ADM_DATA,
  GET_MEMBERS_ADM_DATA_ASYNC,
} from './action-types';
import { authSelector, registerSelector } from 'store/selectors';
import { showDialog } from 'utils';

function* sendFinancialAsync({ payload }: any): any {
  try {
    const { navigation, capitalData } = payload;
    yield put(actionObject(LOADING_FINANCIAL_REGISTER, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data: getData, statusCode: getStatus } = yield call(
      FetchService,
      '/financial/detail',
      'GET',
      null,
      accessToken,
    );
    if (getData && getStatus == 200) return yield call(updateFinancialAsync, navigation);

    const { assets, expenses, capital, incomes, liability } = yield select(registerSelector);

    const incomeNumber = convertStringToNumber(incomes);
    const expensesNumber = convertStringToNumber(expenses);
    const capitalNumber = convertStringToNumber(capitalData);
    const lliabiltyNumber = convertStringToNumber(liability);
    const assetsNumber = convertStringToNumber(assets);

    const body = {
      asset: assetsNumber,
      expense: expensesNumber,
      capital: capitalNumber,
      income: incomeNumber,
      liability: lliabiltyNumber,
    };
    const { data, statusCode, response } = yield call(FetchService, '/financial', 'POST', body, accessToken);

    if (data && statusCode == 200) {
      const registerData = { assets, expenses, capital, incomes, liability };
      navigation('/register');
      yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
      yield call(showDialog, 'Registro financiero exitoso', 'success');
      yield put(actionObject(SET_ENTITY_DATA, { ...registerData, statusCode, financialStep: 1 }));
    }

    if (!data) {
      yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

const transformResponse = (data: any) => ({
  incomes: data.income,
  expenses: data.expense,
  liability: data.liability,
  assets: data.asset,
  capital: data.capital,
  financialStatus: data.financialStatus,
});

// const formattedDate = (dateString: Date) => {
//   const date = new Date(dateString);
//   return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
// };

const formattedDate = (dateString: Date) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero for months
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero for days
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

function* getFinancialAsync(): any {
  try {
    yield put(actionObject(LOADING_FINANCIAL_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(FetchService, '/financial/detail', 'GET', null, accessToken);

    if (data && statusCode == 200) {
      const newData = transformResponse(data);
      yield put(actionObject(LOADING_FINANCIAL_GET, false));
      yield put(actionObject(GET_FINANCIAL_DATA_ASYNC, newData));
    }

    if (!data) {
      yield put(actionObject(LOADING_FINANCIAL_GET, false));

      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_FINANCIAL_GET, false));
    return error;
  }
}

function* updateFinancialAsync(navigation: any): any {
  try {
    yield put(actionObject(LOADING_FINANCIAL_REGISTER, true));

    const { assets, expenses, capital, incomes, liability } = yield select(registerSelector);
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const incomeNumber = convertStringToNumber(incomes);
    const expensesNumber = convertStringToNumber(expenses);
    const capitalNumber = convertStringToNumber(capital);
    const lliabiltyNumber = convertStringToNumber(liability);
    const assetsNumber = convertStringToNumber(assets);

    const body = {
      asset: assetsNumber,
      expense: expensesNumber,
      capital: capitalNumber,
      income: incomeNumber,
      liability: lliabiltyNumber,
      financialStatus: 'Waiting',
    };

    const { data, statusCode, response } = yield call(FetchService, '/financial', 'PUT', body, accessToken);

    if (data && statusCode == 200) {
      const newData = transformResponse(data);
      yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
      yield put(actionObject(UPDATE_FINANCIAL_DATA_ASYNC, newData));
      yield put(actionObject(SET_ENTITY_DATA, { financialStep: 1 }));
      navigation('/register');
      yield call(showDialog, 'Registro financiero editado de forma exitosa', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* sendLegalAsync({ payload }: any): any {
  try {
    const { navigation } = payload;
    yield put(actionObject(LOADING_FINANCIAL_REGISTER, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const {
      entityData,
      protocolData,
      administrativeMembers,
      vigilanceMembers,
      explainAdm: explainAdmin,
      explainVig: explainWatch,
    } = yield select(registerSelector);

    const {
      elected_start_date,
      elected_end_date,
      date_swearing_certificate,
      date_constitutive_act,
      date_proto_constitutive_act,
      date_statute,
      ...restProtocol
    } = protocolData;

    const body = {
      ...entityData,
      ...restProtocol,
      explainAdmin,
      explainWatch,
      elected_period: `${formattedDate(elected_start_date)}-${formattedDate(elected_end_date)}`,
      date_swearing_certificate: formattedDate(date_swearing_certificate),
      date_constitutive_act: formattedDate(date_constitutive_act),
      date_proto_constitutive_act: formattedDate(date_proto_constitutive_act),
      date_statute: `${formattedDate(date_statute)}`,
      adminCouncil: administrativeMembers.admMembers,
      watchCouncil: vigilanceMembers.vigMembers,
    };

    const { data, statusCode } = yield call(FetchService, '/legal', 'POST', body, accessToken);

    if (data && statusCode == 200) {
      navigation('/register');
      yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
      yield call(showDialog, 'Registro legal exitoso', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOADING_LEGAL_REGISTER, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getLegallAsync(): any {
  try {
    yield put(actionObject(LOADING_LEGAL_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(FetchService, '/legal/detail', 'GET', null, accessToken);

    const {
      entityData,
      protocolData,
      administrativeMembers = { admMembers: [], completed: false },
      vigilanceMembers = { vigMembers: [], completed: false },
      legalStatus,
      explainAdmin = '',
      explainWatch = '',
    } = data;

    const legalData = {
      entityData,
      administrativeMembers,
      vigilanceMembers,
      legalStatus,
      protocolData: {
        ...protocolData,
        date_constitutive_act: data.protocolData ? new Date(data.protocolData.date_constitutive_act) : '',
        date_proto_constitutive_act: data.protocolData ? new Date(data.protocolData.date_proto_constitutive_act) : '',
        date_statute: data.protocolData ? new Date(data.protocolData.date_statute) : '',
        date_swearing_certificate: data.protocolData ? new Date(data.protocolData.date_swearing_certificate) : '',
        elected_end_date: data.protocolData ? new Date(data.protocolData.elected_end_date) : '',
        elected_start_date: data.protocolData ? new Date(data.protocolData.elected_start_date) : '',
      },
      explainAdm: explainAdmin,
      explainVig: explainWatch,
    };

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_LEGAL_GET, false));
      yield put(actionObject(GET_LEGAL_DATA_ASYNC, legalData));
    }

    if (!data) {
      yield put(actionObject(LOADING_LEGAL_GET, false));

      return response?.message;
    }
  } catch (error) {
    yield put(actionObject(LOADING_LEGAL_GET, false));
    return error;
  }
}

function* getPositionlAdmAsync(): any {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(FetchService, `/position/ADMIN`, 'GET', null, accessToken);

    if (data && statusCode == 200) {
      // const getRegister = (state: any) => state.register;
      // const members: any = yield select(getRegister);

      // const lastValues = members?.splice(6);
      // console.log(lastValues, data);

      yield put(actionObject(GET_POSITION_ADM_DATA_ASYNC, data));
    }

    if (!data) {
      return response?.message;
    }
  } catch (error) {
    return error;
  }
}

function* getPositionlWatchAsync(): any {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(FetchService, `/position/WATCH`, 'GET', null, accessToken);

    if (data && statusCode == 200) {
      yield put(actionObject(GET_POSITION_WATCH_DATA_ASYNC, data));
    }

    if (!data) {
      return response?.message;
    }
  } catch (error) {
    return error;
  }
}

function* deleteFileAsync({ payload }: any): any {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const response = yield call(FetchService, `/storage/remove/${payload}`, 'POST', undefined, accessToken);

    if (response) {
      yield put(actionObject(DELETE_FILE_ASYNC, response));
    }

    if (!response) {
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    throw new Error('Ha ocurrido un error');
  }
}

function* saveAdmMembersAsync({ payload }: any): any {
  try {
    yield put(actionObject(LOADING_FINANCIAL_REGISTER, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    yield call(FetchService, '/legal', 'POST', payload, accessToken);

    yield put(actionObject(LOADING_FINANCIAL_REGISTER, false));
    yield call(showDialog, 'Miembros actualizados', 'success');
  } catch (error) {
    yield put(actionObject(LOADING_LEGAL_REGISTER, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getMembersAdmAsync(): any {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(FetchService, `/legal/members`, 'GET', null, accessToken);

    if (data && statusCode == 200) {
      yield put(actionObject(GET_MEMBERS_ADM_DATA_ASYNC, data));
    }

    if (!data) {
      return response?.message;
    }
  } catch (error) {
    return error;
  }
}

export function* watchSendFinancial() {
  yield takeLatest(SEND_FINANCIAL_DATA, sendFinancialAsync);
}

export function* watchGetFinancial() {
  yield takeLatest(GET_FINANCIAL_DATA, getFinancialAsync);
}

export function* watchUpdateFinancial() {
  yield takeLatest(UPDATE_FINANCIAL_DATA, updateFinancialAsync);
}

export function* watchSendLegal() {
  yield takeLatest(SEND_LEGAL_DATA, sendLegalAsync);
}

export function* watchGetLegal() {
  yield takeLatest(GET_LEGAL_DATA, getLegallAsync);
}

export function* watchGetAdmPositions() {
  yield takeLatest(GET_POSITION_ADM_DATA, getPositionlAdmAsync);
}
export function* watchGetWatchPositions() {
  yield takeLatest(GET_POSITION_WATCH_DATA, getPositionlWatchAsync);
}
export function* watchDeleteFile() {
  yield takeLatest(DELETE_FILE, deleteFileAsync);
}

export function* watchSaveAdmMembersAsync() {
  yield takeLatest(SAVE_POSITION_ADM_DATA, saveAdmMembersAsync);
}

export function* watchGetMembersAdmAsync() {
  yield takeLatest(GET_MEMBERS_ADM_DATA, getMembersAdmAsync);
}
