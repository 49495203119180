import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { MuiChips, VerticalChart } from 'components';
import { DoughnutCard, Filters, IndicatorCard, IndicatorGrid, ProtocolRow, StatusCards } from './components';
import { hexColors } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployers, getReportData, getStates } from 'store/actions';
import { reportsSelector } from 'store/selectors';

export const AdminHome = () => {
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useDispatch();
  const reports = useSelector(reportsSelector);
  const { reportResult } = reports;

  useEffect(() => {
    dispatch(getStates());
    dispatch(getEmployers());
    dispatch(getEmployers());
    dispatch(getReportData([]));
  }, []);

  const buildData = (chartData: any) => {
    const currentValues: any = [];
    const result = currentValues.reverse();

    chartData?.map((item: any, index: any) => {
      return currentValues.unshift({
        labels: item?.label,
        values: item?.value,
        colors: hexColors[index],
      });
    });
    return result;
  };

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center' bgcolor={palette.light}>
      <Box
        width='100%'
        height={'calc(100vh - 5rem)'}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='center'
        position='relative'>
        <Box
          display={'flex'}
          margin={'4.5rem 1% 1rem 1%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          width={'98%'}>
          <Typography fontSize={16} fontWeight={'600'} marginRight={'1rem'} color={palette?.black}>
            Información de todas las cajas
          </Typography>
          <MuiChips
            method={() => setShowFilters((showFilters) => !showFilters)}
            isSelected={true}
            text={showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
          />
        </Box>
        {showFilters && <Filters />}

        <Box display={'flex'} width={'98%'}>
          <Box width={'50%'} height={'23.063rem'} margin={'0 1% 1rem 0'}>
            <DoughnutCard
              chartData={buildData(reportResult?.bySector?.chart ?? [])}
              detailData={reportResult?.bySector?.detail ?? []}
              title={'Cantidad de cajas de Ahorro'}
              total={reportResult?.total ?? 0}
            />
          </Box>
          <Box width={'50%'} height={'23.063rem'} margin={'0 0 1rem 1%'}>
            <DoughnutCard
              chartData={buildData(reportResult?.byAssociates?.chart ?? [])}
              detailData={reportResult?.byAssociates?.detail ?? []}
              title={'Cantidad de Asociados'}
              total={reportResult?.total ?? 0}
            />
          </Box>
        </Box>
        <Box width={'98%'} display={'flex'} height={'25.126rem'} justifyContent={'space-between'} alignItems={'center'}>
          <StatusCards chartData={buildData(reportResult?.byStatus?.chart ?? [])} total={reportResult?.total ?? 0} />
          <Box
            width={'55%'}
            height={'23.126rem'}
            margin={'0.5rem 0 1rem 2%'}
            justifyContent={'space-between'}
            padding={'1rem'}
            display={'flex'}
            flexDirection={'column'}
            borderRadius={'0.75rem'}
            bgcolor={palette?.light}>
            <Typography textAlign={'left'} variant='subtitle1'>
              Vigencia (Comparativo de un mes a otro)
            </Typography>
            <Box height='18rem' width={'100%'} maxHeight='18rem' paddingRight={0}>
              <VerticalChart chartValues={reportResult?.statusByMonths} />
            </Box>
          </Box>
        </Box>

        <Box
          width={'98%'}
          display={'flex'}
          paddingBottom={'1rem'}
          marginTop={'0.5rem'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <ProtocolRow
            title='En proceso de protocolización'
            icon='material-symbols:privacy-tip-outline'
            filter='in_process'
            total={0}
            iconBackground={palette?.primary}
          />
          <ProtocolRow
            title='Protocolizados'
            icon='mdi:check-underline'
            filter='verified'
            total={reportResult?.total ?? 0}
            iconBackground={palette?.success}
          />
        </Box>
        <Box width={'98%'} paddingBottom={'1rem'} marginTop={'0.5rem'}>
          <Box width={'15rem'} marginBottom={'1rem'}>
            <IndicatorCard title={'Estados financieros'} total={0} showCurrency={false} />
          </Box>
          {(reportResult?.indicators?.mainAccounts ?? []).map((mainIndicator: any) => (
            <IndicatorGrid
              key={mainIndicator.label}
              title={mainIndicator.label}
              data={mainIndicator.values}
              xsSize={3}
              showCurrency
            />
          ))}

          {(reportResult?.indicators?.indicators ?? []).map((indicator: any) => (
            <IndicatorGrid
              key={indicator?.label}
              title={indicator?.label}
              data={indicator?.values}
              xsSize={4}
              showCurrency={false}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
