import { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  AppBar,
  Grid,
  IconButton,
  SwipeableDrawer,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import { styles } from './styles';
import routes from '../routes';
import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';
import { baseUrl } from 'paths';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Theme } from '@mui/material';

const LoginNavBar = () => {
  const [open, setOpen] = useState(false);
  const navigation = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const openLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar sx={styles.main} position='fixed'>
          <Box display={'flex'} width={'100%'}>
            <Box
              component={'img'}
              sx={{ filter: 'grayscale(100%)' }}
              src={`${baseUrl}/resources/gob-logo.png`}
              width={isMobile ? '100%' : '462px'}
              alt='gob-logo'
            />
          </Box>
          <Box marginTop={!isMobile ? '0.5rem' : '1rem'} paddingBottom={!isMobile ? '1rem' : 0}>
            <Grid container spacing={0}>
              <Grid item display={'flex'} xs={2}>
                <Box
                  component={'img'}
                  marginBottom={!isMobile ? '0.5rem' : 0}
                  marginTop={!isMobile ? '0.5rem' : 0}
                  width={!isMobile ? 135 : 30}
                  src={`${baseUrl}${isMobile ? '/resources/logo.svg' : '/resources/logo-black.svg'}`}
                  alt='logo'
                />
              </Grid>
              <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'} xs={8}>
                <Box display={'flex'} flexGrow={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                  {routes?.map((item: any, index: number) => {
                    return (
                      <Box key={index}>
                        <Typography
                          fontSize={'0.938rem'}
                          textAlign={'left'}
                          fontWeight={'500'}
                          padding={'0 1.25rem'}
                          onClick={() => openLink(item?.link)}
                          onMouseEnter={() => {
                            // handleOpen(item);
                          }}
                          sx={styles.section}>
                          {item?.sectionName}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid display={'flex'} justifyContent={'flex-end'} alignItems={'center'} item xs={2}>
                <IconButton onClick={() => setOpen(true)} sx={styles.menuButton}>
                  <Icon icon='bx:menu' width={40} color={palette.black} />
                </IconButton>
                <Button onClick={() => navigation('/pre-register')} sx={styles.button} variant='contained'>
                  Registrarte en Sigecaf
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AppBar>
      </Box>

      <SwipeableDrawer
        anchor='top'
        disableBackdropTransition
        hideBackdrop
        sx={styles.swipeableDrawerStyles}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}>
        <Box margin={'0 1rem'}>
          <Box
            component={'img'}
            sx={{ filter: 'grayscale(100%)' }}
            width={'100%'}
            src={`${baseUrl}/resources/gob-logo.png`}
            alt='gob-logo'
          />
          <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
            <Box
              component={'img'}
              marginBottom={'0.5rem'}
              marginTop={'0.5rem'}
              width={30}
              src={`${baseUrl}/resources/logo.svg`}
              alt='logo'
            />
            <IconButton sx={{ margin: '0 0.5rem' }} onClick={() => setOpen(false)}>
              <Icon icon='material-symbols:close' width={30} color={palette.black} />
            </IconButton>
          </Box>

          <Button onClick={() => navigation('/pre-register')} sx={styles.responsiveButton} variant='contained'>
            Registrarte en Sigecaf
          </Button>

          <Box sx={{ overflowY: 'scroll' }} marginTop={'2rem'}>
            {routes?.map((item: any, index: number) => {
              return (
                <Accordion key={index} elevation={0}>
                  <AccordionSummary
                    expandIcon={<Icon icon={'mdi:chevron-down'} color={palette?.dark} width={25} />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'>
                    <Typography variant='body2' onClick={() => openLink(item?.link)}>
                      {item?.sectionName}
                    </Typography>
                  </AccordionSummary>
                  {item?.sections?.map((element: any, index: number) => {
                    return (
                      <AccordionDetails key={index}>
                        <Typography variant='subtitle2'>{element?.name}</Typography>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default LoginNavBar;
