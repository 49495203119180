import * as Yup from 'yup';
import { onlyLettersAndNumbers } from 'utils';

export const schema = Yup.object().shape({
  token: Yup.string(),
  password: Yup.string()
    .required('Campo requerido')
    .matches(onlyLettersAndNumbers, 'El campo debe contener solo letras y números')
    .min(8, `Este campo debe poseer un minimo de ${8} caracteres `)
    .max(255, `Este campo no debe tener más de ${255} caracteres`),

  confirm_password: Yup.string()
    .required('Campo requerido')
    .matches(onlyLettersAndNumbers, 'El campo debe contener solo letras y números')
    .oneOf([Yup.ref('password'), null], 'La contraseña no coincide.'),
});
