import { Box, Typography, InputAdornment } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { TextField, HistoricalTable } from 'components';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { styles } from './styles';
import { useForm } from 'react-hook-form';
import { getHistorical, searchReport } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { monthlyReportSelector } from 'store/selectors';
import { UsePagination } from 'hooks';

export const Historical = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const filesData = useSelector(monthlyReportSelector);
  const { data, meta } = filesData?.files;
  const totalPages = meta?.pageCount || 1;
  const [search, setSearch] = useState('');
  const headers = ['Mes', 'Descargar'];
  const {
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const { nextPage, previousPage, page, customPage } = UsePagination(meta?.page, totalPages, getHistorical);

  useEffect(() => {
    dispatch(getHistorical(1));
  }, []);

  const handleSearch = async ({ target }: any) => {
    const { value } = target;
    setSearch(value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(searchReport(search, customPage));
    }, 600);

    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center' bgcolor={palette.light}>
      <Box
        width='100%'
        height={'calc(100vh - 5rem)'}
        position={'relative'}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='center'>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'98%'}
          margin={'4.5rem 1% 1rem 1%'}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography fontSize={16} fontWeight={'600'} marginRight={'1rem'} color={palette?.dark}>
              Registros
            </Typography>
            <TextField
              label='Buscar'
              {...register('search', {
                onChange: handleSearch,
              })}
              placeholder='Mes de registro'
              type='text'
              name='search'
              control={control}
              id='search'
              InputProps={{
                style: { width: '28rem' },
                endAdornment: (
                  <InputAdornment position='end'>
                    <Icon icon='heroicons:magnifying-glass-solid' width={18} color={palette.black} />
                  </InputAdornment>
                ),
              }}
              ref={inputRef.current}
            />
          </Box>
        </Box>
        <HistoricalTable
          headers={headers}
          rowsData={data}
          isAdmin={false}
          title={`Histórico de caja (${meta?.itemCount ?? 0})`}
          page={page}
          nextPage={nextPage}
          previousPage={previousPage}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};
