import { useRef } from 'react';
import { TextField } from 'components';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { LoginNavBar, Footer } from './components';
import { useMediaQuery, Theme } from '@mui/material';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'store/actions';
import { intermitenceSelector } from 'store/selectors';
import { LoginResponsive } from './components/LoginResponsive';
import { UseIntermitence } from 'hooks';

export const Login = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const inputRef = useRef(null);
  const navigation = useNavigate();
  const { status, switchStatus } = UseIntermitence();

  const { loginLoading } = useSelector(intermitenceSelector);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      user: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => dispatch(loginUser(data, navigation));

  return (
    <>
      <LoginNavBar />
      {!isMobile ? (
        <Box
          sx={styles.main}
          display='flex'
          flexDirection='column'
          alignItems='center'
          height='100vh'
          bgcolor={palette.light}>
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            alignItems='center'
            position='relative'>
            <Box display='flex' width='100%' sx={styles.sideContainer}>
              <Box
                sx={styles.leftSide}
                padding='2rem'
                bgcolor={palette.dark}
                display='flex'
                justifyContent='center'
                alignItems='center'>
                <Typography sx={styles.title} color={palette.white} textAlign='left' paragraph={true}>
                  <b> Ingresa al Sigecaf: Gestión segura y transparente de las Cajas de Ahorro.</b>
                </Typography>
              </Box>

              <Box position='relative' sx={styles.rightSide}>
                <Box
                  sx={styles.image}
                  component={'img'}
                  src={`${process.env.PUBLIC_URL}/resources/hands-background.svg`}
                  alt='hands-background'
                />

                <Box
                  sx={styles.backgroundImage}
                  component={'img'}
                  src={`${process.env.PUBLIC_URL}/resources/venezuela-point.svg`}
                  alt='hands-background'
                />
              </Box>

              <Box
                sx={styles.formContainer}
                bgcolor={palette.white}
                borderRadius='0.938rem'
                padding='2rem'
                bottom='-30px'
                display='flex'
                margin='0 auto'>
                <Box display='flex' justifyContent='space-between' sx={styles.fieldsContainer}>
                  <Box
                    sx={styles.fieldContent}
                    width='100%'
                    display='flex'
                    justifyContent='space-between'
                    flexDirection='column'>
                    <TextField
                      fullWidth
                      label='ID'
                      {...register('user')}
                      placeholder='Texto'
                      id='user'
                      helperText={errors?.user?.message}
                      name='user'
                      control={control}
                      error={!!errors?.user}
                      ref={inputRef.current}
                    />
                    <Box display='flex'>
                      <Typography variant='h6' onClick={() => navigation('/pre-register')} sx={styles.text}>
                        ¿No estás registrado? <u>Ingresa aquí.</u>
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={styles.fieldContent}
                    width='100%'
                    display='flex'
                    justifyContent='space-between'
                    flexDirection='column'>
                    <TextField
                      fullWidth
                      label='Contraseña'
                      {...register('password')}
                      placeholder='Texto'
                      type={!status ? 'password' : 'text'}
                      icon={status ? 'mdi:eye-off-outline' : 'ic:baseline-remove-red-eye'}
                      control={control}
                      helperText={errors.password?.message}
                      iconMethod={switchStatus}
                      error={!!errors?.password}
                      ref={inputRef.current}
                    />
                    <Box display='flex'>
                      <Typography variant='h6' sx={styles.text} onClick={() => navigation('/forgot-password')}>
                        ¿Has olvidado tu contraseña? <u>Recupérala.</u>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.buttonContainer}>
                  <Button type='submit' onClick={handleSubmit(onSubmit)} sx={styles.button} variant='contained'>
                    {loginLoading ? <CircularProgress color='inherit' size={28} /> : 'Iniciar sesión'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <>
            <Box
              marginTop='5rem'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              width={'100%'}
              bgcolor={palette.light}
              paddingBottom={'5rem'}>
              <Typography fontSize='3rem' fontWeight='600' color={palette.black}>
                ¿Estás interesado en asociarte con nosotros?
              </Typography>

              <Box
                marginTop='5rem'
                bgcolor={palette.white}
                borderRadius='0.938rem'
                padding='3rem 5rem 3rem 5rem'
                display='flex'
                justifyContent='center'
                flexDirection='column'
                alignItems='center'
                width='85%'>
                <Box width='80%'>
                  <Typography fontSize='1.25rem' color={palette.black}>
                    Para solicitar asociarte con nosotros, envía los siguientes requisitos al correo:
                  </Typography>
                  <Typography fontSize='1.25rem' color={palette.black} fontWeight='700'>
                    sudeca@gmail.com
                  </Typography>
                </Box>

                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='flex-start'
                  marginTop='3rem'
                  width='80%'>
                  <Typography
                    fontSize='1.25rem'
                    color={palette.black}
                    fontWeight='700'
                    margin='0.5rem  0 1rem 0'
                    textAlign='left'>
                    1. Oficio dirigido al superintendente (donde se indique el deseo de constituir una caja de ahorros)
                  </Typography>
                  <Typography
                    fontSize='1.25rem'
                    color={palette.black}
                    fontWeight='700'
                    margin='0.5rem  0 1rem 0'
                    textAlign='left'>
                    2. Nombre de la caja con sus siglas.
                  </Typography>
                  <Box display='flex' alignItems='center' margin='0.5rem  0 1rem 0'>
                    <Typography
                      fontSize='1.25rem'
                      color={palette.black}
                      fontWeight='700'
                      marginRight='2rem'
                      textAlign='left'>
                      3. Acta Constitutiva con su estatutos
                    </Typography>

                    {/* <DownloadButton width={18} /> */}
                  </Box>

                  <Typography
                    fontSize='1.25rem'
                    color={palette.black}
                    fontWeight='700'
                    margin='0.5rem  0 1rem 0'
                    textAlign='left'>
                    4. Listados de asociados.
                  </Typography>
                  <Typography
                    fontSize='1.25rem'
                    color={palette.black}
                    fontWeight='700'
                    margin='0.5rem  0 1rem 0'
                    textAlign='left'>
                    5. La orden de protocolización.
                  </Typography>
                  <Typography
                    fontSize='1.25rem'
                    color={palette.black}
                    fontWeight='700'
                    margin='0.5rem  0 1rem 0'
                    textAlign='left'>
                    6. Una vez registrada remitir el acta debidamente protocolizada para que se le asigne el numero de
                    registro y sector al cual pertenece.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Footer />
          </>
        </Box>
      ) : (
        <LoginResponsive />
      )}
    </>
  );
};
