export const passwordRegex =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
// Deben tener al menos 8 caracteres, contener caracteres especiales, minúsculas y mayúsculas, incluso números y símbolos, si se permite.
export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
// Ingrese un formato válido, por ejemplo email@gmail.com
export const onlyLettersRegex = /^[a-zA-ZñÑ ]+$/; //Este campo no debe tener caracteres numéricos ni caracteres especiales
export const onlyNumbersRegex = /^[0-9 ]+$/; //Este campo solo debe tener caracteres numéricos.
export const onlyNumbersRegexWithoutZero = /^[1-9]+$/;
export const onlyNumbersFirstNoZero = /^[1-9][0-9]*$/; //Este campo solo debe tener caracteres numéricos (El primer numero no puede ser 0)
export const onlyNumbersWithoutSpace = /^[0-9]{0,}$/;
export const phoneRegex = /^(\+|00)[1-9]{1,3}[ ][0-9]{3}[0-9]{7,12}$/g; //El número de teléfono no es válido el formato debe ser el siguiente 0123456789
export const amountRegex = /(^(\-|)[0-9]+\.[0-9]{1,2}$|^(\-|)[0-9]+$)/gm; // El monto no cumple con el formato requerido. Ejemplo 1 o 1.25
export const onlyLettersAndNumbers = /^[a-zA-ZñÑ0-9]*$/; //No se permiten caracteres especiales
export const simplePhoneRegex = /^(\+|0)[0-9]{9,11}$/g;
export const onlyLettersNumbersAndSpace = /^[a-zA-ZñÑ0-9 ]*$/;
export const onlyLettersNumbersWithoutSpace = /^[a-zA-ZñÑ0-9]*$/;
export const amountCharactersRegex = /^[0-9,.]+$/;
export const IdRegex = /[a-zA-Z]{2}[-]\d{4}/;
export const CiOrRifRegex = /^(v|V|j|J)([0-9]{7,9})$/; // Este campo debe poseer un formato valido como J000000000 O V00000000
export const CIRegex = /^(v|V)([0-9]{7,8})$/; // Este campo debe poseer un formato valido como J000000000 O V00000000
export const RifRegex = /^(j|J)([0-9]{8,9})$/;
export const fullFormatPhoneRegex = /^([0]{1})+([0-9]{10,11})$/g;
