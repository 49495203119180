import { useRef } from 'react';
import { MuiModal, TextField } from 'components';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styles } from './styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMediaQuery, Theme } from '@mui/material';
import { palette } from 'theme/contants';

export const RejectModal = ({ open, onClose, onConfirm }: any) => {
  const inputRef = useRef(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      reason: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    onConfirm(data?.reason);
    onClose();
  };

  const value = watch('reason');
  return (
    <MuiModal
      width={'60%'}
      height={!isMobile ? '24.25rem' : '23rem'}
      marginTop={!isMobile ? '4rem' : '0'}
      titleAlign={isMobile ? 'center' : 'left'}
      open={open}
      onCancel={onClose}
      title={'Explique el motivo del rechazo'}>
      <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography textAlign={'left'} sx={styles.modalsubtitle}>
            Explica la razón
          </Typography>

          <Grid item display={'flex'} margin={'0 1rem 0 0'} xs={12}>
            <TextField
              fullWidth
              label='Dejar comentario'
              {...register('reason')}
              placeholder='Texto'
              multiline
              inputProps={{ maxLength: 255 }}
              InputProps={{
                style: {
                  paddingBottom: '30px',
                  paddingRight: '7px',
                  paddingTop: '5px',
                },
                endAdornment: (
                  <Box display={'flex'} position={'absolute'} bottom={'0.5rem'} right={0} marginRight={'1rem'}>
                    <Typography color={palette?.black} fontSize={'12px'} fontWeight={'500'}>
                      {value?.length}
                    </Typography>
                    <Typography fontSize={'12px'} fontWeight={'500'} color={palette?.boulder}>
                      {`/${255}`}
                    </Typography>
                  </Box>
                ),
              }}
              rows={4}
              control={control}
              helperText={errors.reason?.message ? errors.reason?.message : ''}
              error={!!errors?.reason}
              ref={inputRef.current}
            />
          </Grid>
        </Box>

        <Box sx={styles.buttonContainer} display={'flex'} marginTop={'3rem'}>
          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            Confirmar
          </Button>
        </Box>
      </Grid>
    </MuiModal>
  );
};
