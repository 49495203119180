import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { FinancialTable, RejectModal } from '../../components';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { clientsSelector, authSelector } from 'store/selectors';
import { useEffect } from 'react';
import { getClientFinancialDetail, setFinancialStatus } from 'store/actions';
import { StatusEnum, PermissionEnum } from 'utils/constants';
import { parseAmount } from 'utils';
import { UseIntermitence } from 'hooks';

export const FinancialData = ({ clientId }: any) => {
  const dispatch = useDispatch();
  const clientData = useSelector(clientsSelector);
  const { status, switchStatus } = UseIntermitence();

  const {
    user: {
      role: { permissions },
    },
  } = useSelector(authSelector);

  const incomes = [
    { name: 'Ingresos por Cartera de Préstamos', value: clientData?.financialDetail?.income?.load_portfolio },
    { name: 'Ingresos Financieros', value: clientData?.financialDetail?.income?.financial_income },

    {
      name: 'Rendimientos sobre Cartera de Inversión',
      value: clientData?.financialDetail?.income?.investment_portfolio_income,
    },
    { name: 'Otros Ingresos Operativos', value: clientData?.financialDetail?.income?.operating_income },
    { name: 'Ingresos Extraordinarios', value: clientData?.financialDetail?.income?.extraordinary },
  ];
  const expenses = [
    { name: 'Gastos de Personal', value: clientData?.financialDetail?.expense?.personal },
    { name: 'Gastos Administrativos', value: clientData?.financialDetail?.expense?.administrative },
    { name: 'Pago por Servicios Recibidos', value: clientData?.financialDetail?.expense?.services_received },
    {
      name: 'Gastos de Depreciación de Propiedad, Planta y Equipo',
      value: clientData?.financialDetail?.expense?.depreciation,
    },
    { name: 'Gastos por Constitución de la Estimación', value: clientData?.financialDetail?.expense?.estimates },
    { name: 'Gastos Financieros', value: clientData?.financialDetail?.expense?.financial_expense },
    { name: 'Gastos operativos', value: clientData?.financialDetail?.expense?.operating_expense },
  ];
  const currentAssets = [
    { name: 'Efectivo Moneda Nacional', value: clientData?.financialDetail?.asset?.national_currency },
    { name: 'Efectivo Moneda Extranjera', value: clientData?.financialDetail?.asset?.foreign_currency },
    { name: 'Bancos e instituciones financieras', value: clientData?.financialDetail?.asset?.banks },
    { name: 'Cartera de préstamos', value: clientData?.financialDetail?.asset?.load_portfolio_asset },
    { name: 'Cartera de inversión', value: clientData?.financialDetail?.asset?.investment_portfolio_asset },
  ];
  const notCurrentAssets = [
    { name: 'Terreno', value: clientData?.financialDetail?.asset?.land },
    { name: 'Edificio', value: clientData?.financialDetail?.asset?.building },
    { name: 'Equipos de transporte', value: clientData?.financialDetail?.asset?.transport_equipment },
    { name: 'Otros activos', value: clientData?.financialDetail?.asset?.other_assets },
  ];
  const liability = [
    { name: 'Cuentas por pagar', value: clientData?.financialDetail?.liability?.accounts_payable },
    { name: 'Compromisos laborales', value: clientData?.financialDetail?.liability?.commitments },
    { name: 'Haberes por pagar', value: clientData?.financialDetail?.liability?.items_payable },
    { name: 'Otros pasivos', value: clientData?.financialDetail?.liability?.other_liabilities },
  ];
  const capital = [
    {
      name: 'Aporte recibido (Aporte del Asociado)',
      value: clientData?.financialDetail?.capital?.contribution_associate_received,
    },
    {
      name: 'Aporte recibido (Aporte del empleador)',
      value: clientData?.financialDetail?.capital?.contribution_employer_received,
    },
    {
      name: 'Aporte no recibido (Aporte del Asociado)',
      value: clientData?.financialDetail?.capital?.contribution_associate,
    },
    {
      name: 'Aporte no recibido (Aporte del empleador)',
      value: clientData?.financialDetail?.capital?.contribution_employer,
    },
    { name: 'Reservas legales', value: clientData?.financialDetail?.capital?.legal_reserves },
  ];

  const calculateTotal = (data: any) => {
    const amounts = data?.map((item: any) => {
      return parseFloat(item?.value);
    });

    const total = amounts.reduce((accumulator: number, current: number) => {
      return accumulator + current;
    }, 0);

    return parseAmount(total);
  };

  useEffect(() => {
    dispatch(getClientFinancialDetail(clientId));
  }, []);

  const setReviewStatus = (status: string) => {
    if (status == 'Rejected') return switchStatus();

    dispatch(setFinancialStatus({ clientId, status }));
    dispatch(getClientFinancialDetail(clientId));
  };

  return (
    <>
      {clientData?.financialDetail?.income ? (
        <>
          <Box paddingBottom={'1rem'} width={'100%'}>
            <FinancialTable title='Ingresos de la caja' rows={incomes} totalAmount={calculateTotal(incomes)} />
            <FinancialTable title='Egresos de la caja ' rows={expenses} totalAmount={calculateTotal(expenses)} />
            <FinancialTable
              title='Activos corrientes'
              rows={currentAssets}
              totalAmount={calculateTotal(currentAssets)}
            />
            <FinancialTable
              title='Activos no corrientes'
              rows={notCurrentAssets}
              totalAmount={calculateTotal(notCurrentAssets)}
            />
            <FinancialTable title='Pasivos' rows={liability} totalAmount={calculateTotal(liability)} />
            <FinancialTable
              title='Patrimonio constituido y reservas atribuibles'
              rows={capital}
              totalAmount={calculateTotal(capital)}
            />
          </Box>
          {clientData?.financialDetail?.financialStatus == StatusEnum?.WAITING && (
            <Box display={'flex'} justifyContent={'flex-end'} width='100%' paddingBottom='1rem' alignContent={'center'}>
              {permissions.includes(PermissionEnum.RejectedFinantial) && (
                <Box width={'10.125rem'} marginRight='1rem'>
                  <Button
                    onClick={() => setReviewStatus('Rejected')}
                    sx={{ background: palette?.error, width: '100%' }}
                    variant={'contained'}>
                    <Box display={'flex'} justifyContent={'center'} marginRight={'0.5rem'}>
                      <Icon icon='fa6-solid:circle-xmark' width={18} color={palette.white} />
                    </Box>
                    Rechazado
                  </Button>
                </Box>
              )}

              {permissions.includes(PermissionEnum.ApproveFinantial) && (
                <Box width={'10.125rem'} marginRight='1%'>
                  <Button
                    onClick={() => setReviewStatus('Approved')}
                    sx={{ background: palette?.success, width: '100%' }}
                    variant={'contained'}>
                    <Box display={'flex'} justifyContent={'center'} marginRight={'0.5rem'}>
                      <Icon icon='fa6-solid:circle-check' width={18} color={palette.white} />
                    </Box>
                    Aprobado
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <EmptyState clientData={clientData} />
      )}

      {status && (
        <RejectModal
          open={status}
          onClose={switchStatus}
          onConfirm={(value: string) => dispatch(setFinancialStatus({ clientId, status: 'Rejected', reason: value }))}
        />
      )}
    </>
  );
};

export const EmptyState = ({ clientData }: any) => {
  return (
    <Box width={'100%'} height={'70%'} flexDirection={'column'} display={'flex'} justifyContent={'center'}>
      <Box>
        {clientData?.clientsLoading ? (
          <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
        ) : (
          <>
            <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
            <Typography color={palette?.dark} variant='subtitle1'>
              No hay datos financieros de la caja
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
