import { Theme } from '@mui/material';
import { palette } from 'theme/contants';

export const styles = {
  image: (theme: Theme) => ({
    backgroundSize: 'contain',
    width: '100%',
    zIndex: 1,
    position: 'absolute',
    marginTop: '3rem',
  }),
  backgroundImage: (theme: Theme) => ({
    backgroundSize: 'contain',
    width: '100%',
    zIndex: 0,
  }),

  text: {
    color: palette.black,
    margin: '0.5rem 0.8rem 0 0',
    '&:hover': {
      color: palette.primary,
      cursor: 'pointer',
    },
  },
  title: (theme: Theme) => ({
    fontSize: '3rem',

    lineHeight: '1.2',
    b: {
      fontWeight: '700',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  }),
};
