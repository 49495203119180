import { useRef, useEffect, useState } from 'react';
import { Box, Button, InputAdornment } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { AutocompleteSelect, HistoricalTable, TextField } from 'components';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { monthlyReportSelector } from 'store/selectors';
import { UsePagination } from 'hooks';
import { exportFinancialHistorical, getFinancialHistorical, searchFinancialHistorical } from 'store/actions';
import { getYearsInRange, months, savingsSize, sectors } from 'utils';
import { getExportableUrl, getS3Url } from 'store/intermitence/action';

export const FinancialHistorical = () => {
  const inputRef = useRef(null);
  const historical = useSelector(monthlyReportSelector);
  const [search, setSearch]: any = useState(null);
  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const [aviableMonths, setAviableMonths] = useState<any>(months);

  const currentDate = new Date();
  const currentYear: any = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [currentParams, setCurrentParams] = useState<any>({ year: currentYear, month: currentMonth });

  const filterAvailableMonths = () => {
    const selectedMonth = currentParams?.month;

    const result: any = months.filter((month) => {
      if (currentParams?.year === currentYear) {
        return parseInt(month.value) <= currentMonth;
      }

      return false;
    });

    if (result?.length == 0) return setAviableMonths(months);
    setAviableMonths(result);

    if (selectedMonth > result[result?.length - 1].value) return setValue('month', result[result?.length - 1]);
  };

  const {
    financialHistorical: { data, meta },
  } = historical;

  const totalPages = meta?.pageCount || 1;

  const { nextPage, previousPage, page } = UsePagination(meta?.page, totalPages, getFinancialHistorical, currentParams);

  const { register, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
      year: null,
      month: null,
      sector: null,
      size: null,
    },
  });

  const headers: string[] = ['Id de caja', 'Sector', 'Miembros', 'Txt'];

  const handleSearch = async ({ target }: any) => {
    const { value } = target;
    setSearch(value);
  };

  const handleSelect = (value: any, type: any) => {
    setValue(type, value);

    const filters: any = {
      ...currentParams,
      [type]: type == 'month' ? value?.value : value?.id,
    };

    setCurrentParams(filters);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        searchFinancialHistorical(search, {
          month: currentParams?.month,
          year: currentParams?.year,
          sector: currentParams?.sector,
          size: currentParams?.size,
        }),
      );
    }, 600);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    filterAvailableMonths();

    dispatch(
      getFinancialHistorical({
        page: page,
        sector: currentParams?.sector,
        year: currentParams?.year,
        month: currentParams?.month,
        size: currentParams?.size,
      }),
    );
  }, [currentParams]);

  useEffect(() => {
    const result: any = months.filter((month: any) => month.value == currentMonth);

    handleSelect(result?.[0], 'month');
    handleSelect({ name: currentYear, id: currentYear }, 'year');
  }, []);

  const downloadReport = () => {
    let modifiedParams = { ...currentParams, search };

    dispatch(
      exportFinancialHistorical(modifiedParams, ({ ok, data }) => {
        if (ok) {
          dispatch(
            getExportableUrl(data, ({ ok, values }) => {
              if (ok) {
                window.open(values?.url);
              }
            }),
          );
        }
      }),
    );
  };

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center' bgcolor={palette.light}>
      <Box
        width='100%'
        height={'calc(100vh - 5rem)'}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='center'
        position='relative'>
        <Box
          display={'flex'}
          margin={'4.5rem 1% 1rem 1%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          width={'98%'}>
          <TextField
            label='Buscar'
            {...register('search', {
              onChange: handleSearch,
            })}
            placeholder='ID de caja de ahorro'
            type='text'
            name='search'
            control={control}
            id='search'
            InputProps={{
              style: { width: '20rem' },
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon icon='heroicons:magnifying-glass-solid' width={18} color={palette.black} />
                </InputAdornment>
              ),
            }}
            ref={inputRef.current}
          />
          <Box width={'20rem'} marginLeft={'1rem'}>
            <AutocompleteSelect
              {...register('month')}
              label={'Mes'}
              options={aviableMonths}
              disableClearButton
              placeholder={'Seleccionar'}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.gray} !important`,
                  },
                  padding: '4px 14px 4px 4px',
                },
              }}
              multiple={false}
              control={control}
              handleSelection={(value: any) => {
                handleSelect(value, 'month');
              }}
              ref={selectRef.current}
              limitTags={1}
            />
          </Box>
          <Box width={'20rem'} marginLeft={'1rem'}>
            <AutocompleteSelect
              {...register('year')}
              label={'Año'}
              options={getYearsInRange(new Date(), 2000)}
              disableClearButton
              placeholder={'Seleccionar'}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.gray} !important`,
                  },
                  padding: '4px 14px 4px 4px',
                },
              }}
              multiple={false}
              control={control}
              handleSelection={(value: any) => {
                handleSelect(value, 'year');
              }}
              ref={selectRef.current}
              limitTags={1}
            />
          </Box>
          <Box width={'13.708rem'} marginLeft={'1rem'}>
            <Button
              onClick={() => downloadReport()}
              variant='outlined'
              disabled={data?.length == 0 ? true : false}
              type='submit'
              sx={{
                color: palette?.dark,
                borderColor: palette?.dark,
                borderRadius: '0.938rem',
                width: '100%',
                fontWeight: '600',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: palette.blueHaze,
                },
              }}>
              {'Descargar data'}
            </Button>
          </Box>
        </Box>
        <Box
          display={'flex'}
          margin={'1rem 1% 2rem 1%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          width={'98%'}>
          <Box width={'20rem'}>
            <AutocompleteSelect
              {...register('sector')}
              options={sectors}
              placeholder={'Seleccionar'}
              label={'Sector'}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.gray} !important`,
                  },
                  padding: '4px 14px 4px 4px',
                },
              }}
              multiple={false}
              control={control}
              handleSelection={(value: any) => {
                handleSelect(value, 'sector');
              }}
              ref={selectRef.current}
              limitTags={1}
            />
          </Box>
          <Box width={'20rem'} marginLeft={'1rem'}>
            <AutocompleteSelect
              {...register('size')}
              options={savingsSize}
              placeholder={'Seleccionar'}
              label={'Tamaño de la caja'}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.gray} !important`,
                  },
                  padding: '4px 14px 4px 4px',
                },
              }}
              multiple={false}
              control={control}
              handleSelection={(value: any) => {
                handleSelect(value, 'size');
              }}
              ref={selectRef.current}
              limitTags={1}
            />
          </Box>
        </Box>

        <HistoricalTable
          headers={headers}
          rowsData={data}
          isAdmin
          title={`Número de Estados financieros (${meta?.itemCount ?? 0})`}
          page={page}
          nextPage={nextPage}
          previousPage={previousPage}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};
