import { MuiCssBaseline } from './CssBaseline';
import { MuiButton } from './Button';
import { MuiInputLabel } from './InputLabel';
import { MuiSwitch } from './Switch';
import { MuiCheckbox } from './Checkbox';
import { MuiRadio } from './Radio';
import { MuiFormHelperText } from './HelperText';
import { MuiInputBase } from './InputBase';
import { MuiAlert } from './Alert';
import { MuiTypography } from './Typography';
import { MuiYearPicker } from './YearPicker';
import { MuiAppBar } from './AppBar';
import { MuiDrawer } from './Drawer';

export const OverridesComponents = {
  MuiCssBaseline,
  MuiButton,
  MuiAlert,
  MuiTypography,
  MuiYearPicker,
  MuiAppBar,
  MuiDrawer,
  // Form
  MuiInputBase,
  MuiInputLabel,
  MuiFormHelperText,
  MuiSwitch,
  MuiCheckbox,
  MuiRadio,
};
