import { useRef } from 'react';
import { TextField } from 'components';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { styles } from '../../../../styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import { palette } from 'theme/contants';

export const EntityData = () => {
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const { entityData } = registerData;
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    control,

    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: entityData?.name ? entityData?.name : '',
      rif: entityData?.name ? entityData?.rif : '',
      register_number: entityData?.name ? entityData?.register_number : '',
      address: entityData?.name ? entityData?.address : '',
      employer: entityData?.name ? entityData?.employer : '',
      number_associates: entityData?.name ? entityData?.number_associates : '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    dispatch(setEntityData({ ...registerData, entityData: data, legalStep: 2 }));
  };

  const savingName = watch('name');
  const savingAddress = watch('address');
  const savingEmployer = watch('employer');
  // UseEnterPress(() => onSubmit(values));

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography textAlign={'left'} sx={styles.secondFormText} fontWeight={'600'} color={palette.black}>
            Datos de las Cajas de Ahorro
          </Typography>

          <Box display={'flex'} sx={styles.inputContainer}>
            <Tooltip title={savingName ?? ''} placement={'top'}>
              <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                <TextField
                  fullWidth
                  label='Nombre'
                  {...register('name')}
                  placeholder='Texto'
                  control={control}
                  helperText={errors?.name?.message ? errors?.name?.message : 'Nombre de la Caja con su siglas'}
                  error={!!errors?.name}
                  ref={inputRef.current}
                  disabled
                />
              </Grid>
            </Tooltip>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <TextField
                fullWidth
                label='Rif'
                {...register('rif')}
                placeholder='Texto'
                helperText={errors?.rif?.message ? errors?.rif.message : 'Registro de Información Fiscal'}
                control={control}
                error={!!errors?.rif}
                ref={inputRef.current}
                disabled
              />
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <TextField
                fullWidth
                label='Número de registro público'
                {...register('register_number')}
                placeholder='Texto'
                helperText={
                  errors?.register_number?.message ? errors?.register_number.message : 'Número de registro y sector'
                }
                control={control}
                error={!!errors?.register_number}
                ref={inputRef.current}
                disabled
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Tooltip title={savingAddress ?? ''} placement={'top'}>
              <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                <TextField
                  fullWidth
                  label='Dirección completa de la Sede de la Caja de Ahorros'
                  {...register('address')}
                  helperText={errors?.address?.message ? errors?.address.message : ''}
                  placeholder='Texto'
                  control={control}
                  error={!!errors?.address}
                  ref={inputRef.current}
                />
              </Grid>
            </Tooltip>
            <Tooltip title={savingEmployer ?? ''} placement={'top'}>
              <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                <TextField
                  fullWidth
                  label='Empleador'
                  {...register('employer')}
                  placeholder='Texto'
                  helperText={errors?.employer?.message ? errors?.employer.message : ''}
                  control={control}
                  error={!!errors?.employer}
                  ref={inputRef.current}
                />
              </Grid>
            </Tooltip>
            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <TextField
                fullWidth
                label='Cantidad de asociados actualizado'
                {...register('number_associates')}
                placeholder='0'
                helperText={errors?.number_associates?.message ? errors?.number_associates.message : ''}
                control={control}
                error={!!errors?.number_associates}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => navigation('/register')} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            Continuar
          </Button>
        </Box>
      </Box>
    </>
  );
};
