import { Theme } from '@mui/material';

export const styles = {
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#E1E5F3',
      padding: '0.75rem 1rem',
      fontSize: '14px',
      color: '#000',
      borderRadius: '5px',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media only screen and (max-width: 900px)': {
      padding: 0,
    },
    '& .MuiTooltip-arrow': {
      color: '#E1E5F3',
    },
  },
  containerButton: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '45%',
    },
  }),
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
};
