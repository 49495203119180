import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    marginTop: '5rem',
    height: 'calc(100vh - 5rem)',

    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(100% - 80%);',
    },
  }),

  rowText: (theme: Theme) => ({
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '0.75rem',
    fontWeight: '700',
  }),
  tableButton: (theme: Theme) => ({
    height: '1.813rem',
    width: '9.75rem',
    borderRadius: '0.625rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '60px',
      borderRadius: '50%',
      fontSize: '2.5rem',
      padding: 0,
    },
  }),
  button: (theme: Theme) => ({
    width: '8.563rem',
    marginBottom: '1.4rem',
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem 0',
      width: '100%',
    },
  }),
};
