import { Theme } from '@mui/material';
import { palette } from 'theme/contants';

export const styles = {
  buttonContainer: (theme: Theme) => ({
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),

  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  }),

  tableButton: (theme: Theme) => ({
    height: '1.813rem',
    width: '9.75rem',
    borderRadius: '0.625rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '60px',
      borderRadius: '50%',
      fontSize: '2.5rem',
      padding: 0,
    },
  }),
  rowText: (theme: Theme) => ({
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '0.75rem',
    fontWeight: '700',
  }),
  emptyTable: (theme: Theme) => ({
    bgcolor: palette.light,
    borderRadius: '1.125rem',
    marginBottom: '5rem',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  }),
  table: (theme: Theme) => ({
    bgcolor: palette.light,
    borderRadius: '1.125rem',
    marginBottom: '5rem',

    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  }),
  formText: (theme: Theme) => ({
    fontSize: '1.25rem',
    marginBottom: '3rem',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      marginBottom: '1rem',
      marginTop: '1rem',
      width: '100%',
    },
  }),
};
