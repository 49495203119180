import * as Yup from 'yup';
import { CiOrRifRegex, onlyLettersRegex, amountCharactersRegex } from 'utils';

export const schema = Yup.object().shape({
  land: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  building: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  transport_equipment: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
  other_assets: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
});
