import { Box, IconButton, Typography } from '@mui/material';
import { palette } from 'theme/contants';
// import { MuiChips } from 'components';

import { Icon } from '@iconify/react';
// import { useNavigate } from 'react-router-dom';
import { ProtocolRowTypes } from './types';
import { FC } from 'react';

export const ProtocolRow: FC<ProtocolRowTypes> = ({ title, icon, total, filter, iconBackground }) => {
  // const navigation = useNavigate();

  return (
    <Box
      display='flex'
      borderRadius={'0.75rem'}
      justifyContent={'space-between'}
      width={'49%'}
      alignItems='center'
      padding={'1rem'}
      bgcolor={palette.light}>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <IconButton
          disableRipple
          sx={{ background: iconBackground, padding: '0.6rem', cursor: 'inherit', marginTop: '0.2rem' }}>
          <Icon icon={icon} width={23} color={palette.white} />
        </IconButton>
        <Typography textAlign={'left'} marginLeft={'0.5rem'} variant='subtitle1'>
          {title}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Typography textAlign={'left'} marginRight={'1rem'} marginLeft={'0.5rem'} variant='subtitle1'>
          {total}
        </Typography>
        {/* <MuiChips
          method={() => navigation('/clients-list', { state: { filter: filter, type: 'protocol' } })}

          isSelected={true}
          text={'Ver todas'}
        /> */}
      </Box>
    </Box>
  );
};
