import { palette } from '../contants';

export const MuiRadio = {
  styleOverrides: {
    root: {
      color: palette.primary,
      width: '15px',
      height: '15px',
      margin: '12px',

      '&.Mui-checked': {
        backgroundColor: palette.primary,
      },
    },
  },
};
