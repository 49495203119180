import { Theme } from '@mui/material';

export const styles = {
  containerButton: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '45%',
    },
  }),
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
};
