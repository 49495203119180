import { Box, Typography } from '@mui/material';
import { SelectorBoxType } from './types';
import { FC } from 'react';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { useMediaQuery, Theme } from '@mui/material';

export const SelectorBox: FC<SelectorBoxType> = ({ icon, iconSize, text = '', status, method }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box
      onClick={method}
      sx={styles.selectTypeOptions}
      borderRadius={'1.063rem'}
      flexDirection={'column'}
      bgcolor={palette.light}
      display={'flex'}
      border={`1px solid ${status?.color ? status?.color : palette?.light}`}
      justifyContent={'center'}
      alignItems={'center'}>
      <Icon icon={icon} width={iconSize} color={palette?.dark} />
      <Typography textAlign={'center'} fontWeight={'600'} color={palette.black} sx={styles.selectTypeOptionText}>
        {text}
      </Typography>
      {status?.icon && (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={!isMobile ? '2rem' : '0.5rem'}>
          <Icon icon={status?.icon} width={!isMobile ? 25 : 22} color={status?.color} />
          <Typography textAlign={'center'} fontWeight={'600'} color={palette.black} sx={styles.selectTypeText}>
            {status?.text}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
