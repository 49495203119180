import { useRef, useState, useEffect } from 'react';
import { InputAmount } from 'components';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { parseAmount, parseTotalAmount, handleAmountFormat, plainNumberString } from 'utils';
import { useMediaQuery, Theme } from '@mui/material';

export const CurrentAssets = () => {
  const inputRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const { assets, totalCurrentAssets } = registerData;
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      national_currency: assets?.national_currency ? handleAmountFormat(assets?.national_currency) : '',
      foreign_currency: assets?.foreign_currency ? handleAmountFormat(assets?.foreign_currency) : '',
      banks: assets?.banks ? handleAmountFormat(assets?.banks) : '',
      load_portfolio_asset: assets?.load_portfolio_asset ? handleAmountFormat(assets?.load_portfolio_asset) : '',
      investment_portfolio_asset: assets?.investment_portfolio_asset
        ? handleAmountFormat(assets?.investment_portfolio_asset)
        : '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    validateZero();
    const assetsCopy = { ...assets };
    const newDataFormat = plainNumberString(data);
    const newData = { ...assetsCopy, ...newDataFormat };

    dispatch(
      setEntityData({
        ...registerData,
        assets: newData,
        totalCurrentAssets: totalAmount,
        financialStep: 4,
      }),
    );
  };
  const returnPreviousStep = () => {
    dispatch(setEntityData({ ...registerData, financialStep: 2 }));
  };

  const onHandler = () => {
    const formValues = getValues();
    parseTotalAmount(formValues, setTotalAmount);
  };

  useEffect(() => {
    const formValues = getValues();
    setTotalAmount(totalCurrentAssets);
    parseTotalAmount(formValues, setTotalAmount);
  }, []);

  const validateZero = () => {
    const formValues = getValues();
    const newvalue = Object.entries(formValues).map((item: any) => {
      if (Number(item[1]) <= 0 || item[1] == '0,00') return setValue(item[0], String(0));
    });
  };

  const values = getValues();

  // UseEnterPress(() => onSubmit(values));

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            fontWeight={'600'}
            marginTop={'1rem'}
            color={palette.black}>
            Activos corrientes
          </Typography>

          <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Efectivo Moneda Nacional'
                {...register('national_currency')}
                control={control}
                placeholder={'0.00'}
                handlerAmount={() => {
                  onHandler();
                }}
                helperText={
                  errors?.national_currency?.message
                    ? errors?.national_currency?.message
                    : 'Registre los saldos de las exitencias de billetes en moneda nacional.'
                }
                error={!!errors?.national_currency}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Efectivo Moneda Extranjera'
                {...register('foreign_currency')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.foreign_currency?.message
                    ? errors?.foreign_currency.message
                    : 'Registre el contravalor en bolívares de las exitencias de billetes de moneda extranjera.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.foreign_currency}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Bancos e Instituciones Financieras'
                {...register('banks')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.banks?.message
                    ? errors?.banks.message
                    : 'Registre los saldos disponibles en las instituciones bancaria'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.banks}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} display={'flex'} sx={styles.boxForm} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Cartera de Préstamos'
                {...register('load_portfolio_asset')}
                helperText={
                  errors?.load_portfolio_asset?.message
                    ? errors?.load_portfolio_asset.message
                    : 'Comprende el saldo total del capital de préstamo que la Caja de Ahorro otorga a sus asociados.'
                }
                handlerAmount={() => onHandler()}
                control={control}
                placeholder={'0.00'}
                error={!!errors?.load_portfolio_asset}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Cartera de Inversiones'
                {...register('investment_portfolio_asset')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.investment_portfolio_asset?.message
                    ? errors?.investment_portfolio_asset.message
                    : 'En este ítems la Caja de Ahorro registrará el saldo total de las colocaciones en inversiones.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.investment_portfolio_asset}
                ref={inputRef.current}
              />
            </Grid>

            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>
      </Grid>

      <Box sx={{ wdith: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography sx={styles.amount} marginTop={'2rem'} fontWeight='600' fontSize={'1.25rem'}>
          Total: <span style={{ fontWeight: '400' }}>{parseAmount(totalAmount)}</span>
        </Typography>
      </Box>

      <Box
        display={'flex'}
        sx={styles.buttonContainer}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Box sx={styles.amounContainer}>
            <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
              Continuar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
