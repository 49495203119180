import { Box, Typography, LinearProgress } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { MuiStepper, Navbar } from 'components';
import { useSelector } from 'react-redux';
import { Incomes, Expenses, CurrentAssets, NotCurrentAssets, Liability, Capital } from '../Financial/components';
import { registerSelector } from 'store/selectors';

export const FinancialRegistration = () => {
  const { financialStep, getFinancialLoading } = useSelector(registerSelector);

  const returnFromStepComponent = (step: number) => {
    switch (step) {
      case 1:
        return <Incomes />;
      case 2:
        return <Expenses />;
      case 3:
        return <CurrentAssets />;
      case 4:
        return <NotCurrentAssets />;
      case 5:
        return <Liability />;
      case 6:
        return <Capital />;
      default:
        return <Incomes />;
    }
  };

  return (
    <>
      {getFinancialLoading && <LinearProgress variant='indeterminate' sx={{ zIndex: 9999 }} />}
      <Navbar />
      <Box
        sx={styles.main}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        bgcolor={palette.light}>
        <Box width={'90%'} display={'flex'} justifyContent={'center'}>
          <MuiStepper totalSteps={7} actualStep={financialStep} />
        </Box>

        <Box
          display={'flex'}
          sx={{ overflowY: 'scroll' }}
          flexDirection={'column'}
          justifyContent={'space-between'}
          width={'90%'}
          height={'90%'}
          borderRadius={'0.938rem'}
          padding={'2rem 2rem'}
          bgcolor={palette.white}>
          <Box>
            <Typography textAlign={'left'} fontWeight={'600'} color={palette.black} sx={styles.title}>
              Proceso de Registro Financiero
            </Typography>
          </Box>
          {returnFromStepComponent(financialStep)}
        </Box>
      </Box>
    </>
  );
};
