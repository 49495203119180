import * as Yup from 'yup';
import { IdRegex } from 'utils';

export const schema = Yup.object().shape({
  user: Yup.string()
    .required('Campo requerido')
    .min(7, `Este campo debe poseer un minimo de ${7} caracteres `)
    .max(7, `Este campo no debe tener más de ${7} caracteres`)
    .matches(IdRegex, 'el ID debe poseer el siguiente formato PM-0000'),
});
