import { palette } from 'theme/contants';
import { TextField } from 'components';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useEffect, useRef } from 'react';
import { styles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schema } from './validate';
import { useNavigate } from 'react-router-dom';
import { LoginNavBar } from 'pages/Login/components';
import { UseIntermitence } from 'hooks';
import { useMediaQuery, Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { restorePassword } from 'store/actions';
import { useDispatch } from 'react-redux';

export const RestorePassword = () => {
  const { search } = useLocation();
  const token = search.split('=');
  const inputRef = useRef(null);
  const navigation = useNavigate();
  const { status, switchStatus } = UseIntermitence();
  const { status: confirm, switchStatus: switchConfirm } = UseIntermitence();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      token: '',
      password: '',
      confirm_password: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('token', token[1]);
  }, []);

  const onSubmit = (data: any) => {
    delete data?.confirm_password;
    dispatch(restorePassword(data, navigation));
  };

  return (
    <>
      <LoginNavBar />
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        marginTop={!isMobile ? '5rem' : 'calc(100% - 80%)'}
        bgcolor={palette?.light}
        height={!isMobile ? '100vh' : '97vh'}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          borderRadius={'17px'}
          width={!isMobile ? '37.75rem' : '90%'}
          height={!isMobile ? '35.813rem' : '70%'}
          bgcolor={palette?.white}>
          <Box
            margin={'1rem 0'}
            flexDirection={'column'}
            display={'flex'}
            justifyContent={'space-between'}
            height={'70%'}
            width={'80%'}
            alignContent={'center'}>
            <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} alignContent={'center'}>
              <Typography fontSize={'1rem'} fontWeight={'700'}>
                Recuperar contraseña
              </Typography>
              <Typography align='left' fontSize={'0.875rem'} marginTop={'2rem'} marginBottom={'1rem'}>
                Ingresa tu nueva contraseña
              </Typography>

              <Grid item sx={styles.input} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Contraseña'
                  {...register('password')}
                  placeholder='Texto'
                  id='password'
                  type={!status ? 'password' : 'text'}
                  icon={status ? 'mdi:eye-off-outline' : 'ic:baseline-remove-red-eye'}
                  helperText={errors?.password?.message}
                  name='password'
                  iconMethod={switchStatus}
                  control={control}
                  error={!!errors?.password}
                  ref={inputRef.current}
                />
              </Grid>
              <Grid item sx={styles.input} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Confirmar contraseña'
                  {...register('confirm_password')}
                  placeholder='Texto'
                  type={!confirm ? 'password' : 'text'}
                  iconMethod={switchConfirm}
                  icon={confirm ? 'mdi:eye-off-outline' : 'ic:baseline-remove-red-eye'}
                  control={control}
                  helperText={errors?.confirm_password?.message ? errors?.confirm_password.message : ''}
                  error={!!errors?.confirm_password}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
            <Box sx={styles.buttonContainer}>
              <Button type='submit' onClick={handleSubmit(onSubmit)} sx={styles.button} variant='contained'>
                Recuperar
              </Button>
              <Typography
                onClick={() => navigation('/')}
                sx={{ cursor: 'pointer' }}
                fontSize={'0.875rem'}
                marginTop={'1rem'}>
                Volver a inicio de sesión
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
