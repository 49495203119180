import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  formText: (theme: Theme) => ({
    fontSize: '1.25rem',
    marginBottom: '1.5rem',

    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      marginBottom: '1rem',
    },
  }),
};
