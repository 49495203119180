import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService, showDialog } from '../../utils';
import {
  GET_MUNICIPALITY_ASYNC,
  GET_STATES,
  GET_STATES_ASYNC,
  SET_LOADING,
  GET_MUNICIPALITY,
  GET_PARISHES,
  GET_PARISHES_ASYNC,
  GET_REPORT_DATA_ASYNC,
  GET_REPORT_DATA,
  GET_EMPLOYERS_ASYNC,
  GET_EMPLOYERS,
  EXPORT_REPORT_DATA_ASYNC,
  EXPORT_REPORT_DATA,
} from './action-types';

import { authSelector } from 'store/selectors';
import { GET_HISTORICAL_ASYNC } from 'store/monthlyReport/action-types';

function* getStatesAsync() {
  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(FetchService, `/location/states`, 'GET', null, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));
      yield put(actionObject(GET_STATES_ASYNC, { states: data }));
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getMunicipalitiesAsync({ payload }: any) {
  const { states } = payload;

  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/location/municipalities?` + (states ? `&states=${states}` : []),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));
      yield put(actionObject(GET_MUNICIPALITY_ASYNC, { municipalities: data }));
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getParishesAsync({ payload }: any) {
  const { states, municipalities } = payload;

  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/location/parishes?` +
        (states ? `&states=${states}` : []) +
        (municipalities ? `&municipalities=${municipalities}` : []),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));
      yield put(actionObject(GET_PARISHES_ASYNC, { parishes: data }));
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getReportsDataAsync({ payload }: any) {
  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/saving-bank/admin-dashboard?` +
        (payload?.states ? `&state=${payload?.states}` : []) +
        (payload?.municipality ? `&municipality=${payload?.municipality}` : []) +
        (payload?.parish ? `&parish=${payload?.parish}` : []) +
        (payload?.sector ? `&sector=${payload?.sector}` : []) +
        (payload?.employer ? `&employer=${payload?.employer}` : []) +
        (payload?.protocol ? `&protocolization=${payload?.protocol}` : []) +
        (payload?.status ? `&status=${payload?.status}` : []) +
        (payload?.startDate ? `&startDate=${payload?.startDate}` : '') +
        (payload?.endDate ? `&endDate=${payload?.endDate}` : ''),

      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));
      yield put(actionObject(GET_REPORT_DATA_ASYNC, { reportResult: data, filterParams: payload }));
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getEmployersAsync() {
  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(FetchService, `/saving-bank/employers`, 'GET', null, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(SET_LOADING, false));
      yield put(actionObject(GET_EMPLOYERS_ASYNC, { employers: data }));
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* exportReportsDataAsync({ payload, callback }: any) {
  try {
    yield put(actionObject(SET_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(
      FetchService,
      `/saving-bank/admin-dashboard/export?` +
        (payload?.states ? `&state=${payload?.states}` : []) +
        (payload?.municipality ? `&municipality=${payload?.municipality}` : []) +
        (payload?.parish ? `&parish=${payload?.parish}` : []) +
        (payload?.sector ? `&sector=${payload?.sector}` : []) +
        (payload?.employer ? `&employer=${payload?.employer}` : []) +
        (payload?.protocol ? `&protocolization=${payload?.protocol}` : []) +
        (payload?.status ? `&status=${payload?.status}` : []),
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      callback &&
        callback({
          ok: true,
          data: data,
        });
      yield put(actionObject(SET_LOADING, false));

      yield put(actionObject(EXPORT_REPORT_DATA_ASYNC, { filterParams: payload }));
      return;
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

export function* watchgGetStates() {
  yield takeLatest(GET_STATES, getStatesAsync);
}
export function* watchgGetMunicipalities() {
  yield takeLatest(GET_MUNICIPALITY, getMunicipalitiesAsync);
}
export function* watchgGetParishes() {
  yield takeLatest(GET_PARISHES, getParishesAsync);
}
export function* watchgGetReportData() {
  yield takeLatest(GET_REPORT_DATA, getReportsDataAsync);
}

export function* watchgGetEmployers() {
  yield takeLatest(GET_EMPLOYERS, getEmployersAsync);
}

export function* watchgExportReportData() {
  yield takeLatest(EXPORT_REPORT_DATA, exportReportsDataAsync);
}
