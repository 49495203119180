import * as Yup from 'yup';
import { onlyLettersRegex, onlyLettersNumbersAndSpace } from 'utils';

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),
  // .matches(onlyLettersRegex, 'Este campo no debe tener caracteres especiales'),

  description: Yup.string()
    .required('Campo requerido')
    .min(3, `Este campo debe tener al menos ${3} caracteres`)
    .max(255, `Este campo no debe tener mas de ${255} caracteres`),
  // .matches(onlyLettersNumbersAndSpace, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  permissions: Yup.array().min(1).notRequired(),
});
