export const palette = {
  primary: '#EEA71E',
  secondary: '#003566',
  light: '#F4F5FA',
  gray: '#BFBFBF',
  disabled: '#C2C2C2',
  disabledLight: '#E0E0E0',
  dark: '#010C41',
  warning: '#FFCD29',
  success: '#36BA34',
  error: '#DB4B41',
  white: '#FFFFFF',
  black: '#181818',
  darkGray: '#D9D9D9',
  whiteLilac: '#E1E5F3',
  blueHaze: '#C3C8DD',
  easternBlue: '#1494BC',
  funBlue: '#16549E',
  scorpion: '#5E5E5E',
  codGray: '#1E1E1E',
  tundora: '#474747',
  stratos: '#000C5E',
  steelBlue: '#3C75BA',
  linkWater: '#E9EEFA',
  boulder: '#7B7B7B',
};
