import { Box, Typography, LinearProgress } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { MuiStepper, Navbar } from 'components';
import { useSelector } from 'react-redux';
import { EntityData, Members, ProtocolData } from './components';
import { registerSelector } from '../../../../store/selectors';

export const LegalRegistration = () => {
  const { legalStep, getLegalLoading } = useSelector(registerSelector);

  const returnFromStepComponent = (step: number) => {
    switch (step) {
      case 1:
        return <EntityData />;
      case 2:
        return <ProtocolData />;
      case 3:
        return <Members type='adm' />;
      case 4:
        return <Members type='vig' />;
      default:
        return <EntityData />;
    }
  };

  return (
    <>
      {getLegalLoading && <LinearProgress variant='indeterminate' sx={{ zIndex: 9999 }} />}
      <Navbar />
      <Box
        sx={styles.main}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        bgcolor={palette.light}>
        <Box width={'90%'} display={'flex'} justifyContent={'center'}>
          <MuiStepper totalSteps={5} actualStep={legalStep} />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          width={'90%'}
          height={'90%'}
          borderRadius={'0.938rem'}
          padding={'2rem 2rem'}
          bgcolor={palette.white}>
          <Box>
            <Typography
              textAlign={'left'}
              fontWeight={'600'}
              variant={'h3'}
              marginBottom={legalStep >= 3 ? '1rem' : '3rem'}
              color={palette.black}
              sx={styles.title}>
              Proceso de Registro legal
            </Typography>
          </Box>
          {returnFromStepComponent(legalStep)}
        </Box>
      </Box>
    </>
  );
};
