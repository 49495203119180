import { theme } from 'theme';
import { palette } from 'theme/contants';

export const styles = {
  sectionTitle: {
    fontSize: '1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.875rem',
    },
    '&:hover': {
      color: palette.primary,
      cursor: 'pointer',
    },
  },
  sectionTitleDisabled: {
    fontSize: '1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.875rem',
    },
  },
};
