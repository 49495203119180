import * as Yup from 'yup';

export const schema = Yup.object().shape({
  personal: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  administrative: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  services_received: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
  depreciation: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),

  operating_expense: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
  estimates: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
  financial_expense: Yup.string().notRequired(),
  // .matches(
  //   amountCharactersRegex,
  //   'Este campo no debe tener caracteres numéricos ni caracteres especiales',
  // ),
});
