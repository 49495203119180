import { useEffect, useRef, useState } from 'react';
import { MuiModal, TextField, InputFileUpload, InputAmount } from 'components';
import { Box, Button, Grid, Typography, RadioGroup, Radio, FormControl, CircularProgress } from '@mui/material';
import { styles } from './styles';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { productsSelector } from 'store/selectors';
import { useMediaQuery, Theme } from '@mui/material';
import { sendProduct, updateProduct, uploadImage, deleteImage } from 'store/products/action';
import { parseAmount } from 'utils';

export const AddProductsModal = ({ open, onClose, type, productData, page }: any) => {
  const inputRef = useRef(null);
  const [currentType, setCurrentType] = useState(productData?.classification || 'Producto');

  const dispatch = useDispatch();
  const { loading } = useSelector(productsSelector);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [showFields, setShowFields] = useState({ showSecond: false, showThird: false });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      classification: productData?.classification ? productData.classification : 'Producto',
      name: productData?.name ? productData.name : '',
      availability: productData?.availability ? productData.availability : '',
      location: productData?.location ? productData?.location : '',
      phone: productData?.phone ? productData?.phone : '',
      association: productData?.association ? productData?.association : '',
      price: productData?.price ? parseAmount(productData?.price.toString()) : '',
      first_product_image: productData?.product_image?.[0] ? productData?.product_image?.[0]?.url : '',
      second_product_image: productData?.product_image?.[1] ? productData?.product_image?.[1]?.url : '',
      third_product_image: productData?.product_image?.[2] ? productData?.product_image?.[2]?.url : '',
      email: productData?.email ? productData?.email : '',
      description: productData?.description ? productData?.description : '',
      productStatus: 'Waiting',
    },
    resolver: yupResolver(schema),
  });

  const values: any = getValues();

  const onHandlerDocument = async (key: any, file: any) => {
    if (!file) {
      if (type == 'edit') dispatch(deleteImage({ url: values[key] }, page));
      setValue(key, '');
      return;
    }

    const { name } = file;
    const fileName = name?.substring(0, name?.lastIndexOf('.'));
    clearErrors(key);
    dispatch(uploadImage({ file, key: fileName }, setValue, key));
  };

  const onSubmit = (data: any) => {
    const { first_product_image, second_product_image, third_product_image, ...body } = data;
    const images = [first_product_image, second_product_image, third_product_image];

    const currentImages = handleImages(images);
    body['images'] = currentImages;
    body['availability'] = Number(body['availability']);
    const { productStatus, ...formData } = body;

    if (type == 'edit') return dispatch(updateProduct(productData?.id, body, onClose, page));
    dispatch(sendProduct(formData, onClose, page));
  };

  const handleImages = (images: Array<string>) => {
    const imagesGroup = images.map((image: string, index: number) => {
      if (type == 'edit' && image) return { url: image, id: productData?.product_image?.[index]?.id };
      if (image) return { url: image };
      return null;
    });

    return imagesGroup;
  };

  const handleRadio = (field: any, value: string) => {
    field.onChange(value);
    setCurrentType(value);
  };

  useEffect(() => {
    const productCurrentData = productData?.product_image;

    const subscription = watch((value) => {
      if (value?.second_product_image) return setShowFields((state: any) => ({ ...state, showThird: true }));
      if (value?.first_product_image) setShowFields((state: any) => ({ ...state, showSecond: true }));

      if (!value?.first_product_image) setShowFields((state: any) => ({ ...state, showSecond: false }));
      if (!value?.second_product_image) return setShowFields((state: any) => ({ ...state, showThird: false }));
    });

    if (productCurrentData?.length == 1) setShowFields((state: any) => ({ ...state, showSecond: true }));
    if (productCurrentData?.length == 2) return setShowFields({ showSecond: true, showThird: true });
    if (productCurrentData?.length == 3) return setShowFields({ showSecond: true, showThird: true });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <MuiModal
      width={'58.313rem'}
      title={type == 'create' ? 'Agregar producto o servicio nuevo' : 'Editar producto o servicio'}
      titleAlign={isMobile ? 'center' : 'left'}
      open={open}
      marginTop={isMobile ? '2rem' : '4rem'}
      onCancel={onClose}>
      <Box sx={styles.modalBody}>
        <Grid
          paddingRight={'0.5rem'}
          marginTop={!isMobile ? '2rem' : '0'}
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ overflowY: 'scroll' }}
          container
          spacing={0}>
          <Box width={'100%'} marginTop={'1rem'} display={'flex'} flexDirection={'column'}>
            <Controller
              control={control}
              name='classification'
              defaultValue={values?.classification}
              render={({ field }) => (
                <FormControl>
                  <RadioGroup {...field} onChange={(event, value) => handleRadio(field, value)} value={field.value}>
                    <Box display='flex' alignItems='center' marginBottom='2.5rem'>
                      <Box display='flex' alignItems='center'>
                        <Radio value='Producto' />
                        <Typography variant='h6'>Producto</Typography>
                      </Box>

                      <Box display='flex' alignItems='center' marginLeft='6rem'>
                        <Radio value='Servicio' />
                        <Typography variant='h6'>Servicio</Typography>
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              )}
            />

            <Box display={'flex'} sx={styles.inputContainer} justifyContent={'space-between'} alignContent={'center'}>
              <Grid
                item
                sx={currentType == 'Producto' ? styles.firstInput : null}
                xs={12}
                md={12}
                sm={12}
                lg={12}
                xl={currentType == 'Producto' ? 6 : 12}>
                <TextField
                  fullWidth
                  label='Nombre del producto o servicio'
                  {...register('name')}
                  placeholder='Producto o servicio'
                  control={control}
                  helperText={errors?.name?.message ? errors?.name.message : ''}
                  error={!!errors?.name}
                  ref={inputRef.current}
                />
              </Grid>
              {currentType == 'Producto' && (
                <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={6}>
                  <TextField
                    fullWidth
                    label='Disponibilidad'
                    {...register('availability')}
                    placeholder='Cantidad'
                    helperText={errors?.availability?.message ? errors?.availability.message : ''}
                    control={control}
                    error={!!errors?.availability}
                    ref={inputRef.current}
                  />
                </Grid>
              )}
            </Box>
          </Box>

          <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Ubicación de origen'
                  {...register('location')}
                  helperText={errors?.location?.message ? errors?.location.message : ''}
                  placeholder='Ciudad, parroquia y estado'
                  control={control}
                  error={!!errors?.location}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.secondInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Asociación que ofrece el producto'
                  {...register('association')}
                  placeholder='Producto o servicio'
                  control={control}
                  helperText={errors?.association?.message ? errors?.association.message : ''}
                  error={!!errors?.association}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} sm={12} md={12} lg={12} xl={6}>
                <InputAmount
                  fullWidth
                  label='Precio'
                  {...register('price')}
                  control={control}
                  placeholder='0'
                  handlerAmount={() => {}}
                  showPlaceholder={true}
                  leftPlaceHolder={'Cantidad'}
                  error={!!errors?.price}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Teléfono'
                  {...register('phone')}
                  placeholder='04141234567'
                  helperText={errors?.phone?.message ? errors?.phone.message : ''}
                  control={control}
                  error={!!errors?.phone}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} sx={styles.inputContainer} alignContent={'center'}>
              <Grid item sx={styles.firstInput} xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  fullWidth
                  label='Correo'
                  {...register('email')}
                  placeholder='@email.com'
                  control={control}
                  helperText={errors?.email?.message ? errors?.email.message : ''}
                  error={!!errors?.email}
                  ref={inputRef.current}
                />
              </Grid>
              <Grid item sx={styles.secondInput} xs={12} sm={12} md={12} lg={6} xl={6} />
            </Box>
          </Box>
          <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
              <Grid item display={'flex'} xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label='Descripción y características'
                  {...register('description')}
                  placeholder=''
                  control={control}
                  multiline
                  rows={4}
                  helperText={errors?.description?.message ? errors?.description.message : ''}
                  error={!!errors?.description}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
          </Box>
          <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} marginBottom={'2rem'} alignContent={'center'}>
              <Grid item display={'flex'} xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputFileUpload
                  {...register('first_product_image')}
                  fullWidth
                  control={control}
                  index={0}
                  acceptedFormats={'.jpg, .jpeg, .png'}
                  methodFile={(value: any) => onHandlerDocument('first_product_image', value)}
                  externalName={values?.first_product_image}
                  ref={inputRef.current}
                  error={!!errors?.first_product_image}
                  label='Primera imagen del producto'
                  buttonWidth={'5%'}
                  withDeleteIcon={type == 'edit' ? false : true}
                  loading={loading}
                />
              </Grid>
            </Box>

            {showFields?.showSecond && (
              <Box display={'flex'} justifyContent={'space-between'} marginBottom={'2rem'} alignContent={'center'}>
                <Grid item display={'flex'} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputFileUpload
                    {...register('second_product_image')}
                    fullWidth
                    control={control}
                    acceptedFormats={'.jpg, .jpeg, .png'}
                    index={1}
                    methodFile={(value: any) => onHandlerDocument('second_product_image', value)}
                    ref={inputRef.current}
                    error={!!errors?.second_product_image}
                    externalName={values?.second_product_image}
                    label='Segunda imagen del producto'
                    buttonWidth={'5%'}
                    withDeleteIcon={true}
                    loading={loading}
                  />
                </Grid>
              </Box>
            )}

            {showFields?.showThird && (
              <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                <Grid item display={'flex'} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputFileUpload
                    {...register('third_product_image')}
                    fullWidth
                    control={control}
                    acceptedFormats={'.jpg, .jpeg, .png'}
                    externalName={values?.third_product_image}
                    index={2}
                    methodFile={(value: any) => onHandlerDocument('third_product_image', value)}
                    ref={inputRef.current}
                    error={!!errors?.third_product_image}
                    label='Tercera imagen del producto'
                    buttonWidth={'5%'}
                    withDeleteIcon={true}
                    loading={loading}
                  />
                </Grid>
              </Box>
            )}
          </Box>
        </Grid>

        <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
          <Button onClick={() => onClose()} variant='outlined' type='submit' sx={styles.button}>
            Cancelar
          </Button>

          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            {loading ? <CircularProgress color='inherit' size={28} /> : type == 'create' ? 'Agregar' : 'Guardar'}
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};
