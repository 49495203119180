export const LOGIN = 'LOGIN';
export const LOGIN_ASYNC = 'LOGIN_ASYNC';
export const PRE_REGISTER = 'PRE_REGISTER';
export const PRE_REGISTER_ASYNC = 'PRE_REGISTER_ASYNC';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ASYNC = 'FORGOT_PASSWORD_ASYNC';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const RESTORE_PASSWORD_ASYNC = 'RESTORE_PASSWORD_ASYNC';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_ASYNC = 'USER_LOGIN_ASYNC';
