import { useRef, useEffect, useState } from 'react';
import { Box, Typography, InputAdornment } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { AutocompleteSelect, ClientsTable, TextField } from 'components';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { clientsSelector } from 'store/selectors';
import { UsePagination } from 'hooks';
import { getClients, searchClients, setFilters } from 'store/actions';
import { protocolStatus, savingStatus, verifyStatus } from 'utils';
import { useLocation, useNavigate } from 'react-router-dom';

export const ClientsList = () => {
  const inputRef = useRef(null);
  const clients = useSelector(clientsSelector);
  const [search, setSearch]: any = useState(null);
  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const params = useLocation();
  const navigate = useNavigate();

  const {
    clientsData: { data, meta },
    filters,
  } = clients;
  const totalPages = meta?.pageCount || 1;

  const { nextPage, previousPage, page, customPage } = UsePagination(meta?.page, totalPages, getClients, filters);

  const { register, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
      status: null,
      // protocol: null,
      isVerified: null,
    },
  });

  const headers: string[] = [
    'Nombre',
    'Id de caja',
    'Verificación',
    'Estado de vigencia',
    'Monto de ingreso en bs',
    '',
    '',
  ];

  const handleSearch = async ({ target }: any) => {
    const { value } = target;
    setSearch(value);
  };

  const handleSelect = (value: any, type: any) => {
    setValue(type, value);

    let typeName = type == 'status' ? 'currentStatus' : 'isVerified';
    const payload: any = {
      ...filters,
      [typeName]: type == 'status' ? value?.id : value?.value,
    };

    dispatch(setFilters(payload));
  };

  const filterClients = (params: any) => {
    const findedFilter: any = (params?.state?.type == 'status' ? savingStatus : protocolStatus).find(
      (status) => status.id === params?.state?.filter,
    );

    setValue(params?.state?.type, findedFilter);

    if (params?.state?.type == 'status') {
      return dispatch(setFilters({ currentStatus: findedFilter?.id }));
    }

    // dispatch(setFilters({ protocol: findedFilter?.id }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search == null) return filterClients(params);
      dispatch(
        searchClients(search, customPage, filters?.currentStatus, filters?.protocolization, filters?.isVerified),
      );
    }, 600);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (search == null) {
      dispatch(
        getClients({
          page: page,
          currentStatus: filters?.currentStatus,
          // protocolization: filters?.protocolization,
          isVerified: filters?.isVerified,
        }),
      );
    } else {
      dispatch(
        searchClients(search, customPage, filters?.currentStatus, filters?.protocolization, filters?.isVerified),
      );
    }
  }, [filters]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate('/clients-list', { replace: true });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center' bgcolor={palette.light}>
      <Box
        width='100%'
        height={'calc(100vh - 5rem)'}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='center'
        position='relative'>
        <Box
          display={'flex'}
          margin={'4.5rem 1% 1rem 1%'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          width={'98%'}>
          <Typography fontSize={16} fontWeight={'600'} marginRight={'1rem'} color={palette?.dark}>
            Cajas de ahorro
          </Typography>
          <TextField
            label='Buscar'
            {...register('search', {
              onChange: handleSearch,
            })}
            placeholder='Nombre o ID de caja de ahorro'
            type='text'
            name='search'
            control={control}
            id='search'
            InputProps={{
              style: { width: '20rem' },
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon icon='heroicons:magnifying-glass-solid' width={18} color={palette.black} />
                </InputAdornment>
              ),
            }}
            ref={inputRef.current}
          />
          <Box width={'20rem'} marginLeft={'1rem'}>
            <AutocompleteSelect
              {...register('status')}
              options={savingStatus}
              placeholder={'Seleccionar'}
              label={'Estado de vigencia'}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.gray} !important`,
                  },
                  padding: '4px 14px 4px 4px',
                },
              }}
              multiple={false}
              control={control}
              handleSelection={(value: any) => {
                handleSelect(value, 'status');
              }}
              ref={selectRef.current}
              limitTags={1}
            />
          </Box>
          <Box width={'20rem'} marginLeft={'1rem'}>
            <AutocompleteSelect
              {...register('isVerified')}
              options={verifyStatus}
              placeholder={'Seleccionar'}
              label={'Estado de verificación'}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '15px',
                    border: `1px solid ${palette.gray} !important`,
                  },
                  padding: '4px 14px 4px 4px',
                },
              }}
              multiple={false}
              control={control}
              handleSelection={(value: any) => {
                handleSelect(value, 'isVerified');
              }}
              ref={selectRef.current}
              limitTags={1}
            />
          </Box>
        </Box>

        <ClientsTable
          headers={headers}
          rowsData={data}
          title={`Número de cajas en el sistema (${meta?.itemCount ?? 0})`}
          page={page}
          nextPage={nextPage}
          previousPage={previousPage}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};
