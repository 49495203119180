import { IconButton } from '@mui/material';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { DownloadType } from './types';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { getS3Url } from 'store/intermitence/action';

export const DownloadButton: FC<DownloadType> = ({ fileName = null, width = 16 }: any) => {
  const dispatch = useDispatch();

  const getUrl = () => {
    dispatch(
      getS3Url(fileName, ({ ok, values }) => {
        if (ok) {
          window.open(values?.url);
        }
      }),
    );
  };

  return (
    <>
      {fileName && (
        <IconButton disabled={!fileName ? true : false} onClick={() => getUrl()} sx={{ bgcolor: palette?.light }}>
          <Icon icon='fa6-solid:download' width={width} color={palette.black} />
        </IconButton>
      )}
    </>
  );
};
