import { Theme } from '@mui/material';

export const styles = {
  rowText: (theme: Theme) => ({
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '0.75rem',
  }),
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem 0',
      width: '100%',
    },
  }),
};
