import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService, showDialog } from '../../utils';
import {
  GET_USERS,
  GET_USERS_ASYNC,
  LOADING_USERS_GET,
  SEND_USER,
  UPDATE_USER,
  SEARCH_USER,
  SEARCH_USER_ASYNC,
  DELETE_USER,
} from './action-types';

import { authSelector } from 'store/selectors';

function* getUsersAsync({ payload }: any) {
  try {
    yield put(actionObject(LOADING_USERS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(FetchService, `/user?page=${payload}`, 'GET', null, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(LOADING_USERS_GET, false));
      yield put(actionObject(GET_USERS_ASYNC, data));
    }

    if (!data) {
      yield put(actionObject(LOADING_USERS_GET, false));

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(LOADING_USERS_GET, false));
    throw new Error('Ha ocurrido un error');
  }
}

function* deleteUserAsync({ payload }: any) {
  const { id, onClose, page } = payload;

  try {
    yield put(actionObject(LOADING_USERS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(FetchService, `/user/${id}`, 'DELETE', undefined, accessToken);

    if (data && statusCode == 200) {
      yield put(actionObject(LOADING_USERS_GET, false));
      onClose();
      yield call(getUsersAsync, { payload: page });
      yield call(showDialog, 'Eliminado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_USERS_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOADING_USERS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* sendUserAsync({ payload }: any) {
  const { body, onClose, page } = payload;

  try {
    yield put(actionObject(LOADING_USERS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, code } = yield call(FetchService, '/user', 'POST', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(LOADING_USERS_GET, false));
      onClose();
      yield call(getUsersAsync, { payload: page });
      yield call(showDialog, 'Creado exitosamente', 'success');
    }

    if (!data) {
      if (code && code == '23505') yield call(showDialog, 'El correo electrónico ya esta registrado', 'error');
      yield put(actionObject(LOADING_USERS_GET, false));
    }
  } catch (error) {
    yield put(actionObject(LOADING_USERS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* updateUserAsync({ payload }: any) {
  const { id, body, onClose, page } = payload;

  try {
    yield put(actionObject(LOADING_USERS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(FetchService, `/user/${id}`, 'PUT', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(LOADING_USERS_GET, false));
      onClose();
      yield call(getUsersAsync, { payload: page });
      yield call(showDialog, 'Editado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(LOADING_USERS_GET, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(LOADING_USERS_GET, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* searchUserAsync({ payload }: any) {
  const { name, customPage } = payload;

  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/user/search?page=1&criteria=${name}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      customPage(1);
      yield put(actionObject(SEARCH_USER_ASYNC, data));
    }

    if (!data) {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

export function* watchgGetUser() {
  yield takeLatest(GET_USERS, getUsersAsync);
}

export function* watchSendUser() {
  yield takeLatest(SEND_USER, sendUserAsync);
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUserAsync);
}

export function* watchSearchUser() {
  yield takeLatest(SEARCH_USER, searchUserAsync);
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUserAsync);
}
