export const SET_PRODUCTS_DATA = 'SET_PRODUCTS_DATA';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_ASYNC = 'GET_PRODUCTS_ASYNC';
export const SEND_PRODUCT = 'SEND_PRODUCT';
export const SEND_PRODUCT_ASYNC = 'SEND_PRODUCT_ASYNC';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_ASYNC = 'DELETE_PRODUCT_ASYNC';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_ASYNC = 'UPDATE_PRODUCT_ASYNC';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SEARCH_PRODUCT_ASYNC = 'SEARCH_PRODUCT_ASYNC';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_ASYNC = 'UPLOAD_IMAGE_ASYNC';
export const GET_SAVING_PRODUCTS = 'GET_SAVING_PRODUCTS';
export const GET_SAVING_PRODUCTS_ASYNC = 'GET_SAVING_PRODUCTS_ASYNC';
export const GET_PRODUCT_IMAGE_URL = 'GET_PRODUCT_IMAGE_URL';
export const GET_PRODUCT_IMAGE_URL_ASYNC = 'GET_PRODUCT_IMAGE_URL_ASYNC';
export const RESET_IMAGE = 'RESET_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_IMAGE_ASYNC = 'DELETE_IMAGE_ASYNC';
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS';
export const UPDATE_PRODUCT_STATUS_ASYNC = 'UPDATE_PRODUCT_STATUS_ASYNC';
