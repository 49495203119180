import { useState, FC } from 'react';
import { TextField as MUITextField, TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  DesktopDatePicker as MuiPickerDate,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  pickersDayClasses,
} from '@mui/x-date-pickers';

import { palette } from 'theme/contants';
import 'moment/locale/es';

interface IAditionalProps {
  name: string;
  control: any;
  error: boolean;
  ref: any;
  handleDatePicker: any;
  label: string;
  value: string;
  disableFuture?: boolean;
  disablePast?: boolean;
}
type Props = TextFieldProps | IAditionalProps;

export const InputDate: FC<Props> = ({
  name,
  control,
  error = false,
  ref,
  handleDatePicker,
  label,
  value,
  disableFuture = true,
  disablePast = false,
  ...props
}: any) => {
  const [date, setNewDate] = useState(new Date(value));

  const locale = 'es-ES';

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiPickerDate
            label={label}
            disableFuture={disableFuture}
            disablePast={disablePast}
            value={date}
            renderDay={renderWeekPickerDay}
            onChange={(date: any) => {
              handleDatePicker(date);
              setNewDate(date);
            }}
            PopperProps={{
              sx: {
                zIndex: 1308,
                '& .MuiPickersCalendarHeader-labelContainer': {
                  minHeight: '60px',
                  paddingTop: '10px',
                },
              },
            }}
            renderInput={(params: any) => (
              <MUITextField
                sx={{
                  width: '100%',
                }}
                variant='outlined'
                inputRef={ref}
                error={error}
                InputLabelProps={{
                  shrink: true,
                }}
                {...params}
                {...props}
                {...field}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const renderWeekPickerDay = (date: Date, selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: palette.primary,
        },
      }}
    />
  );
};
