export const SET_USERS_DATA = 'SET_USERS_DATA';
export const SET_USERS_DATA_ASYNC = 'SET_USERS_DATA_ASYNC';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ASYNC = 'GET_USERS_DATA_ASYNC';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ASYNC = 'DELETE_USER_ASYNC';
export const LOADING_USERS_GET = 'LOADING_USERS_GET';
export const SEND_USER = 'SEND_USER';
export const SEND_USER_ASYNC = 'SEND_USER_ASYNC';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ASYNC = 'UPDATE_USER_ASYNC';
export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_ASYNC = 'SEARCH_USER_ASYNC';
