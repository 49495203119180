import * as Yup from 'yup';
import { emailRegex, onlyLettersRegex, onlyLettersAndNumbers } from 'utils';

export const schema = (type: string) =>
  Yup.object().shape({
    type: Yup.string().default(type),

    firstname: Yup.string()
      .required('Campo requerido')
      .min(3, `Este campo debe tener al menos ${3} caracteres`)
      .max(255, `Este campo no debe tener mas de ${255} caracteres`)
      .matches(onlyLettersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

    lastname: Yup.string()
      .required('Campo requerido')
      .min(3, `Este campo debe tener al menos ${3} caracteres`)
      .max(255, `Este campo no debe tener mas de ${255} caracteres`)
      .matches(onlyLettersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

    email: Yup.string()
      .required('Campo requerido')
      .matches(emailRegex, 'Ingrese un formato válido, por ejemplo email@gmail.com'),

    role: Yup.string().required(),

    password: Yup.string().when('type', {
      is: 'create',
      then: Yup.string()
        .required('Campo requerido')
        .matches(onlyLettersAndNumbers, 'El campo debe contener solo letras y números')
        .min(8, `Este campo debe poseer un minimo de ${8} caracteres `)
        .max(255, `Este campo no debe tener más de ${255} caracteres`),
      otherwise: Yup.string().notRequired(),
    }),
    confirmPassword: Yup.string().when('type', {
      is: 'create',
      then: Yup.string()
        .required('Campo requerido')
        .matches(onlyLettersAndNumbers, 'El campo debe contener solo letras y números')
        .oneOf([Yup.ref('password'), null], 'La contraseña no coincide.'),
      otherwise: Yup.string().notRequired(),
    }),
  });
