import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    marginTop: '5rem',
    backgroundColor: palette.white,
    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(100% - 80%);',
    },
  }),
  tableButton: (theme: Theme) => ({
    height: '1.813rem',
    width: '9.75rem',
    borderRadius: '0.625rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '60px',
      borderRadius: '50%',
      fontSize: '2.5rem',
      padding: 0,
    },
  }),
};
