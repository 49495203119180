import { useEffect } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { Navbar, SelectorBox } from 'components';
import { useMediaQuery, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { registerSelector } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getFinancialData, getLegalData } from 'store/actions';
import { StatusEnum } from '../../utils/constants';

export const Register = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const registerData = useSelector(registerSelector);
  const { legalStatus, financialStatus, getFinancialLoading } = registerData;

  const setFinancial = (financialStatus: string) => {
    if (financialStatus == StatusEnum?.WAITING)
      return { color: palette.primary, text: 'Datos cargados', icon: 'fa6-solid:clock' };
    if (financialStatus == StatusEnum?.REJECTED)
      return { color: palette.error, text: 'Error en datos', icon: 'fa6-solid:circle-xmark' };
    if (financialStatus == StatusEnum?.APPROVED)
      return { color: palette.success, text: 'Aprobado', icon: 'fa-solid:check-circle' };
  };

  const setLegal = (legalStatus: string) => {
    if (legalStatus == StatusEnum?.WAITING)
      return { color: palette.primary, text: 'Datos cargados', icon: 'fa6-solid:clock' };
    if (legalStatus == StatusEnum?.REJECTED)
      return { color: palette.error, text: 'Error en datos', icon: 'fa6-solid:circle-xmark' };
    if (legalStatus == StatusEnum?.APPROVED)
      return { color: palette.success, text: 'Aprobado', icon: 'fa-solid:check-circle' };
  };

  useEffect(() => {
    dispatch(getLegalData());
    dispatch(getFinancialData());
  }, []);

  return (
    <>
      {getFinancialLoading && <LinearProgress variant='indeterminate' sx={{ zIndex: 9999 }} />}
      <Navbar />

      <Box
        sx={styles.selectTypeMain}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        bgcolor={palette.light}>
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          flexDirection={'column'}
          width={'90%'}
          bgcolor={palette.white}
          borderRadius={'0.938rem'}
          padding={'2rem 2rem'}>
          <Typography
            variant='h2'
            textAlign={'center'}
            color={palette.black}
            fontWeight={'600'}
            sx={styles.selectTypeTitle}>
            Proceso de registro de Cajas de Ahorro
          </Typography>
          <Typography
            textAlign={'center'}
            variant='subtitle2'
            color={palette.black}
            marginTop={'2rem'}
            sx={styles.selectTypeSubtitle}>
            Selecciona el tipo de registro
          </Typography>
          <Box
            display={'flex'}
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <SelectorBox
              method={() => navigation('/legal-register')}
              text='Registro Legal'
              icon={'fa-solid:gavel'}
              iconSize={125}
              status={setLegal(legalStatus)}
            />

            <SelectorBox
              text='Registro Financiero'
              method={() => navigation('/financial-register')}
              icon={'fa6-solid:business-time'}
              iconSize={140}
              status={setFinancial(financialStatus)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
