import { palette } from 'theme/contants';
import { Navbar, TextField } from 'components';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useRef } from 'react';
import { styles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schema } from './validate';
import { useNavigate } from 'react-router-dom';
import { UseIntermitence } from 'hooks';
import { userlogin } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useMediaQuery, Theme } from '@mui/material';

export const AdminLogin = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { status, switchStatus } = UseIntermitence();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    data['email'] = data['email'].toLowerCase();
    dispatch(userlogin(data, navigation));
  }

  return (
    <>
      <Navbar />
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        marginTop={!isMobile ? '5rem' : 'calc(100% - 80%)'}
        bgcolor={palette?.light}
        height={!isMobile ? '100vh' : '85vh'}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          borderRadius={'17px'}
          width={!isMobile ? '37.75rem' : '90%'}
          height={!isMobile ? '35.813rem' : '70%'}
          bgcolor={palette?.white}>
          <Box
            margin={'1rem 0'}
            flexDirection={'column'}
            display={'flex'}
            justifyContent={'space-between'}
            height={'70%'}
            width={'80%'}
            alignContent={'center'}>
            <Typography fontSize={'1rem'} fontWeight={'700'}>
              Iniciar sesión a la plataforma administrativa
            </Typography>

            <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} alignContent={'center'}>
              <Grid item sx={styles.input} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Correo'
                  {...register('email')}
                  placeholder='Texto'
                  id='email'
                  helperText={errors?.email?.message}
                  name='email'
                  control={control}
                  error={!!errors?.email}
                  ref={inputRef.current}
                />
              </Grid>

              <Grid item sx={styles.input} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Contraseña'
                  {...register('password')}
                  placeholder='Texto'
                  type={!status ? 'password' : 'text'}
                  icon={status ? 'mdi:eye-off-outline' : 'ic:baseline-remove-red-eye'}
                  control={control}
                  helperText={errors.password?.message}
                  iconMethod={switchStatus}
                  error={!!errors?.password}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
            <Box sx={styles.buttonContainer}>
              <Button type='submit' onClick={handleSubmit(onSubmit)} sx={styles.button} variant='contained'>
                Iniciar sesión
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
