import { SET_USERS_DATA, GET_USERS, DELETE_USER, SEND_USER, UPDATE_USER, SEARCH_USER } from './action-types';
import { actionObject } from '../../utils';

export const setUsersData = (data: any) => actionObject(SET_USERS_DATA, data);
export const searchUser = (name: string, customPage: any) => actionObject(SEARCH_USER, { name, customPage });
export const getUsers = (page: number) => actionObject(GET_USERS, page);
export const deleteUser = (id: string, onClose: any, page: number) => actionObject(DELETE_USER, { id, onClose, page });
export const sendUser = (body: any, onClose: any, page: number) => actionObject(SEND_USER, { body, onClose, page });
export const updateUser = (id: string, body: any, onClose: any, page: number) =>
  actionObject(UPDATE_USER, { id, body, onClose, page });
