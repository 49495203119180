import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Box,
  Tooltip,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { palette } from 'theme/contants';
import { styles } from '../../styles';
import { DownloadButton } from 'components';
import { registerSelector } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getPositionAdm, getPositionWatch } from 'store/actions';

export const MemberSection: FC<any> = ({ headers, type, rows = [] }: any) => {
  const registerData = useSelector(registerSelector);
  const { positionsAdm, positionsWatch } = registerData;
  const dispatch = useDispatch();

  // const getPositionName = (id: string) => {

  //   if (type == 'adm') {
  //     const index = [...positionsAdm]?.findIndex((item: any) => {
  //       return item?.id === id;
  //     });
  //     return positionsAdm[index]?.name;
  //   }

  //   const index = [...positionsWatch]?.findIndex((item: any) => {
  //     return item?.id === id;
  //   });
  //   return positionsWatch[index]?.name;
  // };

  useEffect(() => {
    dispatch(getPositionAdm());

    dispatch(getPositionWatch());
  }, []);

  return (
    <Table
      sx={{
        bgcolor: palette.white,
        borderRadius: '0.625rem',
        width: '100%',
        marginLeft: '1%',
        marginRight: '1%',
        boxShadow: 2,
      }}
      aria-label='simple table'>
      <TableHead>
        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              paddingTop: '1rem',
              paddingBottom: '1rem',
              borderBottom: `1px solid ${palette.black}`,
            },
          }}>
          {headers?.map((item: string, index: number | string) => (
            <TableCell width={`${100 / headers?.length}%`} key={index}>
              <Typography fontSize={'0.75rem'} fontWeight='700'>
                {item}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {rows?.length > 0 ? (
          <>
            {rows?.map((row: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  <Tooltip title={row?.fullname ?? ''} placement={'top'}>
                    <Typography variant='button' sx={styles.rowText}>
                      {row?.fullname}{' '}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell component='th' scope='row'>
                  <Typography variant='button' sx={styles.rowText}>
                    {row?.dni?.toUpperCase()}{' '}
                  </Typography>
                </TableCell>
                <TableCell component='th' scope='row'>
                  <Typography variant='button' sx={styles.rowText}>
                    {row?.phone}{' '}
                  </Typography>
                </TableCell>

                <TableCell component='th' scope='row'>
                  <Tooltip title={row?.email ?? ''} placement={'top'}>
                    <Typography variant='button' sx={styles.rowText}>
                      {row?.email}{' '}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell component='th' scope='row'>
                  <Tooltip title={row?.positionName ?? ''} placement={'top'}>
                    <Typography variant='button' sx={styles.rowText}>
                      {row?.positionName}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell component='th' scope='row'>
                  {type == 'adm' && (
                    <Box display={'flex'}>
                      <Typography variant='button' sx={styles.fileText}>
                        {row?.path_affidavit}
                      </Typography>
                      {row?.path_affidavit && <DownloadButton fileName={row?.path_affidavit} width={18} />}
                    </Box>
                  )}
                </TableCell>

                <TableCell component='th' scope='row'>
                  {type == 'adm' && (
                    <Box display={'flex'}>
                      <Typography variant='button' sx={styles.fileText}>
                        {row?.path_personal_balance}{' '}
                      </Typography>
                      {row?.path_personal_balance && (
                        <DownloadButton fileName={row?.path_personal_balance} width={18} />
                      )}
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow
            sx={{
              height: '1rem',

              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell align='center' colSpan={12}></TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
