import { Box, IconButton, Typography } from '@mui/material';
import { palette } from 'theme/contants';
import { DoughnutChart, MuiChips } from 'components';
import { Icon } from '@iconify/react';
import { StatusCardsTypes } from './types';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const StatusCards: FC<StatusCardsTypes> = ({ chartData, total }) => {
  const navigation = useNavigate();
  const icons = ['heroicons:folder-minus-solid', 'heroicons:folder-plus-solid', 'fa6-solid:folder-closed'];

  const validateStatus = (status: string) => {
    if (status == 'No Vigente') return 'Cajas no vigentes';
    if (status == 'En proceso de vigencia') return 'En proceso de vigencia';
    if (status == 'Vigente') return 'Cajas Vigentes';
  };

  const validateFilter = (status: string) => {
    if (status == 'No Vigente') return 'No Vigente';
    if (status == 'En proceso de vigencia') return 'En proceso de vigencia';
    if (status == 'Vigente') return 'Vigente';
  };

  return (
    <Box display={'flex'} width={'45%'} height={'100%'} marginTop={'1rem'}>
      <Box display={'flex'} width={'100%'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <Box
          width={'48%'}
          height={'11.063rem'}
          position={'relative'}
          display={'flex'}
          justifyContent={'center'}
          bgcolor={palette.light}
          borderRadius={'0.75rem'}
          padding={'1rem'}>
          <Box>
            <Typography
              position={'absolute'}
              sx={{ transform: 'translate(-50%, -50%)' }}
              top={'50%'}
              left={'50%'}
              width={'20%'}
              variant='subtitle1'>
              {`Total ${total ?? 0}`}
            </Typography>

            <DoughnutChart chartValues={chartData} cutout={58} />
          </Box>
        </Box>
        {chartData
          ?.map((item: any, index: number) => {
            return (
              <Box
                width={'48%'}
                key={index}
                height={'11.063rem'}
                position={'relative'}
                bgcolor={palette.light}
                borderRadius={'0.75rem'}
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                padding={'1rem'}>
                <IconButton
                  disableRipple
                  sx={{ background: item?.colors, padding: '0.6rem', cursor: 'inherit', marginTop: '0.2rem' }}>
                  <Icon icon={icons[index]} width={23} color={palette.white} />
                </IconButton>
                <Box
                  flexDirection={'column'}
                  height={'100%'}
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}>
                  <Box width={'100%'}>
                    <Typography textAlign={'left'} marginLeft={'0.5rem'} variant='subtitle1'>
                      {validateStatus(item?.labels)}
                    </Typography>
                    <Typography textAlign={'left'} marginLeft={'0.5rem'} variant='subtitle1'>
                      {item?.values} cajas
                    </Typography>
                  </Box>
                  <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                    <MuiChips
                      method={() =>
                        navigation('/clients-list', { state: { filter: validateFilter(item.labels), type: 'status' } })
                      }
                      isSelected={true}
                      text={'Ver todas'}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })
          .reverse()}
      </Box>
    </Box>
  );
};
