import { Chip, Box } from '@mui/material';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { ChipType } from './types';
import { FC } from 'react';

export const MuiChips: FC<ChipType> = ({ method, icon, text, iconRotate = false, isSelected = false }: any) => {
  return (
    <Chip
      onClick={method}
      sx={{
        backgroundColor: !isSelected ? 'transparent' : palette.blueHaze,
        color: palette.dark,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: '0.438rem',
        '&:hover, &:focus': {
          cursor: 'pointer',
          backgroundColor: palette.blueHaze,
        },
      }}
      icon={
        <>
          {icon && (
            <Box
              flexDirection={'row'}
              display={'flex'}
              marginLeft={'15px'}
              sx={{ transform: iconRotate ? 'rotate(180deg)' : '' }}>
              <Icon icon={icon} width={18} color={palette?.dark} />
            </Box>
          )}
        </>
      }
      label={text}
    />
  );
};
