import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import sagas from './sagas';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['intermitence', 'products', 'users', 'roles', 'clients', 'permissions', 'reports'],
  whitelist: ['auth', 'register'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();
const middlewareSaga = [sagaMiddleware];

const store: any = configureStore({
  reducer: persistedReducer,
  middleware: [...middlewareSaga],
});

sagaMiddleware.run(sagas);
const persistor = persistStore(store);
export { store, persistor };
