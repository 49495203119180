import { palette } from '../contants';

export const MuiAppBar = {
  styleOverrides: {
    root: {
      backgroundColor: palette.white,
      padding: '0.5rem 2rem',
      boxShadow: 'none',
      '@media only screen and (max-width: 900px)': {
        padding: '0.5rem 1rem',
      },
    },
  },
};
