export const SET_ROLES_DATA = 'SET_ROLES_DATA';
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_ASYNC = 'GET_ROLES_ASYNC';
export const LOADING_ROLES_GET = 'LOADING_ROLES_GET';
export const DELETE_ROL = 'DELETE_ROL';
export const DELETE_ROL_ASYNC = 'DELETE_ROL_ASYNC';
export const SEARCH_ROL = 'SEARCH_ROL';
export const SEARCH_ROL_ASYNC = 'SEARCH_ROL_ASYNC';
export const SEND_ROLE = 'SEND_ROLE';
export const SEND_ROLE_ASYNC = 'SEND_ROLE_ASYNC';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_ASYNC = 'UPDATE_ROLE_ASYNC';
