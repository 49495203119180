import {
  SHOW_TOAST,
  LOGIN_LOADING,
  PRE_REGISTER_LOADING,
  S3_LOADING,
  GET_URL_S3,
  GET_URL_S3_ASYNC,
  GET_EXPORTABLE_ASYNC,
} from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  toast: {
    text: '',
    type: 'success',
    show: false,
  },
  loginLoading: false,
  preregisterLoading: false,
  s3Loading: false,
  s3Url: '',
};

const intermitence = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case SHOW_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          ...payload,
        },
      };

    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: payload,
      };

    case PRE_REGISTER_LOADING:
      return {
        ...state,
        preregisterLoading: payload,
      };
    case GET_URL_S3_ASYNC:
      return {
        ...state,
        s3Url: payload,
      };
    case GET_EXPORTABLE_ASYNC:
      return {
        ...state,
        s3Url: payload,
      };
    case S3_LOADING:
      return {
        ...state,
        s3Loading: payload,
      };

    default:
      return state;
  }
};

export default intermitence;
