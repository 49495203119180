import { Box, Typography, Grid } from '@mui/material';
import { styles } from './styles';
import routes from '../routes';
import { palette } from 'theme/contants';
import { baseUrl } from 'paths';

const Footer = () => {
  const openLink = (link: string) => {
    if (!link) return null;
    window.open(link, '_blank');
  };
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      width={'100%'}
      padding={'3rem 2rem 1rem 2rem'}
      bgcolor={palette.dark}>
      <Box component={'img'} width={100} src={`${baseUrl}/resources/logo-footer.svg`} alt='logo' />
      <Box>
        <Grid container spacing={0}>
          <Grid item display={'flex'} xs={12}>
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'} marginTop={'1rem'}>
              {routes?.map((item: any, index: number) => {
                return (
                  <Box width={'16.6rem'} key={index}>
                    <Typography
                      color={!item?.link ? palette?.boulder : palette.white}
                      textAlign={'left'}
                      fontWeight={'700'}
                      margin={'1rem 0'}
                      onClick={() => openLink(item?.link)}
                      sx={!item?.link ? styles.sectionTitleDisabled : styles.sectionTitle}>
                      {item?.sectionName}
                    </Typography>
                    {item?.sections?.map((element: any, id: number) => {
                      return (
                        <Box key={id}>
                          <Typography
                            color={!element?.link ? palette?.boulder : palette.white}
                            textAlign={'left'}
                            margin={'1rem 0'}
                            onClick={() => openLink(element?.link)}
                            sx={!element?.link ? styles.sectionTitleDisabled : styles.sectionTitle}>
                            {element?.name}{' '}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box justifyContent={'space-between'} display={'flex'} alignItems={'flex-end'}>
        <Typography
          fontSize={'0.875rem'}
          color={palette.white}
          fontWeight={'700'}
          textAlign={'left'}
          marginTop={'2rem'}>
          © Mayo 2018 Creado por la Div. de Tecnología de la Superintendencia de Cajas de Ahorro (SUDECA).
        </Typography>
        <Box width={'12%'} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'}>
          <Box
            margin={'0 1rem'}
            component={'img'}
            width={26}
            height={21}
            src={`${process.env.PUBLIC_URL}/resources/twit.svg`}
            alt='twitter'
          />
          <Box
            margin={'0 1rem'}
            component={'img'}
            width={12}
            height={24}
            src={`${process.env.PUBLIC_URL}/resources/face.svg`}
            alt='face'
          />
          <Box
            margin={'0 0 0 1rem'}
            component={'img'}
            width={23}
            height={23}
            src={`${process.env.PUBLIC_URL}/resources/insta.svg`}
            alt='insta'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
