import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    marginTop: '5rem',
    backgroundColor: palette.white,
    [theme.breakpoints.down('md')]: {
      marginTop: 'calc(100% - 80%);',
    },
  }),

  formText: (theme: Theme) => ({
    fontSize: '1.25rem',
    fontWeight: '600',

    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  }),
  card: (theme: Theme) => ({
    animation: 'fadeIn 2s ease',
  }),
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#E1E5F3',
      padding: '0.75rem 1rem',
      fontSize: '14px',
      color: '#000',
      borderRadius: '5px',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiTooltip-arrow': {
      color: '#E1E5F3',
    },
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0.4,
    },

    '100%': {
      opacity: 1,
    },
  },
};
