import { useRef } from 'react';
import { InputFileUpload, InputDate } from 'components';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styles } from '../../../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setEntityData, uploadImage } from 'store/actions';
import { productsSelector, registerSelector } from 'store/selectors';
import { useMediaQuery, Theme } from '@mui/material';
import { UseEnterPress } from 'hooks';

export const ProtocolData = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const registerData = useSelector(registerSelector);
  const { protocolData } = registerData;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { loading } = useSelector(productsSelector);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date_swearing_certificate: protocolData?.date_swearing_certificate
        ? protocolData?.date_swearing_certificate
        : new Date(),
      elected_start_date: protocolData?.elected_start_date ? protocolData?.elected_start_date : new Date(),
      elected_end_date: protocolData?.elected_end_date ? protocolData?.elected_end_date : new Date(),
      path_swearing_certificate: protocolData?.path_swearing_certificate ? protocolData?.path_swearing_certificate : '',
      date_constitutive_act: protocolData?.date_constitutive_act ? protocolData?.date_constitutive_act : new Date(),
      path_constitutive_act: protocolData?.path_constitutive_act ? protocolData?.path_constitutive_act : '',
      date_proto_constitutive_act: protocolData?.date_proto_constitutive_act
        ? protocolData?.date_proto_constitutive_act
        : new Date(),
      date_statute: protocolData?.date_statute ? protocolData?.date_statute : new Date(),
      statute: protocolData?.statute ? protocolData?.statute : '',
    },
    resolver: yupResolver(schema),
  });

  const formValues: any = getValues();

  const onSubmit = (data: any) => {
    dispatch(setEntityData({ ...registerData, protocolData: data, legalStep: 3 }));
  };
  const returnPreviousStep = () => {
    dispatch(setEntityData({ ...registerData, legalStep: 1 }));
  };
  const handleDate = (key: any, date: any) => setValue(key, date);

  const onHandlerDocument = (key: any, file: any) => {
    if (!file) {
      setValue(key, '');
      return;
    }

    const { name } = file;
    const fileName = name?.substring(0, name?.lastIndexOf('.'));
    clearErrors(key);
    dispatch(uploadImage({ file, key: fileName }, setValue, key));
  };

  // UseEnterPress(() => onSubmit(formValues));

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography textAlign={'left'} sx={styles.secondFormText} fontWeight={'600'} color={palette.black}>
            Protocolización
          </Typography>

          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputDate
                {...register('date_swearing_certificate')}
                label='Fecha de protocolización del Acta de Juramentación'
                control={control}
                value={formValues?.date_swearing_certificate}
                helperText={
                  'la ultima acta de juramentación de los miembros del Consejo de Administración y Consejo de Vigilancia.'
                }
                handleDatePicker={(date: any) => handleDate('date_swearing_certificate', date)}
                error={!!errors?.date_swearing_certificate}
                ref={inputRef.current}
              />
            </Grid>

            <Grid
              item
              marginRight={!isMobile ? '1rem' : '0px'}
              marginLeft={!isMobile ? '1rem' : '0px'}
              sx={{ display: 'flex', flexDirection: 'column' }}
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={4}>
              <Box display={'flex'}>
                <Grid item marginRight={'0.5rem'} xs={12} md={12} sm={12} lg={12} xl={6}>
                  <InputDate
                    {...register('elected_start_date')}
                    label='Desde'
                    control={control}
                    value={formValues?.elected_start_date}
                    handleDatePicker={(value: any) => handleDate('elected_start_date', value)}
                    error={!!errors?.elected_start_date}
                    ref={inputRef.current}
                  />
                </Grid>
                <Grid item marginLeft={'0.5rem'} xs={12} md={12} sm={12} lg={12} xl={6}>
                  <InputDate
                    {...register('elected_end_date')}
                    label='Hasta'
                    placeholder='00/00/00'
                    disablePast={false}
                    disableFuture={false}
                    control={control}
                    value={formValues?.elected_end_date}
                    handleDatePicker={(value: any) => handleDate('elected_end_date', value)}
                    error={!!errors?.elected_end_date}
                    ref={inputRef.current}
                  />
                </Grid>
              </Box>
              <Typography lineHeight={'1.66'} color={'rgba(0, 0, 0, 0.6)'} marginTop={'3px'} fontSize={'0.625rem'}>
                Periodo para el cual resultaron electos. (Ej: 2022-2025)
              </Typography>
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <Box display={'flex'}>
                <InputFileUpload
                  {...register('path_swearing_certificate')}
                  fullWidth
                  acceptedFormats={'.pdf'}
                  index={0}
                  withDeleteIcon={true}
                  control={control}
                  externalName={formValues?.path_swearing_certificate}
                  helperText={
                    errors?.path_swearing_certificate?.message ? errors?.path_swearing_certificate?.message : ''
                  }
                  methodFile={(value: any) => onHandlerDocument('path_swearing_certificate', value)}
                  ref={inputRef.current}
                  error={!!errors?.path_swearing_certificate}
                  label='Adjuntar Acta de Juramentación'
                  loading={loading}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} margin={!isMobile ? '2rem 0' : '0'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputDate
                {...register('date_constitutive_act')}
                label='Fecha del Acta Constitutiva'
                placeholder='00/00/00'
                control={control}
                value={formValues?.date_constitutive_act}
                handleDatePicker={(value: any) => handleDate('date_constitutive_act', value)}
                error={!!errors?.date_constitutive_act}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputDate
                {...register('date_proto_constitutive_act')}
                label='Fecha de protocolización del Acta Constitutiva'
                placeholder='00/00/00'
                control={control}
                value={formValues?.date_proto_constitutive_act}
                handleDatePicker={(value: any) => handleDate('date_proto_constitutive_act', value)}
                error={!!errors?.date_proto_constitutive_act}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <InputFileUpload
                  {...register('path_constitutive_act')}
                  fullWidth
                  index={1}
                  withDeleteIcon={true}
                  acceptedFormats={'.pdf'}
                  externalName={formValues?.path_constitutive_act}
                  helperText={errors?.path_constitutive_act?.message ? errors?.path_constitutive_act?.message : ''}
                  control={control}
                  methodFile={(value: any) => onHandlerDocument('path_constitutive_act', value)}
                  ref={inputRef.current}
                  error={!!errors?.path_constitutive_act}
                  label='Adjuntar Acta Constitutiva'
                  loading={loading}
                />
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box width={'100%'} margin={!isMobile ? '2rem 0' : '0'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputDate
                {...register('date_statute')}
                label='Fecha de última Reforma Estatuaria'
                placeholder='00/00/00'
                control={control}
                value={formValues?.date_statute}
                handleDatePicker={(value: any) => handleDate('date_statute', value)}
                error={!!errors?.date_statute}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <Box display={'flex'}>
                <InputFileUpload
                  {...register('statute')}
                  fullWidth
                  index={2}
                  withDeleteIcon={true}
                  acceptedFormats={'.pdf'}
                  control={control}
                  helperText={errors?.statute?.message ? errors?.statute?.message : ''}
                  methodFile={(value: any) => onHandlerDocument('statute', value)}
                  ref={inputRef.current}
                  externalName={formValues?.statute}
                  error={!!errors?.statute}
                  label='Adjuntar Estatutos Vigentes'
                  loading={loading}
                />
              </Box>
            </Grid>

            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>
      </Grid>

      <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
        <Box sx={styles.buttonContainerLeft} marginTop={'1.5rem'}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight} marginTop={'1.5rem'}>
          <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
            Continuar
          </Button>
        </Box>
      </Box>
    </>
  );
};
