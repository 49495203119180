import {
  GET_SEGMENT,
  SAVE_MONTHLY_UPLOAD,
  GET_HISTORICAL,
  DOWNLOAD_FILE,
  GET_PREVIEW,
  CLEAR_SEGMENT,
  SEARCH_REPORT,
  GET_FINANCIAL_HISTORICAL,
  SEARCH_FINANCIAL_HISTORICAL,
  EXPORT_FINANCIAL_HISTORICAL,
} from './action-types';
import { actionObject } from '../../utils';

export const getSegments = (type: string, id?: string) => actionObject(GET_SEGMENT, { id, type });
export const saveMonthlyUpload = (data: any[], params: any) => actionObject(SAVE_MONTHLY_UPLOAD, { data, params });
export const getHistorical = (page: number) => actionObject(GET_HISTORICAL, page);
export const downloadFile = (filename: string) => actionObject(DOWNLOAD_FILE, { filename });
export const getPreview = (data: any[]) => actionObject(GET_PREVIEW, { data });
export const clearSegments = () => actionObject(CLEAR_SEGMENT);
export const searchReport = (name: string, customPage: any) => actionObject(SEARCH_REPORT, { name, customPage });

export const getFinancialHistorical = (filterParams: any) => actionObject(GET_FINANCIAL_HISTORICAL, filterParams);
export const searchFinancialHistorical = (name: string, filterParams: any) =>
  actionObject(SEARCH_FINANCIAL_HISTORICAL, { name, filterParams });

export const exportFinancialHistorical = (filterParams: any, callback?: ({ ok }: { ok: boolean; data: any }) => void) =>
  actionObject(EXPORT_FINANCIAL_HISTORICAL, filterParams, callback);
