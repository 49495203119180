import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  selectTypeMain: (theme: Theme) => ({
    height: '90vh',
    marginTop: '5rem',
    backgroundColor: palette?.light,
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingTop: '2rem',
      marginTop: '2rem',
      paddingBottom: '2rem',
    },
  }),
  main: (theme: Theme) => ({
    height: '90vh',
    backgroundColor: palette.light,
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingTop: '2rem',
      marginTop: '2rem',
      paddingBottom: '2rem',
    },
  }),
  selectTypeTitle: (theme: Theme) => ({
    width: '50%',
    [theme.breakpoints.down('xl')]: {
      width: '70%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
  }),
  selectTypeOptions: (theme: Theme) => ({
    width: '22rem',
    height: '18.1rem',
    margin: '2rem 2rem',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '15rem',
      margin: '1.5rem 0 1rem 0',
      height: '14.1rem',
    },
  }),
  selectTypeOptionText: (theme: Theme) => ({
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
  }),
  selectTypeButtonContainer: (theme: Theme) => ({
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  selectTypeButton: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  }),

  container: (theme: Theme) => ({
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  inputContainer: (theme: Theme) => ({
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }),
  firstInput: (theme: Theme) => ({
    margin: '0 1rem 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  secondInput: (theme: Theme) => ({
    margin: '0 1rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  containerMargin: (theme: Theme) => ({
    margin: '4rem 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  modalButtonContainer: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  buttonContainer: (theme: Theme) => ({
    flexDirection: 'row',
    marginTop: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginTop: '0',
    },
  }),
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem 0',
      width: '100%',
    },
  }),
};
