import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { palette } from 'theme/contants';

export const VerticalChart = ({ chartValues }: any) => {
  ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,

        callbacks: {
          title: function (context: any) {
            const label = chartValues[context[0].dataIndex]?.label;
            return label.charAt(0).toUpperCase() + label.slice(1);
          },
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawTicks: false,
        },
        border: {
          color: '#F9F9F9',
        },
      },
      y: {
        grid: {
          drawTicks: false,
        },
        border: {
          color: '#F9F9F9',
        },
      },
    },
  };

  const capitalizedLabels = chartValues?.map(
    (item: any) => item?.label.slice(0, 3).charAt(0).toUpperCase() + item?.label.slice(1, 3),
  );

  const data: any = {
    // labels: chartValues?.length
    //   ? chartValues.map((item: any) => item?.label.slice(0, 3).charAt(0).toUpperCase() + item?.label.slice(1, 3))
    //   : null,
    labels: chartValues?.length ? capitalizedLabels : null,
    datasets: [
      {
        data: chartValues?.length ? chartValues.map((item: any) => item?.value) : null,
        backgroundColor: palette?.funBlue,
      },
    ],
  };

  return <Bar data={data} options={options} />;
};
