import { SET_ROLES_DATA, GET_ROLES_ASYNC, LOADING_ROLES_GET, SEARCH_ROL_ASYNC } from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  rolesLoading: false,
  rolesData: [],
};

const roles = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case SET_ROLES_DATA:
      return { ...state, ...payload };
    case SEARCH_ROL_ASYNC:
      return { ...state, rolesData: payload };
    case GET_ROLES_ASYNC:
      return { ...state, rolesData: payload };
    case LOADING_ROLES_GET:
      return { ...state, rolesLoading: payload };

    default:
      return state;
  }
};

export default roles;
