import { useEffect, useRef, useState } from 'react';
import { Box, Typography, InputAdornment, Button } from '@mui/material';
import { styles } from '../styles';
import { palette } from 'theme/contants';
import { ProductsTable } from 'components';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { TextField } from 'components';
import { UseIntermitence, UsePagination } from 'hooks';
import { AddProductsModal } from './components';
import { productsSelector } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, searchProduct } from 'store/actions';

export const Producst = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { status, switchStatus } = UseIntermitence();

  const [search, setSearch] = useState('');
  const products = useSelector(productsSelector);
  const {
    productsData: { data, meta },
  } = products;
  const totalPages = meta?.pageCount || 1;

  const { nextPage, previousPage, page, customPage } = UsePagination(meta?.page, totalPages, getProducts);

  const {
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  const headers: string[] = [
    'Nombre',
    'Tipo',
    'Estado',
    'Disponibilidad',
    'Ubicación de origen',
    'Teléfono',
    'Asociación del producto',
    'Precio',
    '',
  ];

  useEffect(() => {
    dispatch(getProducts(1));
  }, []);

  const handleSearch = async ({ target }: any) => {
    const { value } = target;
    setSearch(value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(searchProduct(search, customPage));
    }, 600);

    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <>
      <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center'>
        <Box
          width='100%'
          height={'100%'}
          display='flex'
          justifyContent='flex-start'
          flexDirection='column'
          alignItems='center'
          position='relative'>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'98%'}
            margin={'4.5rem 1% 1rem 1%'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Typography fontSize={16} fontWeight={'600'} marginRight={'1rem'} color={palette?.dark}>
                Productos y servicios
              </Typography>
              <TextField
                label='Buscar'
                {...register('search', {
                  onChange: handleSearch,
                })}
                placeholder='Nombre del producto o servicio'
                type='text'
                name='search'
                control={control}
                id='search'
                InputProps={{
                  style: { width: '28rem' },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Icon icon='heroicons:magnifying-glass-solid' width={18} color={palette.black} />
                    </InputAdornment>
                  ),
                }}
                ref={inputRef.current}
              />
            </Box>

            <Box>
              <Button type='submit' onClick={() => switchStatus()} sx={styles.tableButton} variant='contained'>
                Agregar nuevo
              </Button>
            </Box>
          </Box>

          <ProductsTable
            headers={headers}
            rowsData={data}
            title={`Cantidad de productos (${meta?.itemCount ?? 0})`}
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
            totalPages={totalPages}
          />
        </Box>
      </Box>
      {status && <AddProductsModal open={status} onClose={switchStatus} type={'create'} page={page} />}
    </>
  );
};
