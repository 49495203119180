import {
  GET_PRODUCTS_ASYNC,
  SET_PRODUCTS_DATA,
  PRODUCT_LOADING,
  SEARCH_PRODUCT_ASYNC,
  GET_PRODUCT_IMAGE_URL_ASYNC,
  RESET_IMAGE,
  UPDATE_PRODUCT_STATUS_ASYNC,
} from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  productsData: [],
  loading: false,
  currentImage: '',
};

const products = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case SET_PRODUCTS_DATA:
      return { ...state, ...payload };

    case GET_PRODUCTS_ASYNC:
      return { ...state, productsData: payload };

    case SEARCH_PRODUCT_ASYNC:
      return { ...state, productsData: payload };

    case GET_PRODUCT_IMAGE_URL_ASYNC:
      return { ...state, currentImage: payload };

    case RESET_IMAGE:
      return { ...state, currentImage: payload };

    case PRODUCT_LOADING:
      return { ...state, loading: payload };
    case UPDATE_PRODUCT_STATUS_ASYNC:
      return { ...state, payload };

    default:
      return state;
  }
};

export default products;
