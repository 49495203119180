import { Modal, Box, Typography } from '@mui/material';
import { ModalType } from './types';
import { FC, useState, useEffect } from 'react';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { useLocation } from 'react-router-dom';

export const MuiModal: FC<ModalType> = ({
  onCancel,
  open = false,
  children,
  width = 0,
  height,
  title,
  marginTop,
  titleAlign = 'flex-start',
}) => {
  const location = useLocation();
  const { pathname } = location;
  const [marginModal, setMarginModal] = useState(false);

  useEffect(() => {
    if (
      pathname == '/legal-register' ||
      pathname == '/register' ||
      pathname == '/pre-register' ||
      pathname == 'financial-register'
    )
      return setMarginModal(true);
  }, [pathname]);

  return (
    <Modal
      open={open}
      onClose={onCancel}
      sx={[{ marginTop: marginTop, marginLeft: !marginModal ? '14.5625rem' : '' }, styles.modal]}>
      <Box width={width} height={height} sx={styles.modalBox}>
        {title && (
          <Typography variant='body2' textAlign={titleAlign} color={palette?.black}>
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </Modal>
  );
};
