import { StepProps, Typography, Box } from '@mui/material';
import MobileStepper, { mobileStepperClasses } from '@mui/material/MobileStepper';
import { FC } from 'react';
import { palette } from 'theme/contants';

interface IAditionalProps {
  totalSteps: number;
  actualStep: number;
}
type Props = StepProps | IAditionalProps;

export const MuiStepper: FC<Props> = ({ totalSteps, actualStep, ...props }: any) => {

  return (
    <Box width={'98%'}
      display={'flex'} flexDirection={'column'}>
      <Typography textAlign='left' fontSize={'0.75rem'} padding={'0 8px'} color={palette.black} >{`${actualStep}/${totalSteps - 1}`}</Typography>
      <Box >
        <MobileStepper
          variant="progress"
          steps={totalSteps}
          position="static"
          activeStep={actualStep}
          sx={{
            width: '100%',
            bgcolor: 'transparent',
            [`& .${mobileStepperClasses.progress}`]: {
              bgcolor: palette.darkGray,
              width: '100%'
            }
          }}
          nextButton={null}
          backButton={null}
          {...props}
        />
      </Box>

    </Box>
  )
};




