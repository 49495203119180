import * as Yup from 'yup';
import { amountCharactersRegex } from 'utils';

export const schema = Yup.object().shape({
  contribution_associate_received: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  contribution_employer_received: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  contribution_associate: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),
  contribution_employer: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  legal_reserves: Yup.string().notRequired(),
  // .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),
  deficit: Yup.string().notRequired(),
});
