import { Box, Typography, TableRow, TableCell, Button, CircularProgress, Tooltip } from '@mui/material';
import { styles } from '../../styles';
import { ClientCard, MemberSection, ProtocolSection, RejectModal } from '../../components';
import { TextField } from 'components';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { getClientLegalDetail, setLegalStatus } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { clientsSelector, authSelector } from 'store/selectors';
import { useEffect } from 'react';
import { StatusEnum, PermissionEnum } from 'utils/constants';
import { UseIntermitence } from 'hooks';

export const LegalData = ({ clientId }: any) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const clientData = useSelector(clientsSelector);
  const {
    user: {
      role: { permissions },
    },
  } = useSelector(authSelector);
  const { status, switchStatus } = UseIntermitence();

  const basicDataHeaders = ['Rif', 'Número de registro', 'Dirección', 'Empleador', 'Cantidad de asociados'];

  const membersAdmHeader = [
    'Nombre',
    'Cédula',
    'Teléfono',
    'Correo',
    'Cargo',
    'Balance personal',
    'Declaración Jurada',
  ];
  const membersVigHeader = ['Nombre', 'Cédula', 'Teléfono', 'Correo', 'Cargo', '', ''];

  const { register, control } = useForm({
    defaultValues: {
      explainAdmin: clientData?.legalDetail?.explainAdmin,
      explainWatch: clientData?.legalDetail?.explainWatch,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(getClientLegalDetail(clientId));
  }, []);

  const setReviewStatus = (status: string) => {
    if (status == 'Rejected') return switchStatus();

    dispatch(setLegalStatus({ clientId, status }));
    dispatch(getClientLegalDetail(clientId));
  };

  return (
    <>
      <Box paddingBottom={'1rem'} width={'100%'}>
        <ClientCard headers={basicDataHeaders}>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.entityData?.rif ?? ''}{' '}
              </Typography>
            </TableCell>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.entityData?.register_number ?? ''}{' '}
              </Typography>
            </TableCell>
            <TableCell component='th' scope='row'>
              <Tooltip title={clientData?.legalDetail?.entityData?.address ?? ''} placement={'top'}>
                <Typography variant='button' sx={styles.rowText}>
                  {clientData?.legalDetail?.entityData?.address ?? ''}
                </Typography>
              </Tooltip>
            </TableCell>

            <TableCell component='th' scope='row'>
              <Tooltip title={clientData?.legalDetail?.entityData?.employer ?? ''} placement={'top'}>
                <Typography variant='button' sx={styles.rowText}>
                  {clientData?.legalDetail?.entityData?.employer ?? ''}{' '}
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell component='th' scope='row'>
              <Typography variant='button' sx={styles.rowText}>
                {clientData?.legalDetail?.entityData?.number_associates ?? ''}{' '}
              </Typography>
            </TableCell>
          </TableRow>
        </ClientCard>
      </Box>

      {clientData?.legalDetail?.protocolData ? (
        <>
          <ProtocolSection />
          <Box width={'98%'} marginBottom={'1rem'}>
            <Typography
              marginLeft={'1%'}
              marginRight={'1%'}
              marginBottom={'1rem'}
              textAlign={'left'}
              fontSize={'1rem'}
              fontWeight={'600'}>
              Miembros Consejo de Vigilancia
            </Typography>
            <MemberSection
              headers={membersVigHeader}
              type={'vig'}
              rows={clientData?.legalDetail?.vigilanceMembers?.vigMembers ?? []}
            />
          </Box>

          <Box width={'98%'} marginBottom={'2rem'}>
            <Typography
              marginLeft={'1%'}
              marginRight={'1%'}
              marginBottom={'1rem'}
              textAlign={'left'}
              fontSize={'1rem'}
              fontWeight={'600'}>
              Miembros Consejo Administrativo
            </Typography>
            <MemberSection
              headers={membersAdmHeader}
              type={'adm'}
              rows={clientData?.legalDetail?.administrativeMembers?.admMembers ?? []}
            />
          </Box>

          <Box
            width={'98%'}
            display={'flex'}
            marginLeft={'1%'}
            marginRight={'1%'}
            justifyContent={'space-between'}
            alignItems={'center'}
            paddingBottom={'1rem'}>
            {clientData?.legalDetail?.administrativeMembers?.admMembers?.length < 6 && (
              <Box width={'49%'} marginRight={'1%'}>
                <Typography textAlign={'left'} marginBottom={'1rem'} fontSize={'1rem'} fontWeight={'600'}>
                  ¿No estan activos todos los miembros de administracion?
                </Typography>

                <TextField
                  fullWidth
                  label='Razón'
                  {...register('explainAdmin')}
                  placeholder={clientData?.legalDetail?.explainAdmin}
                  disabled={true}
                  control={control}
                  multiline
                  rows={4}
                  ref={inputRef.current}
                />
              </Box>
            )}

            {clientData?.legalDetail?.vigilanceMembers?.vigMembers?.length < 6 && (
              <Box width={'49%'} marginLeft={'1%'}>
                <Typography textAlign={'left'} marginBottom={'1rem'} fontSize={'1rem'} fontWeight={'600'}>
                  ¿No estan activos todos los miembros de vigilancia?
                </Typography>

                <TextField
                  fullWidth
                  label='Razón'
                  {...register('explainWatch')}
                  placeholder={clientData?.legalDetail?.explainWatch}
                  disabled={true}
                  control={control}
                  multiline
                  rows={4}
                  ref={inputRef.current}
                />
              </Box>
            )}
          </Box>
          {clientData?.legalDetail?.legalStatus == StatusEnum?.WAITING && (
            <Box display={'flex'} justifyContent={'flex-end'} width='100%' paddingBottom='2rem' alignContent={'center'}>
              {permissions.includes(PermissionEnum.RejectedLegal) && (
                <Box width={'10.125rem'} marginRight='1rem'>
                  <Button
                    onClick={() => setReviewStatus('Rejected')}
                    sx={{ background: palette?.error, width: '100%' }}
                    variant={'contained'}>
                    <Box display={'flex'} justifyContent={'center'} marginRight={'0.5rem'}>
                      <Icon icon='fa6-solid:circle-xmark' width={18} color={palette.white} />
                    </Box>
                    Rechazado
                  </Button>
                </Box>
              )}
              {permissions.includes(PermissionEnum.ApproveLegal) && (
                <Box width={'10.125rem'} marginRight='1%'>
                  <Button
                    onClick={() => setReviewStatus('Approved')}
                    sx={{ background: palette?.success, width: '100%' }}
                    variant={'contained'}>
                    <Box display={'flex'} justifyContent={'center'} marginRight={'0.5rem'}>
                      <Icon icon='fa6-solid:circle-check' width={18} color={palette.white} />
                    </Box>
                    Aprobado
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {status && (
            <RejectModal
              open={status}
              onClose={switchStatus}
              onConfirm={(value: string) => dispatch(setLegalStatus({ clientId, status: 'Rejected', reason: value }))}
            />
          )}
        </>
      ) : (
        <EmptyState clientData={clientData} />
      )}
    </>
  );
};

export const EmptyState = ({ clientData }: any) => {
  return (
    <Box width={'100%'} height={'70%'} flexDirection={'column'} display={'flex'} justifyContent={'center'}>
      <Box>
        {clientData?.clientsLoading ? (
          <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
        ) : (
          <>
            <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
            <Typography color={palette?.dark} variant='subtitle1'>
              No hay datos legales de la caja
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
