import { Box, Typography } from '@mui/material';
import { CardType } from './types';
import { FC } from 'react';
import { styles } from './styles';
import { palette } from 'theme/contants';

export const MuiCard: FC<CardType> = ({ title, width, children, height = '20.125rem' }) => {
  return (
    <Box width={'100%'}>
      <Typography textAlign={'left'} sx={styles.formText} fontWeight={'600'} color={palette.black}>
        {title}
      </Typography>
      <Box
        width={width}
        height={height}
        maxHeight={height}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        boxShadow={'0px 1px 6px rgba(0, 0, 0, 0.15)'}
        padding={'1rem'}
        borderRadius={'10px'}>
        {children}
      </Box>
    </Box>
  );
};
