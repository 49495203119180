import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { DashboardDrawer, DashboardHeader } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, Theme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { setShowMessage } from 'store/actions';

export function DashboardLayout({ children }: any) {
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { user } = useSelector(authSelector);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.is_onboarding && !user?.role) {
      dispatch(setShowMessage());
      return navigation('/register');
    }
  }, []);

  return (
    <Box display={'flex'} id='component-main'>
      <CssBaseline />
      <DashboardHeader />
      <DashboardDrawer location={location} />
      <Box
        component='main'
        sx={(theme) => ({
          width: '100%',
          height: !isMobile ? '100vh' : 'auto',
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
        })}>
        <Outlet />
      </Box>
    </Box>
  );
}
