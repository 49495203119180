import React, { useState, useRef } from 'react';
import { MuiModal, TextField, SelectorBox } from 'components';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { Navbar } from 'components';
import { useMediaQuery, Theme } from '@mui/material';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { UseIntermitence } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { preRegister } from 'store/actions';
import { intermitenceSelector } from 'store/selectors';

export const PreRegister = () => {
  const { preregisterLoading } = useSelector(intermitenceSelector);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [typeOfClient, setTypeOfClient] = useState('');
  const [step, setStep] = useState(0);

  const { status, switchStatus } = UseIntermitence();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      register_number: '',
      rif: '',
      name: '',
      email: '',
      user_document: '',
    },
    resolver: yupResolver(schema),
  });

  const sectorType: any = {
    private: 'Privado',
    public: 'Publico',
  };

  const onSubmit = (data: any) => {
    const body = {
      ...data,
      sector: sectorType[typeOfClient],
    };

    dispatch(preRegister(body, switchStatus, reset));
  };

  const nextStep = () => setStep(1);
  const backStep = () => setStep(0);

  return (
    <>
      <Navbar />
      {step == 0 ? (
        <Box
          sx={styles.selectTypeMain}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}>
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirection={'column'}
            width={'90%'}
            bgcolor={palette?.white}
            borderRadius={'0.938rem'}
            padding={'2rem 0'}>
            <Typography variant='h2' fontWeight={'600'} marginTop={'2rem'} sx={styles.selectTypeTitle}>
              Completa los siguientes pasos para registrar las Caja de Ahorro
            </Typography>
            <Typography variant='subtitle2' marginTop={'2rem'}>
              Selecciona el tipo de Cajas de Ahorro
            </Typography>
            <Box
              display={'flex'}
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              marginTop={'2rem'}>
              <SelectorBox
                method={() => {
                  nextStep();
                  setTypeOfClient('public');
                }}
                text='Caja Pública'
                icon={'fa6-solid:building-shield'}
                iconSize={!isMobile ? 125 : 100}
              />

              <SelectorBox
                text='Caja Privada'
                method={() => {
                  nextStep();
                  setTypeOfClient('private');
                }}
                icon={'fa6-solid:building-user'}
                iconSize={!isMobile ? 140 : 110}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={styles.main}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          bgcolor={palette.white}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'90%'}
            height={'90%'}
            borderRadius={'0.938rem'}
            padding={'2rem 2rem'}
            sx={styles.container}
            bgcolor={palette.white}>
            <Box textAlign='left' marginBottom={'3rem'}>
              <Typography variant='h3'>Proceso de Pre registro</Typography>
            </Box>

            <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
              <Box width={'100%'} display={'flex'} flexDirection={'column'} textAlign='left'>
                <Typography variant='subtitle1' marginBottom={'1rem'}>
                  Datos de usuario
                </Typography>
                <Box display={'flex'} sx={styles.inputContainer}>
                  <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                    <TextField
                      fullWidth
                      label='ID de caja de ahorro'
                      {...register('register_number')}
                      placeholder='Texto'
                      control={control}
                      helperText={errors?.register_number ? errors?.register_number?.message : ''}
                      error={!!errors?.register_number}
                      ref={inputRef.current}
                    />
                  </Grid>

                  <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                    <TextField
                      fullWidth
                      label='Rif de caja de ahorro'
                      {...register('rif')}
                      placeholder='Texto'
                      helperText={errors.rif?.message ? errors.rif?.message : ''}
                      control={control}
                      error={!!errors?.rif}
                      ref={inputRef.current}
                    />
                  </Grid>

                  {!isMobile && <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
                </Box>
              </Box>
              <Box
                width={'100%'}
                sx={styles.containerMargin}
                display={'flex'}
                textAlign={'left'}
                flexDirection={'column'}>
                <Typography variant='subtitle1' marginBottom={'1rem'}>
                  Datos de usuario que esta registrando
                </Typography>
                <Box display={'flex'} sx={styles.inputContainer}>
                  <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                    <TextField
                      fullWidth
                      label='Nombre'
                      {...register('name', { required: false })}
                      helperText={errors.name?.message ? errors?.name?.message : 'Opcional'}
                      placeholder='Texto'
                      control={control}
                      error={!!errors?.name}
                      ref={inputRef.current}
                    />
                  </Grid>
                  <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                    <TextField
                      fullWidth
                      label='Correo'
                      {...register('email')}
                      placeholder='Texto'
                      helperText={errors?.email?.message ? errors?.email?.message : ''}
                      control={control}
                      error={!!errors?.email}
                      ref={inputRef.current}
                    />
                  </Grid>
                  <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
                    <TextField
                      fullWidth
                      label='Rif/Cédula'
                      {...register('user_document')}
                      placeholder='Texto'
                      helperText={errors.user_document?.message ? errors.user_document.message : ''}
                      control={control}
                      error={!!errors?.user_document}
                      ref={inputRef.current}
                    />
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Box display={'flex'} width={'100%'} sx={styles.buttonContainer} justifyContent={'space-between'}>
              <Button onClick={() => backStep()} variant='outlined' type='submit' sx={styles.button}>
                Regresar
              </Button>
              <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
                {preregisterLoading ? <CircularProgress color='inherit' size={28} /> : 'Continuar'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {status && (
        <MuiModal width={'60%'} open={status} title={'Proceso de verificación'}>
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            textAlign={'left'}
            flexDirection={'column'}>
            <Typography variant='subtitle2' paragraph marginTop={'2rem'}>
              Estaremos verificando los datos ingresados para continuar con el proceso de registro. La contraseña será
              enviada al correo electrónico asociado a las Cajas de Ahorro para poder acceder a la plataforma.
            </Typography>
            <Box
              sx={styles.modalButtonContainer}
              marginTop={'1.5rem'}
              display='flex'
              justifyContent='flex-end'
              alignContent='flex-end'>
              <Button type='submit' onClick={() => navigation('/')} sx={styles.button} variant='contained'>
                Continuar
              </Button>
            </Box>
          </Box>
        </MuiModal>
      )}
    </>
  );
};
