import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  buttonContainer: (theme: Theme) => ({
    width: '100%',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: '1rem',
      justifyContent: 'center',
    },
  }),
  modalButtonContainer: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),

  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  modalText: (theme: Theme) => ({
    fontSize: '1.25rem',

    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  }),
  modalTitle: (theme: Theme) => ({
    color: palette.black,
    fontSize: '1.563rem',
    fontWeight: '600',
    marginBottom: '1rem',
  }),
  modalsubtitle: (theme: Theme) => ({
    color: palette.black,
    fontSize: '0.75rem',
    marginBottom: '1rem',
  }),
};
