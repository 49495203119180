import { palette } from 'theme/contants';
import { TextField } from 'components';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useRef } from 'react';
import { styles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schema } from './validate';
import { useNavigate } from 'react-router-dom';
import { LoginNavBar } from 'pages/Login/components';
import { forgotPassword } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useMediaQuery, Theme } from '@mui/material';

export const ForgotPassword = () => {
  const inputRef = useRef(null);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      user: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => dispatch(forgotPassword(data));

  return (
    <>
      <LoginNavBar />
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        marginTop={!isMobile ? '5rem' : 'calc(100% - 80%)'}
        bgcolor={palette?.light}
        height={!isMobile ? '100vh' : '85vh'}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          borderRadius={'17px'}
          width={!isMobile ? '37.75rem' : '90%'}
          height={!isMobile ? '35.813rem' : '70%'}
          bgcolor={palette?.white}>
          <Box
            margin={'1rem 0'}
            flexDirection={'column'}
            display={'flex'}
            justifyContent={'space-between'}
            height={'70%'}
            width={'80%'}
            alignContent={'center'}>
            <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} alignContent={'center'}>
              <Typography fontSize={'1rem'} fontWeight={'700'}>
                Recuperar contraseña
              </Typography>
              <Typography align='left' fontSize={'0.875rem'} marginTop={'2rem'} marginBottom={'1rem'}>
                Ingresa tu correo electrónico para recuperar tu contraseña
              </Typography>

              <Grid item sx={styles.input} xs={12} md={12} sm={12} lg={12} xl={6}>
                <TextField
                  fullWidth
                  label='Id de caja de ahorro'
                  {...register('user')}
                  placeholder='Texto'
                  id='ID'
                  helperText={errors?.user?.message}
                  name='user'
                  control={control}
                  error={!!errors?.user}
                  ref={inputRef.current}
                />
              </Grid>
            </Box>
            <Box sx={styles.buttonContainer}>
              <Button type='submit' onClick={handleSubmit(onSubmit)} sx={styles.button} variant='contained'>
                Continuar
              </Button>
              <Typography
                fontSize={'0.875rem'}
                marginTop={'1rem'}
                marginBottom={'1rem'}
                onClick={() => navigation('/')}
                sx={{ cursor: 'pointer' }}>
                Volver a inicio de sesión
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
