import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService } from '../../utils';
import { GET_PERMISSIONS, GET_PERMISSIONS_ASYNC, SET_LOADING } from './action-types';
import { showDialog } from 'utils';
import { authSelector } from 'store/selectors';

function* getPermissionsAsync({ payload }: any) {
  try {
    yield put(actionObject(SET_LOADING, true));

    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { data, statusCode, response } = yield call(
      FetchService,
      `/permission?page=${payload}&take=4`,
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(GET_PERMISSIONS_ASYNC, data));
      yield put(actionObject(SET_LOADING, false));
    }

    if (!data) {
      yield call(showDialog, 'Ha ocurrido un error', 'error');
      yield put(actionObject(SET_LOADING, false));
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(SET_LOADING, false));
    throw new Error('Ha ocurrido un error');
  }
}

export function* watchPermissions() {
  yield takeLatest(GET_PERMISSIONS, getPermissionsAsync);
}
