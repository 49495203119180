import { Box, Button, ClickAwayListener, Tooltip as Muitooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, resetRegister } from 'store/actions';
import { baseUrl } from 'paths';
import { styles } from './styles';
import { UseIntermitence } from 'hooks';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';
import { useMediaQuery, Theme } from '@mui/material';
import { MuiModal } from 'components';

export const Tooltip = ({ logoutUser }: any) => (
  <Box display='flex' alignItems='center' onClick={logoutUser}>
    <Box
      component={'img'}
      sx={{ marginRight: '0.5625rem' }}
      src={`${baseUrl}/resources/logout-icon.svg`}
      alt='logout-icon'
    />{' '}
    Cerrar Sesión{' '}
  </Box>
);

export const Logout = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { status: confirmStatus, switchStatus: switchConfirmStatus } = UseIntermitence();
  const { user } = useSelector(authSelector);
  const { status, switchStatus } = UseIntermitence();

  const logoutUser = () => {
    const { type } = user;
    dispatch(logout());
    dispatch(resetRegister());
    type == 'admin' ? navigation('/admin-login') : navigation('/');
  };

  const closeModal = () => {
    if (status) switchStatus();
    return null;
  };

  return (
    <>
      <ClickAwayListener touchEvent={false} onClickAway={closeModal}>
        <Muitooltip
          role='presentation'
          open={status}
          PopperProps={{
            sx: styles.tooltip,
          }}
          onClick={switchStatus}
          title={<Tooltip logoutUser={() => switchConfirmStatus()} />}
          arrow
          placement='bottom-end'>
          <Box display='flex' alignItems='center' marginLeft='1.875rem' sx={{ cursor: 'pointer' }}>
            <Icon
              icon={'teenyicons:user-solid'}
              width={!isMobile ? '1rem' : '1.5rem'}
              height={!isMobile ? '1.125rem' : '1.5rem'}
              color={palette?.black}
            />
            {!isMobile && (
              <Typography variant='button' marginLeft='0.5625rem'>
                {user?.userName || `${user?.firstname} ${user?.lastname}`}
              </Typography>
            )}
          </Box>
        </Muitooltip>
      </ClickAwayListener>
      {confirmStatus && (
        <MuiModal
          open={confirmStatus}
          width={!isMobile ? '35.563rem' : '80% !important'}
          height={!isMobile ? '12.063rem' : '8rem'}
          title={'¿Deseas cerrar sesión?'}
          titleAlign={'center'}
          onCancel={() => switchConfirmStatus()}>
          <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
            <Box marginRight={'1rem'} sx={styles.containerButton}>
              <Button onClick={() => switchConfirmStatus()} sx={styles.button} variant='outlined' type='submit'>
                Cancelar
              </Button>
            </Box>

            <Box marginLeft={'1rem'} sx={styles.containerButton}>
              <Button onClick={logoutUser} sx={styles.button} variant='contained'>
                Confirmar
              </Button>
            </Box>
          </Box>
        </MuiModal>
      )}
    </>
  );
};
