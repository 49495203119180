import { palette } from '../contants';

export const MuiInputLabel = {
  styleOverrides: {
    root: {
      fontSize: '0.875rem',

      '&.Mui-error': {
        color: palette.dark,
      },

      '&.Mui-focused': {
        color: palette.dark,
      },
    },
  },
};
