import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  main: (theme: Theme) => ({
    height: '90vh',
    backgroundColor: palette.light,
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingTop: '2rem',
      marginTop: '3rem',
      paddingBottom: '2rem',
    },
  }),

  selectTypeSubtitle: (theme: Theme) => ({
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  }),

  title: (theme: Theme) => ({
    fontSize: '1.875rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
      textAlign: 'center',
    },
  }),
  subtitle: (theme: Theme) => ({
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  }),
  formText: (theme: Theme) => ({
    fontSize: '1.25rem',
    marginBottom: '3rem',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
  }),
  secondFormText: (theme: Theme) => ({
    fontSize: '1.25rem',
    marginBottom: '3rem',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
  }),
  inputContainer: (theme: Theme) => ({
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }),
  buttonContainerRight: (theme: Theme) => ({
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '0.5rem',
    },
  }),
  buttonContainerLeft: (theme: Theme) => ({
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '0.5rem',
    },
  }),
  modalButtonContainer: (theme: Theme) => ({
    width: '100%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  buttonContainer: (theme: Theme) => ({
    flexDirection: 'row',
    marginTop: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginTop: '0px',
    },
  }),

  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem 0',
      width: '100%',
    },
  }),
  modalText: (theme: Theme) => ({
    fontSize: '1.25rem',

    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  }),
  tableButton: (theme: Theme) => ({
    height: '1.813rem',
    width: '9.75rem',
    borderRadius: '0.625rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    [theme.breakpoints.down('md')]: {},
  }),
  selectTypeMain: (theme: Theme) => ({
    height: '90vh',
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingTop: '2rem',
      marginTop: '4rem',
      paddingBottom: '2rem',
    },
  }),
  selectTypeTitle: (theme: Theme) => ({
    fontSize: '3rem',
    width: '100%',
  }),
  selectTypeOptions: (theme: Theme) => ({
    width: '22rem',
    minHeight: '16.625',
    margin: '2rem 2rem',
    padding: '1rem 0',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '15rem',
      margin: '1.5rem 0 1rem 0',
      height: '14.1rem',
    },
  }),
  selectTypeOptionText: (theme: Theme) => ({
    fontSize: '1.25rem',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      marginTop: '1rem',
    },
  }),
  firstInput: (theme: Theme) => ({
    margin: '0 1rem 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  secondInput: (theme: Theme) => ({
    margin: '0 1rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  thirdInput: (theme: Theme) => ({
    margin: '0 0 0 1rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  selectTypeButtonContainer: (theme: Theme) => ({
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  selectTypeButton: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  }),
  selectTypeText: (theme: Theme) => ({
    fontSize: '0.875rem',
    fontWeight: '400',
    marginLeft: '1rem',
  }),
  boxForm: (theme: Theme) => ({
    margin: '4rem 0',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  }),
};
