import React, { useState } from 'react';
import { TextField as MUITextField, TextFieldProps, Box, CircularProgress, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { Controller } from 'react-hook-form';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { useDispatch } from 'react-redux';
import { deleteFile } from 'store/actions';
interface IAditionalProps {
  name: string;
  control: any;
  error: boolean;
  ref: any;
  methodFile: any;
  index: number | string;
  helperText: any;
  buttonWidth?: string | number;
  acceptedFormats?: any;
  withDeleteIcon?: boolean;
  externalName?: string;
  loading?: boolean;
}

type Props = TextFieldProps | IAditionalProps;

export const InputFileUpload: FC<Props> = ({
  name,
  control,
  error = false,
  ref,
  index,
  helperText,
  methodFile,
  externalName = null,
  buttonWidth = '15%',
  acceptedFormats = '.jpg, .jpeg, .png, .pdf',
  withDeleteIcon = true,
  loading = false,
  ...props
}: any) => {
  const dispatch = useDispatch();

  const handleUpload = (file: File) => {
    methodFile(file);
  };

  const deleteFiles = () => {
    methodFile('');
    dispatch(deleteFile(externalName));
    externalName = null;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MUITextField
            variant='outlined'
            inputRef={ref}
            disabled={true}
            error={error}
            helperText={helperText}
            id='file-chosen'
            placeholder={externalName || null}
            InputLabelProps={{
              shrink: true,
            }}
            sx={
              error
                ? {
                    '& .MuiOutlinedInput-root.Mui-disabled': {
                      '& > fieldset': { border: `1px solid ${palette.error}` },
                    },
                  }
                : null
            }
            InputProps={{
              endAdornment:
                externalName && withDeleteIcon ? (
                  <IconButton onClick={() => deleteFiles()}>
                    <Icon icon='ic:baseline-close' width={22} color={palette.black} />
                  </IconButton>
                ) : null,
            }}
            {...props}
            {...field}
          />
        )}
      />
      <input
        onChange={(event: any) => handleUpload(event.target.files[0])}
        ref={ref}
        id={`actual-btn-${index}`}
        type='file'
        accept={acceptedFormats}
        hidden
      />
      <label style={{ marginLeft: 10, width: buttonWidth }} htmlFor={`actual-btn-${index}`}>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'2.5rem'}
          borderRadius={'1.25rem'}
          bgcolor={palette.primary}
          border={'none'}
          color={palette.white}>
          {!loading ? (
            <Icon icon='fa6-solid:paperclip' width={20} color={palette.white} />
          ) : (
            <CircularProgress color='inherit' size={22} />
          )}
        </Box>
      </label>
    </>
  );
};
