import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  buttonContainer: (theme: Theme) => ({
    flexDirection: 'row',
    marginTop: '1.5rem',
    paddingBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginTop: '0',
      paddingBottom: '1.5rem',
    },
  }),
  modalButtonContainer: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem 0',
      width: '100%',
    },
  }),
  modalText: (theme: Theme) => ({
    fontSize: '1.25rem',

    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  }),
  modalTitle: (theme: Theme) => ({
    color: palette.black,

    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: '1rem',
    },
  }),
  modalBody: (theme: Theme) => ({
    height: '34.625rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
  }),
  buttonContainerRight: (theme: Theme) => ({
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '0.5rem',
    },
  }),
  buttonContainerLeft: (theme: Theme) => ({
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '0.5rem',
    },
  }),
  inputContainer: (theme: Theme) => ({
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }),
  firstInput: (theme: Theme) => ({
    margin: '0 2rem 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),
  secondInput: (theme: Theme) => ({
    margin: '0 0 0 2rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
    },
  }),

  boxForm: (theme: Theme) => ({
    margin: '3rem 0',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  }),
};
