import { Badge, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { MenuType } from './types';
import { FC, useState } from 'react';
import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';
import { useNavigate } from 'react-router-dom';

export const NotificactionsMenu: FC<MenuType> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigation = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = (item: any) => {
    if (item?.route == null) handleClose();

    handleClose();
    navigation(item?.route);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        {data?.[0]?.message !== 'No hay notificaciones' && (
          <Badge badgeContent={1} color='primary'>
            <Icon icon={'clarity:bell-solid'} width={'1rem'} height={'1.125rem'} color={palette?.black} />
          </Badge>
        )}
        {data?.[0]?.message == 'No hay notificaciones' && (
          <Icon icon={'clarity:bell-solid'} width={'1rem'} height={'1.125rem'} color={palette?.black} />
        )}
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <Box display={'flex'} width={'23rem'} padding={'1rem'}>
          <Icon icon={'clarity:bell-solid'} width={'1rem'} height={'1.125rem'} color={palette?.dark} />
          <Typography marginLeft={'1rem'} fontSize={'1rem'} color={palette?.dark} fontWeight={800}>
            Notificaciones
          </Typography>
        </Box>

        {data?.map((item: any, index: number) => {
          return (
            <MenuItem
              key={index}
              sx={{
                borderBottom: `1px solid ${palette?.blueHaze}`,
                '&:last-child': { border: 0 },
              }}
              onClick={() => redirectTo(item)}>
              <Typography marginLeft={'2rem'} fontSize={'0.875rem'}>
                {item?.message}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
