import InterFontttf from '../../assets/fonts/Inter-VariableFont_slnt,wght.ttf';

export const MuiCssBaseline = {
  styleOverrides: {
    '@font-face': {
      fontFamily: '"Inter"',
      fontDisplay: 'swap',
      fontStyle: 'normal',
      src: `local('Inter'), local('Inter'), url(${InterFontttf}) format('woff2')`,
    },
    '@global': {
      '::-webkit-scrollbar': {
        width: '0.1875rem',
        zIndex: '1900 !important',
      },
      '*::-webkit-scrollbar-track': {
        background: '#E4EFEF',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#EEA71E',
        borderRadius: '2.5px',
      },
    },
  },
};
