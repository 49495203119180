import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService } from '../../utils';
import { GET_URL_S3, S3_LOADING, GET_URL_S3_ASYNC, GET_EXPORTABLE, GET_EXPORTABLE_ASYNC } from './action-types';
import { showDialog } from '../../utils';
import { authSelector } from 'store/selectors';

function* getUrlAsync({ payload, callback }: any) {
  try {
    yield put(actionObject(S3_LOADING, true));

    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { url } = yield call(FetchService, `/storage/image-url/${payload}`, 'GET', null, accessToken);

    if (url) {
      const values: any = { url };
      callback &&
        callback({
          ok: true,
          values,
        });
      yield put(actionObject(GET_URL_S3_ASYNC, url));

      yield put(actionObject(S3_LOADING, false));
    }

    if (!url) {
      yield put(actionObject(S3_LOADING, false));

      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(S3_LOADING, false));
    throw new Error('Ha ocurrido un error');
  }
}

function* getExportableAsync({ payload, callback }: any) {
  try {
    yield put(actionObject(S3_LOADING, true));

    const {
      user: { accessToken },
    } = yield select(authSelector);

    const { url } = yield call(FetchService, `/storage/exportable/${payload}`, 'GET', null, accessToken);

    if (url) {
      const values: any = { url };
      callback &&
        callback({
          ok: true,
          values,
        });
      yield put(actionObject(GET_EXPORTABLE_ASYNC, url));

      yield put(actionObject(S3_LOADING, false));
    }

    if (!url) {
      yield put(actionObject(S3_LOADING, false));

      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(S3_LOADING, false));
    throw new Error('Ha ocurrido un error');
  }
}

export function* watchGetUrl() {
  yield takeLatest(GET_URL_S3, getUrlAsync);
}
export function* watchGetExportable() {
  yield takeLatest(GET_EXPORTABLE, getExportableAsync);
}
