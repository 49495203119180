import { TextField as MUITextField, TextFieldProps, IconButton, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FC } from 'react';
import { Icon } from '@iconify/react';

import { palette } from 'theme/contants';
interface IAditionalProps {
  name: string;
  control?: any;
  error: boolean;
  ref: any;
  helperText: any;
  iconMethod?: any;
  type?: string;
  icon?: string;
}
type Props = TextFieldProps | IAditionalProps;

export const TextField: FC<Props> = ({
  name,
  control,
  error = false,
  helperText,
  type = 'text',
  ref,
  iconMethod,
  icon,
  ...props
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MUITextField
          variant='outlined'
          inputRef={ref}
          error={error}
          helperText={helperText}
          type={type}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: iconMethod ? (
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' onClick={iconMethod} edge='end'>
                  <Icon icon={icon} width={22} color={palette?.black} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          {...props}
          {...field}
        />
      )}
    />
  );
};
