import * as Yup from 'yup';
import { emailRegex, onlyLettersAndNumbers } from 'utils';

export const schema = Yup.object().shape({
  email: Yup.string()
    .required('Campo requerido')
    .matches(emailRegex, 'Ingrese un formato válido, por ejemplo email@gmail.com'),

  password: Yup.string()
    .required('Campo requerido')
    .matches(onlyLettersAndNumbers, 'El campo debe contener solo letras y números')
    .min(8, `Este campo debe poseer un minimo de ${8} caracteres `)
    .max(255, `Este campo no debe tener más de ${255} caracteres`),
});
