import { useRef, useEffect, useState } from 'react';
import { InputAmount } from 'components';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { parseAmount, parseTotalAmount, plainNumberString, handleAmountFormat } from 'utils';
import { useMediaQuery, Theme } from '@mui/material';

export const NotCurrentAssets = () => {
  const inputRef = useRef(null);
  const registerData = useSelector(registerSelector);
  const { assets, totalNotCurrentAssets } = registerData;
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      land: assets?.land ? handleAmountFormat(assets?.land) : '',
      building: assets?.building ? handleAmountFormat(assets?.building) : '',
      transport_equipment: assets?.transport_equipment ? handleAmountFormat(assets?.transport_equipment) : '',
      other_assets: assets?.other_assets ? handleAmountFormat(assets?.other_assets) : '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    validateZero();
    const assetsCopy = { ...assets };
    const formatedData = plainNumberString(data);
    const newData = { ...assetsCopy, ...formatedData };

    dispatch(
      setEntityData({
        ...registerData,
        assets: newData,
        totalNotCurrentAssets: totalAmount,
        financialStep: 5,
      }),
    );
  };
  const returnPreviousStep = () => {
    dispatch(setEntityData({ ...registerData, financialStep: 3 }));
  };

  const onHandler = () => {
    const formValues = getValues();
    parseTotalAmount(formValues, setTotalAmount);
  };

  useEffect(() => {
    const formValues = getValues();
    setTotalAmount(totalNotCurrentAssets);
    parseTotalAmount(formValues, setTotalAmount);
  }, []);

  const validateZero = () => {
    const formValues = getValues();
    const newvalue = Object.entries(formValues).map((item: any) => {
      if (Number(item[1]) <= 0 || item[1] == '0,00') return setValue(item[0], String(0));
    });
  };

  const values = getValues();

  // UseEnterPress(() => onSubmit(values));

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            fontWeight={'600'}
            marginTop={'1rem'}
            color={palette.black}>
            Activos no corrientes
          </Typography>

          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Terreno'
                {...register('land')}
                control={control}
                placeholder={'0.00'}
                handlerAmount={() => {
                  onHandler();
                }}
                helperText={errors?.land?.message ? errors?.land?.message : 'Registre el valor en libros del terreno.'}
                error={!!errors?.land}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Edificio'
                {...register('building')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.building?.message
                    ? errors?.building.message
                    : 'Registre el valor neto de las edificaciones de la asociación destinada para su uso. (edificio, oficinas, entre otros.)'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.building}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Equipos de Transporte'
                {...register('transport_equipment')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.transport_equipment?.message
                    ? errors?.transport_equipment.message
                    : 'Registre el valor neto de los equipos de transporte utilizado por la asociación destinada para su uso.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.transport_equipment}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} margin={!isMobile ? '2rem 0' : '0px'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Otros Activos'
                {...register('other_assets')}
                helperText={
                  errors?.other_assets?.message
                    ? errors?.other_assets.message
                    : 'Registre los saldos en Inventario, Intereses por Cobrar, Gastos pagados por anticipados, entre otros.'
                }
                handlerAmount={() => onHandler()}
                control={control}
                placeholder={'0.00'}
                error={!!errors?.other_assets}
                ref={inputRef.current}
              />
            </Grid>
            {!isMobile && <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
            {!isMobile && <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />}
          </Box>
        </Box>
      </Grid>
      <Box sx={{ wdith: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={styles.amount}
          marginTop={isMobile ? '1rem' : '4rem'}
          marginBottom={isMobile ? '1.5rem' : '0'}
          fontWeight='600'
          fontSize={'1.25rem'}>
          Total: <span style={{ fontWeight: '400' }}>{parseAmount(totalAmount)}</span>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        sx={styles.buttonContainer}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Box sx={styles.amounContainer}>
            <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
              Continuar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
