import { Select as MUISelect, SelectProps, MenuItem, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FC } from 'react';
import { Icon } from '@iconify/react';
import { palette } from 'theme/contants';

interface IAditionalProps {
  name: string;
  control: any;
  error: boolean;
  defaultValue: string;
  options?: any;
  handleSelect: any;
  disable?: boolean;
  disableDefault?: boolean;
}
type Props = SelectProps | IAditionalProps;

export const Select: FC<Props> = ({
  name,
  control,
  error = false,
  options,
  ref,
  defaultValue,
  disable = false,
  handleSelect,
  disableDefault = true,
  ...props
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <MUISelect
          sx={{
            width: '100%',
            height: '2.758rem',
            '& .MuiOutlinedInput-notchedOutline': {
              border: error ? '1px solid red !important' : '',
            },
            '& .MuiSelect-icon': { top: 10 },
          }}
          variant='outlined'
          inputProps={{
            'aria-label': 'Without label',
          }}
          IconComponent={(props) => (
            <Box position={'relative'} right={2} top={-27}>
              <Icon icon='bx:chevron-down' {...props} width={35} color={palette.black} />
            </Box>
          )}
          MenuProps={{ style: { width: '100%', zIndex: 1307 } }}
          {...props}
          onChange={handleSelect}
          defaultValue={0}>
          <MenuItem value={0} disabled={disableDefault}>
            {defaultValue}
          </MenuItem>
          {options?.map((item: any) => (
            <MenuItem disabled={disable} value={item.id} key={item.value ?? item.name}>
              {item?.name}
            </MenuItem>
          ))}
        </MUISelect>
      )}
    />
  );
};
