import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  TableCell,
  tableCellClasses,
  useMediaQuery,
  Theme,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { styles } from './styles';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { registerSelector } from 'store/selectors';
import { setEntityData } from 'store/actions';

export const OnboardingTable: FC<any> = ({ headerData, rowsData = [], title = '', buttonMethod, type }: any) => {
  const widthCells = 100 / headerData.length;
  const dispatch = useDispatch();
  const registerData = useSelector(registerSelector);
  const { positionsWatch, positionsAdmTable, administrativeMembers, positionsAdm } = registerData;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const removeRow = (id: number) => {
    if (id == undefined) return;

    const membersCopy = [...rowsData];
    const result = membersCopy.splice(id, 1);

    if (type == 'adm') {
      return dispatch(setEntityData({ ...registerData, administrativeMembers: { admMembers: [...membersCopy] } }));
    }
    return dispatch(setEntityData({ ...registerData, vigilanceMembers: { vigMembers: [...membersCopy] } }));
  };

  const getPositionName = (row: any) => {
    if (type == 'adm') {
      const index = [...administrativeMembers?.admMembers]?.findIndex((item: any) => {
        if (row.index) {
          return item?.index == row?.index;
        }

        return item?.id === row?.position;
      });
      if (index == -1) return row?.positionName;

      return administrativeMembers?.admMembers?.[index]?.positionName;
    }

    const index = [...positionsWatch]?.findIndex((item: any) => {
      return item?.id === row?.position;
    });

    return positionsWatch[index]?.name;
  };

  return (
    <TableContainer sx={{ width: '100%' }}>
      <Typography
        textAlign={'left'}
        fontSize={'1.25rem'}
        fontWeight={'600'}
        sx={styles.formText}
        marginBottom={'1rem'}
        color={palette.black}>
        {title}
      </Typography>
      <Table sx={rowsData.length > 0 ? styles.table : styles.emptyTable} aria-label='simple table'>
        <TableHead
          sx={{
            [`& .${tableCellClasses.root}`]: {
              padding: '1rem',
            },
          }}>
          <TableRow
            sx={{
              [`& .${tableCellClasses.root}`]: {
                paddingTop: '1rem',
                paddingBottom: '0.5rem',
                border: 'none',
              },
            }}>
            <TableCell align={'left'} width={'100%'} colSpan={4}>
              <Typography fontWeight={'600'} fontSize={'0.75rem'}>
                {' '}
                Miembros{' '}
              </Typography>
            </TableCell>
            <TableCell variant='footer' align={'right'} width={'100%'} colSpan={6}>
              <Box>
                {!isMobile ? (
                  <Button
                    type='submit'
                    disabled={rowsData.length == 8}
                    onClick={buttonMethod}
                    sx={styles.tableButton}
                    variant='contained'>
                    {!isMobile ? 'Agregar miembro' : '+'}
                  </Button>
                ) : (
                  <IconButton
                    disabled={rowsData.length == 6}
                    sx={{ background: palette.primary }}
                    onClick={buttonMethod}>
                    <Icon icon='heroicons:plus-20-solid' width={30} color={palette.white} />
                  </IconButton>
                )}
              </Box>
            </TableCell>
          </TableRow>
          {!isMobile && (
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  borderBottom: `1px solid ${palette.gray}`,
                },
              }}>
              {headerData.map((item: string, index: number | string) => (
                <TableCell width={`${widthCells}%`} key={index}>
                  <Typography fontSize={12} fontWeight='700'>
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {rowsData.length > 0 ? (
            <>
              {rowsData?.map((row: any, index: number) => (
                <>
                  {!isMobile ? (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component='th' scope='row'>
                        <Tooltip title={row?.fullname ?? ''} placement={'top'}>
                          <Typography sx={styles.rowText}>{row.fullname} </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>{row?.dni?.toUpperCase()} </Typography>
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>{row.phone} </Typography>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Tooltip title={row?.email ?? ''} placement={'top'}>
                          <Typography sx={styles.rowText}>{row.email} </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Tooltip title={getPositionName(row)} placement={'top'}>
                          <Typography sx={styles.rowText}>{getPositionName(row)} </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        {type == 'adm' && (
                          <Tooltip title={row.path_affidavit} placement={'top'}>
                            <Typography sx={styles.rowText}>{row.path_affidavit} </Typography>
                          </Tooltip>
                        )}
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        {type == 'adm' && (
                          <Tooltip title={row.path_personal_balance} placement={'top'}>
                            <Typography sx={styles.rowText}>{row.path_personal_balance} </Typography>
                          </Tooltip>
                        )}
                      </TableCell>

                      <TableCell component='th' scope='row' />
                      <TableCell component='th' scope='row' align='right'>
                        <IconButton onClick={() => removeRow(index)}>
                          <Icon icon='ic:baseline-close' width={22} color={palette.black} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: '0',
                        },
                      }}>
                      <TableCell width={'50%'}>
                        <Typography sx={styles.rowText}>{row.fullname} </Typography>
                        <Typography sx={styles.rowText} color={palette?.gray} marginTop={'1rem'}>
                          {getPositionName(row)}{' '}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'top' }} align='right' width={'50%'} colSpan={6}>
                        <Icon
                          onClick={() => removeRow(index)}
                          icon='ic:baseline-close'
                          width={22}
                          color={palette.black}
                          style={{ cursor: 'pointer' }}
                        />

                        <Box />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </>
          ) : (
            <TableRow
              sx={{
                height: !isMobile ? '10.594rem' : '12rem',
                '&:last-child td, &:last-child th': { border: 0 },
              }}>
              <TableCell align='center' colSpan={12}>
                <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
                <Typography color={palette?.dark} variant='subtitle1'>
                  No hay registro de miembros
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
