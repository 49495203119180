import { Typography, Box, Grid } from '@mui/material';
import { FC } from 'react';
import { IndicatorCard } from '../IndicatorCard';
import { IndicatoGridTypes } from './types';

export const IndicatorGrid: FC<IndicatoGridTypes> = ({ title = '', data = [], xsSize, showCurrency }) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography textAlign={'left'} variant='caption' marginBottom={'1rem'}>
        {title}
      </Typography>
      <Grid container width={'100$'} spacing={3.5} marginBottom={2}>
        {data?.map((indicator: any) => {
          return (
            <Grid key={indicator?.title} item xs={xsSize}>
              <IndicatorCard title={indicator?.label} total={indicator?.value} showCurrency={showCurrency} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
