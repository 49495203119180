const URL = process.env.API_LANDING_URL ?? 'https://www.sudeca.gob.ve/';

const routes = [
  {
    sectionName: 'Inicio',
    link: `${URL}`,
    sections: [
      { name: 'Marquesina', link: `${URL}#marquesina` },
      { name: 'Sobre nosotros', link: `${URL}#nosotros` },
      { name: 'Actualización de datos', link: `${URL}#datos` },
      { name: 'Contacto', link: `${URL}#contacto` },
      { name: 'Información de interés', link: `${URL}#interes` },
    ],
  },
  {
    sectionName: 'Nosotros',
    link: `${URL}nosotros/`,
    sections: [
      { name: 'Quiénes somos', link: `${URL}nosotros/#organigrama` },
      { name: 'Organigrama', link: `${URL}nosotros/#valores` },
      { name: 'Misión, visión, valores', link: `${URL}nosotros/#rhistorica` },
      { name: 'Reseña histórica', link: `${URL}/nosotros/#evolucion` },
    ],
  },
  {
    sectionName: 'Normativas',
    link: `${URL}normativas/`,
    sections: [
      {
        name: 'CRBV',
        link: `${URL}wp-content/uploads/2023/09/Constitucion-de-la-Republica-Bolivariana-de-Venezuela-1.pdf`,
      },
      { name: 'Código Civil', link: `${URL}wp-content/uploads/2023/09/Codigo-Civil-1.pdf` },
      { name: 'Providencias', link: `${URL}normativas/#providencias` },
      { name: 'Circulares', link: `${URL}normativas/#circulares` },
      { name: 'Comunicados', link: `${URL}normativas/#comunicados` },
      { name: 'Leyes', link: `${URL}normativas/#leyes` },
      { name: 'Formatos e instructivos ', link: `${URL}normativas/#formatos` },
    ],
  },
  {
    sectionName: 'Estadísticas',
    link: `${URL}estadisticas/`,
    sections: [
      { name: 'Información financiera', link: `${URL}estadisticas/#informacion` },
      { name: 'Ahorro en Oro', link: `${URL}ahorro-en-oro/` },
    ],
  },
  {
    sectionName: 'Productos y servicios',
    link: null,
    sections: [{ name: 'Noticias', link: null }],
  },
];

export default routes;
