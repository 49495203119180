import { FC } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { palette } from 'theme/contants';
import { styles } from './styles';
import { Icon } from '@iconify/react';
import { StatisticsTypes } from './types';
import { parseAmount } from 'utils';

export const Statistics: FC<StatisticsTypes> = ({ text, percent, icon }) => {
  return (
    <Grid container alignItems={'center'} justifyContent={'flex-start'} margin={'1rem 0'}>
      <Grid item textAlign={'left'} xs={1}>
        <Box display='flex' alignItems='center'>
          <Icon icon={icon} width={28} color={palette?.dark} />
        </Box>
      </Grid>
      <Grid item textAlign={'left'} lineHeight={'1'} xs={4.5}>
        <Typography variant='button' fontWeight={'600'}>
          {text}{' '}
        </Typography>
      </Grid>
      <Grid item xs={6.5}>
        <Typography textAlign={'right'} lineHeight={'1.5'} sx={styles.formText}>
          {parseAmount(percent ?? 0)}
        </Typography>
      </Grid>
    </Grid>
  );
};
