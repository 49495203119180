import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

export const DoughnutChart = ({ chartValues, onSliceClick, cutout = 80 }: any) => {
  ChartJS.register(ArcElement, Tooltip);

  const handleSliceClick = (_event: any, chartElements: any) => {
    if (chartElements && chartElements.length > 0) {
      const clickedSliceIndex = chartElements[0].index;
      const clickedSliceLabel = chartValues?.labels?.[clickedSliceIndex];

      onSliceClick(clickedSliceLabel);
    }
  };

  const options: any = {
    onClick: handleSliceClick,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const data: any = {
    labels: chartValues?.length ? chartValues?.map((item: any) => item?.labels) : null,
    datasets: [
      {
        data: chartValues?.length ? chartValues.map((item: any) => item?.values) : null,
        backgroundColor: chartValues?.map((item: any) => item?.colors),
        borderWidth: 1,
        cutout: cutout,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return <Doughnut data={data} options={options} />;
};
