import { GET_PERMISSIONS_ASYNC, SET_LOADING } from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  permissionsData: [],
  permissionsLoading: false,
};

const permissions = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_PERMISSIONS_ASYNC:
      return { ...state, permissionsData: payload };
    case SET_LOADING:
      return { ...state, permissionsLoading: payload };
    default:
      return state;
  }
};

export default permissions;
