import { useRef, useState, useEffect } from 'react';
import { InputAmount } from 'components';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styles } from '../../styles';
import { palette } from 'theme/contants';
import { schema } from './validates';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setEntityData } from 'store/actions';
import { registerSelector } from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import { parseAmount, parseTotalAmount, plainNumberString, handleAmountFormat } from 'utils';

export const Incomes = () => {
  const navigation = useNavigate();
  const inputRef = useRef(null);

  const registerData = useSelector(registerSelector);
  const { incomes, totalIncomes } = registerData;
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      load_portfolio: incomes?.load_portfolio ? handleAmountFormat(incomes?.load_portfolio) : '',
      financial_income: incomes?.financial_income ? handleAmountFormat(incomes?.financial_income) : '',
      investment_portfolio_income: incomes?.investment_portfolio_income
        ? handleAmountFormat(incomes?.investment_portfolio_income)
        : '',
      operating_income: incomes?.operating_income ? handleAmountFormat(incomes?.operating_income) : '',
      extraordinary: incomes?.extraordinary ? handleAmountFormat(incomes?.extraordinary) : '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const formValues = getValues();
    setTotalAmount(totalIncomes);
    parseTotalAmount(formValues, setTotalAmount);
  }, []);

  const onSubmit = (data: any) => {
    validateZero();
    const newData = plainNumberString(data);
    dispatch(setEntityData({ ...registerData, incomes: newData, totalIncomes: totalAmount, financialStep: 2 }));
  };
  const onHandler = () => {
    const formValues = getValues();
    parseTotalAmount(formValues, setTotalAmount);
  };

  const validateZero = () => {
    const formValues = getValues();
    const newvalue = Object.entries(formValues).map((item: any) => {
      if (Number(item[1]) <= 0 || item[1] == '0,00') return setValue(item[0], String(0));
    });
  };

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} container spacing={0}>
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Typography
            textAlign={'left'}
            sx={styles.formText}
            fontWeight={'600'}
            marginTop={'1rem'}
            color={palette.black}>
            Ingresos
          </Typography>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Ingresos por Cartera de Préstamos'
                {...register('load_portfolio')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.load_portfolio?.message
                    ? errors?.load_portfolio.message
                    : 'Registre los ingresos por los préstamos devengados en el ejercicio por concepto de intereses generados por los activos incluidos en el grupo "120 - Cartera de Prestamos".'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors.load_portfolio}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Ingresos Financieros'
                {...register('financial_income')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.financial_income?.message
                    ? errors?.financial_income.message
                    : 'Registre los ingresos financieros devengados en el ejercicio, tales como rendimientos en bancos e instituciones financieras.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.financial_income}
                ref={inputRef.current}
              />
            </Grid>

            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Rendimientos sobre Cartera de Inversión'
                {...register('investment_portfolio_income')}
                handlerAmount={() => onHandler()}
                placeholder={'0.00'}
                helperText={
                  errors?.investment_portfolio_income?.message
                    ? errors?.investment_portfolio_income.message
                    : 'Registre los ingresos financieros devengados en el ejercicio por concepto de rendimientos incluidos en el grupo (Rendimiento sobre Cartera de Inversiones).'
                }
                control={control}
                error={!!errors?.investment_portfolio_income}
                ref={inputRef.current}
              />
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} sx={styles.boxForm} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} sx={styles.inputContainer}>
            <Grid item sx={styles.firstInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Otros Ingresos Operativos'
                {...register('operating_income')}
                helperText={
                  errors?.operating_income?.message
                    ? errors?.operating_income.message
                    : 'Registre los otros ingresos operativos del ejercicio generados en las operaciones normales de la asociación distinto a los ingresos financieros.'
                }
                handlerAmount={() => onHandler()}
                control={control}
                placeholder={'0.00'}
                error={!!errors?.operating_income}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.secondInput} xs={12} md={12} sm={12} lg={12} xl={4}>
              <InputAmount
                fullWidth
                label='Ingresos Extraordinarios'
                {...register('extraordinary')}
                handlerAmount={() => onHandler()}
                helperText={
                  errors?.extraordinary?.message
                    ? errors?.extraordinary.message
                    : 'Registre los ingresos originados por operaciones atípicas o eventos excepcionales, tales como donaciones.'
                }
                control={control}
                placeholder={'0.00'}
                error={!!errors?.extraordinary}
                ref={inputRef.current}
              />
            </Grid>
            <Grid item sx={styles.thirdInput} xs={12} md={12} sm={12} lg={12} xl={4} />
          </Box>
        </Box>
      </Grid>
      <Box sx={{ wdith: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography sx={styles.amount} fontWeight='600' fontSize={'1.25rem'}>
          Total: <span style={{ fontWeight: '400' }}>{parseAmount(totalAmount)}</span>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        sx={styles.buttonContainer}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => navigation('/register')} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Box sx={styles.amounContainer}>
            <Button onClick={handleSubmit(onSubmit)} type='submit' sx={styles.button} variant='contained'>
              Continuar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
