import * as Yup from 'yup';
import { emailRegex, fullFormatPhoneRegex, onlyLettersRegex, CIRegex } from 'utils';

export const schema = (type: string) =>
  Yup.object().shape({
    type: Yup.string().default(type),
    fullname: Yup.string()
      .required('Campo requerido')
      .min(3, `Este campo debe tener al menos ${3} caracteres`)
      .max(255, `Este campo no debe tener mas de ${255} caracteres`)
      .matches(onlyLettersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

    dni: Yup.string()
      .required('Campo requerido')
      .matches(CIRegex, 'Este campo debe poseer un formato valido como V00000000'),

    email: Yup.string()
      .required('Campo requerido')
      .matches(emailRegex, 'Ingrese un formato válido, por ejemplo email@gmail.com'),

    phone: Yup.string()
      .required('Campo requerido')
      .matches(fullFormatPhoneRegex, 'El número de teléfono no es válido el formato debe ser el siguiente 04143456789'),

    position: Yup.object().required('Campo requerido'),

    path_affidavit: Yup.string().when('type', {
      is: 'adm',
      then: Yup.string().required('Campo requerido'),
    }),
    path_personal_balance: Yup.string().when('type', {
      is: 'adm',
      then: Yup.string().required('Campo requerido'),
    }),
  });
