import { palette } from '../contants';

export const MuiInputBase = {
  styleOverrides: {
    root: {
      '&.MuiOutlinedInput-root': {
        borderRadius: '15px',
        fontSize: '0.875rem',
        textAlign: 'left',

        '& .MuiOutlinedInput-input': {
          padding: '12px 14px',
        },

        '&.MuiInputBase-multiline': {
          padding: 0,
        },

        '&:not(.Mui-error):not(.Mui-focused):hover': {
          '& > fieldset': {
            borderColor: palette.gray,
          },
        },

        '&:not(.Mui-error):focus': {
          '& > fieldset': {
            borderColor: palette.dark,
          },
        },

        '&.Mui-focused': {
          '& > fieldset': {
            borderWidth: '1px',
          },
        },

        '&label.Mui-focused': {
          color: palette.dark,
        },

        '&.Mui-focused:not(.Mui-error)': {
          '& > fieldset': {
            borderColor: palette.dark,
          },
        },
      },
    },
  },
};
