import { OnboardingTable } from 'components';
import { Box, Button } from '@mui/material';
import { styles } from '../../../../styles';
import { AddMembersModal, ExplainModal } from '../';
import { UseIntermitence } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEntityData,
  getPositionAdm,
  getPositionWatch,
  sendLegalData,
  savePositionAdm,
  getMembersAdm,
} from 'store/actions';
import { registerSelector } from 'store/selectors';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const Members = ({ type = '' }: any) => {
  const navigation = useNavigate();
  const headerAdm = [
    'Nombre',
    'Cédula',
    'Teléfono',
    'Email',
    'Cargo',
    'Declaración Jurada',
    'Balance personal',
    '',
    '',
  ];
  const headerVig = ['Nombre', 'Cédula', 'Teléfono', 'Email', 'Cargo', '', '', '', ''];

  const { status, switchStatus } = UseIntermitence();
  const { status: explainModal, switchStatus: explainSwitch } = UseIntermitence();
  const dispatch = useDispatch();
  const registerData = useSelector(registerSelector);
  const { administrativeMembers, vigilanceMembers, positionsAdm, legalStep } = registerData;
  const { admMembers } = administrativeMembers;
  const { vigMembers } = vigilanceMembers;

  const nextStep = () => {
    const newAdmMembers = replacePositionsWithNames();
    if (type == 'adm') {
      if (admMembers.length >= 6) {
        dispatch(savePositionAdm({ adminCouncil: newAdmMembers, watchCouncil: vigMembers }));

        return dispatch(setEntityData({ ...registerData, legalStep: 4 }));
      }

      // dispatch(setEntityData({ ...registerData, administrativeMembers: { admMembers: newAdmMembers } }));
      return explainSwitch();
    }

    if (vigMembers.length >= 6) {
      dispatch(
        setEntityData({
          ...registerData,
          administrativeMembers: { admMembers: newAdmMembers },
          legalStatus: 'Waiting',
          legalStep: 1,
        }),
      );
      dispatch(sendLegalData(navigation));
    }
    return explainSwitch();
  };

  const replacePositionsWithNames = () =>
    admMembers.map(({ name, position, ...item }: any) => {
      const positionFound = positionsAdm.find((pos: any) => pos.id === position);

      let val = position;

      if (positionFound) {
        if (positionFound.index != '6' && positionFound.index != '7') {
          val = position || positionFound.name;
        } else if (positionFound.index == '6' || positionFound.index == '7') {
          val = positionFound.name || position;
        }
      }

      return {
        ...item,
        position: val,
      };
    });

  const returnPreviousStep = () => {
    if (type == 'adm') return dispatch(setEntityData({ ...registerData, legalStep: 2 }));
    return dispatch(setEntityData({ ...registerData, legalStep: 3 }));
  };

  useEffect(() => {
    // const step = registerData?.legalStep;
    if (legalStep === 3) {
      dispatch(getPositionAdm());
      dispatch(getMembersAdm());
    } else {
      dispatch(getPositionWatch());
    }
    // if (step === 3 || step === 4) dispatch(resetPositions());
    // dispatch(getPositionAdm());
    // dispatch(getMembersAdm());
  }, [registerData?.legalStep]);

  // UseEnterPress(() => nextStep());

  return (
    <>
      <OnboardingTable
        headerData={type == 'adm' ? headerAdm : headerVig}
        type={type}
        rowsData={type == 'adm' ? admMembers : vigMembers}
        buttonMethod={switchStatus}
        title={
          type == 'adm'
            ? ' Identificación completa de los miembros del Consejo de Administración'
            : 'Identificación completa de los miembros del Consejo de Vigilancia'
        }
      />
      <Box display={'flex'} sx={styles.buttonContainer} width={'100%'} justifyContent={'space-between'}>
        <Box sx={styles.buttonContainerLeft}>
          <Button onClick={() => returnPreviousStep()} variant='outlined' type='submit' sx={styles.button}>
            Regresar
          </Button>
        </Box>
        <Box sx={styles.buttonContainerRight}>
          <Button type='submit' sx={styles.button} onClick={() => nextStep()} variant='contained'>
            Continuar
          </Button>
        </Box>
      </Box>

      {status && <AddMembersModal open={status} type={type} onClose={switchStatus} />}

      {explainModal && <ExplainModal open={explainModal} type={type} onClose={explainSwitch} />}
    </>
  );
};
