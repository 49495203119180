import { Box, AppBar, Grid } from '@mui/material';
import { baseUrl } from 'paths';
import { useMediaQuery, Theme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Logout } from 'components/Logout';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { useEffect, useState } from 'react';

export const Navbar = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const navigation = useNavigate();
  const { user } = useSelector(authSelector);
  const location = useLocation();
  const { pathname } = location;
  const [showLogout, setShowLogout] = useState<boolean>(false);

  useEffect(() => {
    if (pathname !== '/pre-register') return setShowLogout(true);
  }, [pathname]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed'>
        <Grid container spacing={0}>
          {isMobile ? (
            <>
              <Grid item display={'flex'} alignItems={'center'} xs={3}>
                <Box
                  onClick={() => navigation('/')}
                  component={'img'}
                  width={isMobile ? 30 : 100}
                  src={`${baseUrl}${isMobile ? '/resources/logo.svg' : '/resources/logo-black.svg'}`}
                  alt='logo'
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>

              <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} item xs={showLogout ? 6 : 9}>
                <Box
                  component={'img'}
                  sx={{ filter: 'grayscale(100%)' }}
                  src={`${baseUrl}/resources/gob-logo.png`}
                  alt='gob-logo'
                  width={'250px'}
                />
              </Grid>
              {showLogout && (
                <Grid display={'flex'} justifyContent={'flex-end'} alignItems={'center'} item xs={3}>
                  {user?.accessToken && <Logout />}
                </Grid>
              )}
            </>
          ) : (
            <>
              {' '}
              <Grid item display={'flex'} alignItems={'center'} xs={6}>
                <Box
                  onClick={() => navigation('/')}
                  component={'img'}
                  width={isMobile ? 30 : 100}
                  src={`${baseUrl}${isMobile ? '/resources/logo.svg' : '/resources/logo-black.svg'}`}
                  alt='logo'
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>
              <Grid display={'flex'} justifyContent={'flex-end'} alignItems={'center'} item xs={6}>
                <Box
                  component={'img'}
                  sx={{ filter: 'grayscale(100%)' }}
                  src={`${baseUrl}/resources/gob-logo.png`}
                  alt='gob-logo'
                  width={isMobile ? '100%' : '462px'}
                />
                {user?.accessToken && showLogout && <Logout />}
              </Grid>
            </>
          )}
        </Grid>
      </AppBar>
    </Box>
  );
};
