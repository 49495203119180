import { AppBar, Toolbar, Box } from '@mui/material';
import { Logout, NotificactionsMenu } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';

export const DashboardHeader = () => {
  const [data, setData]: any = useState();
  const { user } = useSelector(authSelector);

  useEffect(() => {
    if (user?.upload_notification == true)
      return setData([{ message: 'No a cargado su reporte mensual', route: 'monthly-report' }]);
    setData([{ message: 'No hay notificaciones', route: null }]);
  }, []);

  return (
    <AppBar position='fixed' elevation={0}>
      <Toolbar disableGutters>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding-left={' 1.375rem'}
          padding-right={'2.1875rem'}
          width={'100%'}>
          {user?.type !== 'admin' && (
            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
              <NotificactionsMenu data={data} />
            </Box>
          )}
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <Logout />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
